import '@fortawesome/fontawesome-free/css/all.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
import IconAudio from '@/components/icons/IconAudio'
import IconCamera from '@/components/icons/IconCamera'
import IconPeople from '@/components/icons/IconPeople'
import IconMap from '@/components/icons/IconMap'
import IconFull from '@/components/icons/IconFull'
import IconMouse from '@/components/icons/IconMouse'
import IconKeyboard from '@/components/icons/IconKeyboard'
import IconMic from '@/components/icons/IconMic'
import IconMicOff from '@/components/icons/IconMicOff'
import IconMuteMic from '@/components/icons/IconMuteMic'
import IconLogo from '@/components/icons/IconLogo'
import IconCaretLeft from '@/components/icons/IconCaretLeft'
import IconCaretRight from '@/components/icons/IconCaretRight'
import IconLock from '@/components/icons/IconLock'
import IconUser from '@/components/icons/IconUser'
import IconEmail from '@/components/icons/IconEmail'
import IconRePassword from '@/components/icons/IconRePassword'
import IconHamburger from '@/components/icons/IconHamburger'
import IconLive from '@/components/icons/IconLive'
import IconServer from '@/components/icons/IconServer'
import IconUserDark from '@/components/icons/IconUserDark'
import IconLockDark from '@/components/icons/IconLockDark'
import IconEmailDark from '@/components/icons/IconEmailDark'
import IconFriends from '@/components/icons/IconFriends'
import IconBuilding from '@/components/icons/IconBuilding'
import IconImageUpload from '@/components/icons/IconImageUpload'
import IconInvitation from '@/components/icons/IconInvitation'
import IconAddFile from '@/components/icons/IconAddFile'
import IconDeleteBox from '@/components/icons/IconDeleteBox'

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
        audioIcon: {
          component: IconAudio
        },
        cameraIcon: {
          component: IconCamera
        },
        peopleIcon: {
          component: IconPeople
        },
        mapIcon: {
          component: IconMap
        },
        fullIcon: {
          component: IconFull
        },
        mouseIcon: {
          component: IconMouse
        },
        keyboardIcon: {
          component: IconKeyboard
        },
        micIcon: {
          component: IconMic
        },
        micIconOff: {
          component: IconMicOff
        },
        micMuteIcon: {
          component: IconMuteMic
        },
        logoIcon: {
          component: IconLogo
        },
        leftIcon: {
          component: IconCaretLeft
        },
        rightIcon: {
          component: IconCaretRight
        },
        lockIcon: {
          component: IconLock
        },
        userIcon: {
          component: IconUser
        },
        emailIcon: {
          component: IconEmail
        },
        rePasswordIcon: {
          component: IconRePassword
        },
        hamburgerIcon: {
          component: IconHamburger
        },
        liveIcon: {
          component: IconLive
        },
        serverIcon: {
          component: IconServer
        },
        userDarkIcon: {
          component: IconUserDark
        },
        emailDarkIcon: {
          component: IconEmailDark,
        },
        friendsIcon: {
          component: IconFriends
        },
        lockDarkIcon: {
          component: IconLockDark
        },
        buildingIcon: {
          component: IconBuilding
        },
        imageUploadIcon: {
          component: IconImageUpload
        },
        imageInvitation: {
          component: IconInvitation
        },
        addCsvIcon: {
          component: IconAddFile
        },
        deleteBoxIcon: {
          component: IconDeleteBox
        }
    },


  },
    theme: {
        themes: {
            light: {
                primary: '#1EAFC1',
                error: '#E89361',
                blue: '#185FBF',
                blur: '#99B4B9',
                gray: '#E5E5E5'
            },
        },
    },   
});