<template>
  <div class="invited-badge">
    <span class="invited-text">
      Invited by {{ invitedBy.toUpperCase() }}
    </span>
  </div>


</template>

<script>
  export default {
    name: "InvitedBy.vue",
    props: {
      invitedBy: { type: String, required: true },
    },
  }
</script>

<style lang="scss" scoped>
.invited-badge {
  float: right;
}

.invited-text {
  font-size: 18px;
  font-weight: 500;
  color: red;
}
</style>
