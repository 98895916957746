<template>
  <v-expand-transition>
    <div class="auth-form align-self-center">
      <transition name="component-fade" mode="out-in" appear>
        <sign-up v-if="formState === 'signup'" :toggle="toggle" />
        <restore-password
          v-else-if="formState === 'restore'"
          :toggle="toggle"
        />
        <sign-in v-else-if="formState === 'signIn'" :onToggle="toggle" />
      </transition>
      <!-- <div v-if="formState === 'signup' || formState === 'restore'" class="sign-options">
      <p v-if="formState === 'signup'">
        By creating an account, you agree to the <br/>
          <span>Terms and Use</span> and
          <span>Privacy Policy</span>
      </p>
      <p @click="toggle('signIn')"> <span>I' am already a member</span></p>
    </div> -->
    </div>
  </v-expand-transition>
</template>

<script>
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import RestorePassword from "./RestorePassword";

export default {
  name: "auth",
  components: {
    SignUp,
    SignIn,
    RestorePassword,
  },
  data() {
    return {
      formState: "",
    };
  },
  computed: {
    queryState() {
      return this.$route.query.state || "";
    },
  },
  async mounted() {
    this.formState = this.$route.query.state || "signIn";
  },
  methods: {
    async toggle(data) {
      let routeConfig = {};

      switch (data) {
        case "signup":
          routeConfig = {
            path: "/auth",
            query: { state: "signup", from: "web" },
          };
          break;
        case "restore":
          routeConfig = {
            path: "/auth",
            query: { state: "restore", from: "web" },
          };
          break;
        case "signIn":
          routeConfig = {
            path: "/auth",
            query: { state: "singIn", from: "web" },
          };
          break;
        default:
          routeConfig = {
            path: "/auth",
            query: { state: "singIn", from: "web" },
          };
      }

      await this.$router.push(routeConfig);
      this.formState = data;
    },
  },
  watch: {
    queryState(value) {
      if (value) {
        this.formState = value;
      }
    },
  },
};
</script>

<style scoped>
.auth-form {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(233, 247, 249, 0.5);
  width: 100vw;
  height: calc(100vh - 12vh);
  /* margin-top: 12vh; */
  padding: 0 !important;
}
.toggle {
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  padding: 0.5rem;
  /* font-style: oblique 20deg; */
}
.bold-txt {
  font-weight: bold;
}

.login-logo {
  width: 15em;
  margin: auto;
  margin-bottom: 2em;
}

p > span {
  font-weight: bold;
}
@media screen and (min-width: 3800px) {
  .auth-form .auth-signup-form {
    max-width: 30vw !important;
  }
}
@media screen and (max-width: 580px) {
  .auth-form {
    background: rgba(255, 255, 255, 1);
    /* height: calc(100vh - 70px); */
    align-items: flex-start;
  }
}
</style>
