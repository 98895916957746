<template>
  <div>
    <!-- <home-page-mobile class="home-page-mobile" v-if="isMobile" /> -->
    <div ref="mainIndex" id="mainIndex" class="home-top--banner fill-height">
      <section class="section-click">
        <div class="outer">
          <div class="inner">
            <first-home-component
              id="one-home-component"
              class="one-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <about-rendezvu-home
              id="two-home-component"
              class="two-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <cases-home-page-first
              id="three-home-component"
              class="three-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <cases-home-page-second
              id="three-home-component"
              class="three-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <cases-home-page-third
              id="three-home-component"
              class="three-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <coming-soon-home-page-first
              id="four-home-component"
              class="four-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <coming-soon-home-page-second
              id="four-home-component"
              class="four-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <coming-soon-home-page-third
              id="four-home-component"
              class="four-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <technology-stack
              id="five-home-component"
              class="five-home-component home-section"
            />
          </div>
        </div>
      </section>
      <section class="section section-click">
        <div class="outer">
          <div class="inner">
            <footer-home-page
              id="six-home-component"
              class="six-home-component home-section"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import FirstHomeComponent from "./FirstHomeComponent.vue";
import AboutRendezvuHome from "./AboutRendezvuHome.vue";
import CasesHomePageFirst from "./CasesHomePageFirst.vue";
import CasesHomePageSecond from "./CasesHomePageSecond.vue";
import CasesHomePageThird from "./CasesHomePageThird.vue";
import ComingSoonHomePageFirst from "./ComingSoonHomePageFirst.vue";
import ComingSoonHomePageSecond from "./ComingSoonHomePageSecond.vue";
import ComingSoonHomePageThird from "./ComingSoonHomePageThird.vue";
import TechnologyStack from "./TechnologyStackHomePage.vue";
import FooterHomePage from "./FooterHomePage.vue";
// import HomePageMobile from "./MobileVersion/HomePageMobile.vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  data() {
    return {
      signedIn: false,
      windowHeight: "height:" + "100%",
      errorMessage: null,
      isMobile: false,
    };
  },
  components: {
    FirstHomeComponent,
    AboutRendezvuHome,
    CasesHomePageFirst,
    CasesHomePageSecond,
    CasesHomePageThird,
    ComingSoonHomePageFirst,
    ComingSoonHomePageSecond,
    ComingSoonHomePageThird,
    TechnologyStack,
    FooterHomePage,
    // HomePageMobile,
  },
  updated() {
    this.checkAuth();
  },
  computed: {},
  mounted() {
    this.errorMessage = this.$route.meta.errorMessage;
    gsap.registerPlugin(ScrollTrigger);

    this.scrollTransition();

    this.$nextTick(() => {
      this.checkAuth();
    });
    // if (window.screen.width <= 580) {
    //   this.isMobile = true;
    // } else {
    //   this.isMobile = false;
    // }
    const navigationMain = document.querySelector(".navigation-main");
    const devices = new RegExp(
      "Android|webOS|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini",
      "i"
    );

    window.addEventListener("resize", function () {
      if (devices.test(navigator.userAgent)) {
        if (window.screen.height < window.screen.width) {
          navigationMain.style.display = "flex";
        } else {
          navigationMain.style.display = "none";
        }
      }
    });

    if (window.innerWidth <= 767) {
      this.windowHeight = "height:" + (window.innerHeight - 100) + "px";
    } else {
      this.windowHeight = "height: auto";
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 767) {
        this.windowHeight = "height:" + (window.innerHeight - 100) + "px";
      } else {
        this.windowHeight = "height: auto";
      }
    });
  },
  methods: {
    move(to) {
      this.$router.push(to);
    },
    logOut() {
      awsAuth.signOut();
      this.$router.push("/auth");
      this.signedIn = false;
    },
    checkAuth() {
      awsAuth
        .authenticated()
        .then((response) => {
          this.userName = response.username;
          this.userLogo = response.username[0].toUpperCase();
          this.signedIn = true;
        })
        .catch(() => {
          this.signedIn = false;
        });
    },
    scrollTransition() {
      const sections = document.querySelectorAll("section");

      const outerWrappers = gsap.utils.toArray(".outer");
      const innerWrappers = gsap.utils.toArray(".inner");

      document.addEventListener("wheel", handleWheel);
      document.addEventListener("touchstart", handleTouchStart);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);

      let listening = false,
        direction = "down",
        current,
        next = 0;

      const touch = {
        startX: 0,
        startY: 0,
        dx: 0,
        dy: 0,
        startTime: 0,
        dt: 0,
      };

      const tlDefaults = {
        ease: "power1",
        duration: 1.23,
      };

      gsap.set(outerWrappers, { yPercent: 100 });
      gsap.set(innerWrappers, { yPercent: -100 });

      function slideIn() {
        if (current !== undefined) gsap.set(sections[current], { zIndex: 0 });

        gsap.set(sections[next], { autoAlpha: 1, zIndex: 1 });
        const tl = gsap
          .timeline({
            paused: true,
            defaults: tlDefaults,
            onComplete: () => {
              listening = true;
              current = next;
            },
          })
          .to([outerWrappers[next], innerWrappers[next]], { yPercent: 0 }, 0);

        if (current !== undefined) {
          tl.add(
            gsap
              .timeline()
              .set(outerWrappers[current], { yPercent: 100 })
              .set(innerWrappers[current], { yPercent: -100 })
              .set(sections[current], { autoAlpha: 0 })
          );
        }

        tl.play(0);
      }
      function slideOut() {
        gsap.set(sections[current], { zIndex: 1 });
        gsap.set(sections[next], { autoAlpha: 1, zIndex: 0 });
        gsap.set([outerWrappers[next], innerWrappers[next]], { yPercent: 0 });

        gsap
          .timeline({
            defaults: tlDefaults,
            onComplete: () => {
              listening = true;
              current = next;
            },
          })
          .to(outerWrappers[current], { yPercent: 100 }, 0)
          .to(innerWrappers[current], { yPercent: -100 }, 0);
      }

      function handleDirection() {
        listening = false;
        // const linkOne = document.querySelector("#linkOne");

        // const widthEl = linkOne.offsetWidth + 50;

        if (direction === "down") {
          next = current + 1;
          if (next === 1) {
            slideIn();
            setTimeout(() => {
              gsap.to(".home, .cases, .feature, .technology, .getInTouch", {
                color: "black",
              });
              gsap.to(".rendezvu", { color: "#1eafc1" });
              gsap.to(".icon-apple-main", { color: "black" });
              gsap.to(".icon-windows-download-main", { color: "black" });
            }, 1000);
          } else if (next === 2) {
            slideIn();
            setTimeout(() => {
              gsap.to(".cases", { color: "#1eafc1" });
              gsap.to(".home, .rendezvu, .technology, .feature, .getInTouch", {
                color: "black",
              });
              gsap.to(".icon-apple-main", { color: "black" });
              gsap.to(".icon-windows-download-main", { color: "black" });
            }, 700);
          } else if (next === 3) {
            slideIn();
          } else if (next === 4) {
            slideIn();
          } else if (next === 5) {
            slideIn();
            setTimeout(() => {
              gsap.to(".feature", { color: "#1eafc1" });
              gsap.to(".home, .rendezvu, .cases, .technology, .getInTouch", {
                color: "white",
              });
              gsap.to(".icon-apple-main", { color: "white" });
              gsap.to(".icon-windows-download-main", { color: "white" });
            }, 800);
          } else if (next === 6) {
            slideIn();
            setTimeout(() => {
              gsap.to(".feature", { color: "#1eafc1" });
              gsap.to(".home, .rendezvu, .cases, .technology, .getInTouch", {
                color: "black",
              });
              gsap.to(".icon-apple-main", { color: "black" });
              gsap.to(".icon-windows-download-main", { color: "black" });
            }, 800);
          } else if (next === 7) {
            slideIn();
          } else if (next === 8) {
            slideIn();
            setTimeout(() => {
              gsap.to(".technology", { color: "#1eafc1" });
              gsap.to(".home, .rendezvu, .cases, .feature, .getInTouch", {
                color: "black",
              });
              gsap.to(".icon-apple-main", { color: "black" });
              gsap.to(".icon-windows-download-main", { color: "black" });
            }, 700);
          } else if (next === 9) {
            slideIn();
            setTimeout(() => {
              gsap.to(".getInTouch", { color: "#1eafc1" });
              gsap.to(".home, .rendezvu, .cases, .feature, .technology", {
                color: "white",
              });
              gsap.to(".icon-apple-main", { color: "white" });
              gsap.to(".icon-windows-download-main", { color: "white" });
            }, 700);
          }

          if (next >= sections.length) {
            listening = true;
          }
        }

        if (direction === "up") {
          next = current - 1;
          if (next === 8) {
            gsap.to(".technology", { color: "#1eafc1" });
            gsap.to(".home, .rendezvu, .cases, .feature, .getInTouch", {
              color: "black",
            });
            gsap.to(".icon-apple-main", { color: "black" });
            gsap.to(".icon-windows-download-main", { color: "black" });
          } else if (next === 7) {
            gsap.to(".feature", { color: "#1eafc1" });
            gsap.to(".home, .rendezvu, .cases, .technology, .getInTouch", {
              color: "black",
            });
            gsap.to(".icon-apple-main", { color: "black" });
            gsap.to(".icon-windows-download-main", { color: "black" });
          } else if (next === 6) {
            gsap.to(".feature", { color: "#1eafc1" });
            gsap.to(".home, .rendezvu, .cases, .technology, .getInTouch", {
              color: "black",
            });
            gsap.to(".icon-apple-main", { color: "black" });
            gsap.to(".icon-windows-download-main", { color: "black" });
          } else if (next === 5) {
            gsap.to(".feature", { color: "#1eafc1" });
            gsap.to(".home, .rendezvu, .cases, .technology, .getInTouch", {
              color: "white",
            });
            gsap.to(".icon-apple-main", { color: "white" });
            gsap.to(".icon-windows-download-main", { color: "white" });
          } else if (next === 4) {
            gsap.to(".cases", { color: "#1eafc1" });
            gsap.to(".home, .rendezvu, .technology, .feature, .getInTouch", {
              color: "black",
            });
            gsap.to(".icon-apple-main", { color: "black" });
            gsap.to(".icon-windows-download-main", { color: "black" });
          } else if (next === 3) {
            gsap.to(".cases", { color: "#1eafc1" });
            gsap.to(".home, .rendezvu, .technology, .feature, .getInTouch", {
              color: "black",
            });
            gsap.to(".icon-apple-main", { color: "black" });
            gsap.to(".icon-windows-download-main", { color: "black" });
          } else if (next === 2) {
            gsap.to(".cases", { color: "#1eafc1" });
            gsap.to(".home, .rendezvu, .technology, .feature, .getInTouch", {
              color: "black",
            });
            gsap.to(".icon-apple-main", { color: "black" });
            gsap.to(".icon-windows-download-main", { color: "black" });
          } else if (next === 1) {
            gsap.to(".home, .cases, .feature, .technology, .getInTouch", {
              color: "black",
            });
            gsap.to(".rendezvu", { color: "#1eafc1" });
            gsap.to(".icon-apple-main", { color: "black" });
            gsap.to(".icon-windows-download-main", { color: "black" });
          } else if (next === 0) {
            gsap.to(".rendezvu, .cases, .feature, .technology, .getInTouch", {
              color: "white",
            });
            gsap.to(".home", { color: "#1eafc1" });
            gsap.to(".icon-apple-main", { color: "white" });
            gsap.to(".icon-windows-download-main", { color: "white" });
          }
          if (next < 0) next = 0;
          slideOut();
        }
      }

      function handleWheel(e) {
        if (!listening) return;

        direction = e.wheelDeltaY < 0 ? "down" : "up";
        handleDirection();
      }

      function handleTouchStart(e) {
        if (!listening) return;
        const t = e.changedTouches[0];
        touch.startX = t.pageX;
        touch.startY = t.pageY;
      }

      function handleTouchMove(e) {
        if (!listening) return;
        e.preventDefault();
      }

      function handleTouchEnd(e) {
        if (!listening) return;
        const t = e.changedTouches[0];
        touch.dx = t.pageX - touch.startX;
        touch.dy = t.pageY - touch.startY;
        if (touch.dy > 10) {
          direction = "up";
        }
        if (touch.dy < -10) {
          direction = "down";
        }
        handleDirection();
      }
      slideIn();
    },
  },
};
</script>

<style lang="scss" scoped>
.activeMenu {
  color: #1eafc1 !important;
  border-bottom-color: #1eafc1;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  background-color: none !important;
}

.scale-in-ver-bottom {
  -webkit-animation: scale-in-ver-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}

.scale-out-ver-top {
  -webkit-animation: scale-out-ver-top 0.5s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: scale-out-ver-top 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
.description {
  font-weight: 100;
  text-align: justify;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 5px;
}
.login {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding-left: 17px;
}
article.reversed {
  background: transparent;
}

article.about {
  padding: 5em 0;
}

.shows {
  margin: 2rem;
}

.home-top--banner {
  display: block;
  position: relative;
  font-size: 14px;
  width: 100vw;
  font-family: Poppins;

  @media screen and (max-width: 767px) {
    font-size: 9px;
  }
}
.svg {
  margin: 50px;
}

.home-top--show {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  @media screen and (max-width: 767px) {
    top: auto;
    bottom: 0;
  }
  div {
    color: #1eafc1;
    font-size: 2em;
  }
  h1 {
    font-size: 3em;
    line-height: 1;
  }
  h2 {
    font-size: 1.5em;
    font-weight: 200;
  }
  .live-icon {
    margin-right: 10px;
  }
  .home-buttons {
    button {
      width: 100%;
      margin-top: 1em;
    }
  }
  @media screen and (min-width: 768px) {
    .home-buttons {
      display: flex;
      justify-content: space-between;
      max-width: 300px;
      margin: auto;
      margin-top: 1em;
      button {
        width: 48%;
      }
      & > button {
        margin-right: 1em;
      }
    }
  }

  .v-btn--block {
    min-width: auto !important;
  }
  @media screen and (max-width: 767px) {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  main .order-2 {
    padding-left: 0 !important;
    padding-right: 0;
  }
  .about header,
  .about main {
    padding: 0 1em;
  }

  main header {
    margin-bottom: 1em !important;
  }
  .upcoming--show.info-section {
    .row {
      flex-direction: column-reverse;
    }
  }
  .about.info-section {
    .image {
      text-align: center;
      justify-content: center;
      img {
        max-width: 200px !important;
      }
    }
  }
  .info-section {
    .row {
      flex-direction: column;

      .col {
        max-width: 100%;
      }
      .col-4 {
        flex: 100%;
        max-width: 100% !important;
      }
      .col-6 {
        flex: 100%;
        max-width: 100% !important;
        padding-left: 0 !important;
      }
    }
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
  }
}

.section {
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  visibility: hidden;
  overflow: hidden;

  .outer,
  .inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>

