<template>
  <div class="home-page" id="home">
    <div class="text-home">
      <h1 class="slide-bottom">RendezVu</h1>
      <h3 class="text-focus-in">
        Where the physical and digital worlds<br />
        meet in the Metaverse
      </h3>
      <img
        class="cursor rotate-center"
        src="../../assets/icon-cursor.svg"
        alt=""
      />
    </div>
    <div class="additional">
      <div class="one">
        <img class="icon-blur" src="../../assets/icon-movement.svg" alt="" />
        <p>Intuitive physics and movement</p>
      </div>
      <div class="two">
        <img class="icon-blur" src="../../assets/icon-environment.svg" alt="" />
        <p>Comfortable hybrid environment</p>
      </div>
      <div class="one">
        <img class="icon-blur" src="../../assets/icon-device.svg" alt="" />
        <p>Instantly accessible from any device.</p>
      </div>
    </div>
    <div class="additional-tablet">
      <div class="one">
        <img class="icon-blur" src="../../assets/icon-movement.svg" alt="" />
        <p>
          Intuitive physics<br />
          and movement
        </p>
      </div>
      <div class="two">
        <img class="icon-blur" src="../../assets/icon-environment.svg" alt="" />
        <p>
          Comfortable<br />
          hybrid environment
        </p>
      </div>
      <div class="one">
        <img class="icon-blur" src="../../assets/icon-device.svg" alt="" />
        <p>
          Instantly accessible<br />
          from any device.
        </p>
      </div>
    </div>

    <div class="full-screen__video_back">
      <iframe
        class="full-screen__video"
        src="https://player.vimeo.com/video/700773708?h=769b6c0670&amp;badge=0&amp;background=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="autoplay; fullscreen; picture-in-picture"
      ></iframe>

      <iframe
        class="full-screen__video_tablet"
        src="https://player.vimeo.com/video/701943124?h=d12d250556&amp;background=1&amp;loop=1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        frameborder="0"
        allowfullscreen
        allow="autoplay; fullscreen; picture-in-picture"
      ></iframe>

      <iframe
        class="full-screen__video_mobile"
        src="https://player.vimeo.com/video/701942902?h=94503c77c0&amp;background=1&amp;badge=0&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  data() {
    return {
      height: window.screen.height,
      options: {
        muted: true,
        autoplay: true,
        loop: true,
        background: true,
        controls: false,
        responsive: true,
        width: "100%",
        height: "100%",
        byline: false,
      },
    };
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const home = document.querySelector(".home");
    gsap.to(home, {
      color: "#1eafc1",
    });

  },
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -12vh;
  height: 100vh;
  top: 0;
  position: relative;
  // background-image: linear-gradient(
  //     rgba(37, 33, 33, 0) 70%,
  //     rgba(37, 33, 33, 0.9) 95%
  //   ),
  //   url("../../assets/main-page-content.jpg");
  // background-size: cover;
  flex-direction: column;
  color: white;
}
.full-screen__video_back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.full-screen__video_tablet {
  display: none;
}
.full-screen__video_mobile {
  display: none;
}
.full-screen__video {
  // display: block;
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;

  // z-index: -2;
  // background-color: rgba(0,0,0,0.7);
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.additional-tablet {
  display: none;
}
.additional {
  display: none;
  width: 100%;
  position: relative;
  // flex-direction: row;
  justify-content: space-around;
  margin-top: 28vh;
  font-size: 13px;
  // align-items: stretch;
  // grid-template-areas: "A A A A B B B B C C C C";
}
.one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: one 1s forwards;
  animation-delay: 1.5s;
}
@keyframes one {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 3vw;
  opacity: 0;
  animation: two 1s forwards;
  animation-delay: 1s;
}

@keyframes two {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cursor {
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  width: 7vw;

  img {
    height: 4vh;
  }
}
.icon-blur {
  width: 5vw;
  margin-bottom: 2vh;
}

.text-home {
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-top: 11vh;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  h1 {
    font-weight: 500;
    font-size: 48px;
  }
}
.slide-bottom {
  -webkit-animation: slide-bottom 1s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    alternate both;
  animation: slide-bottom 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) alternate
    both;
}
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
    transition: 1s;
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
    transition: 1s;
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
    transition: 1s;
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
    transition: 1s;
  }
}
.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.rotate-center {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 1s ease-in-out both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.scale-out-ver-top {
  -webkit-animation: scale-out-ver-top 0.5s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: scale-out-ver-top 4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
.activeClass {
  color: #1eafc1;
  opacity: 0;
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .full-screen__video {
    display: none;
  }
  .full-screen__video_mobile {
    display: none;
  }
  .full-screen__video_tablet {
    display: block;
    position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 110%;

    // z-index: -2;
    width: 100vw;
    height: 133vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 75vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    left: 0%;
    transform: translate(-0%, -0%);
  }
  .home-page {
    // background-image: linear-gradient(
    //     rgba(37, 33, 33, 0) 70%,
    //     rgba(37, 33, 33, 0.9) 95%
    //   ),
    //   url("../../assets/main-page-content-tablet.jpg");
  }
  .text-home {
    margin-top: 15%;
    @media screen and (max-width: 820px) {
      margin-top: 15%;
    }

    h1 {
      font-weight: 500;
      font-size: 79px;
      @media screen and (max-width: 700px) {
        font-size: 59px;
      }
    }
    h3 {
      font-size: 24px;
      @media screen and (max-width: 700px) {
        font-size: 18px;
      }
    }
  }
  .cursor {
    margin-top: 19%;
    width: 17vw;
    @media screen and (max-width: 700px) {
      margin-top: 15%;
      width: 14vw;
    }

    img {
      height: 4vh;
    }
  }
  .icon-blur {
    width: 10vw;
    margin-bottom: 2vh;
    @media screen and (max-width: 820px) {
      width: 11vw;
    }
    @media screen and (max-width: 700px) {
      width: 10vw;
    }
  }
  .additional-tablet {
    display: none;
    text-align: center;
    // align-items: center;

    margin-top: 35%;
    font-size: 1.2rem;
    @media screen and (max-width: 820px) {
      font-size: 1rem;
      margin-top: 40%;
    }
    @media screen and (max-width: 820px) {
      margin-top: 30%;
    }
    @media screen and (max-width: 768px) {
      margin-top: 30%;
    }
    @media screen and (max-width: 700px) {
      margin-top: 25%;
      font-size: 0.8rem;
    }
  }
  .additional {
    display: none;
  }
  .two {
    margin-right: 9vw;
    margin-left: 9vw;
    @media screen and (max-width: 820px) {
      margin-right: 9vw;
      margin-left: 9vw;
    }
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .additional {
    margin-top: 28vh;
    font-size: 12px;
    @media screen and (max-device-width: 1000px) {
      font-size: 10px;
    }
  }
  .two {
    // margin-right: 10vw;
    // margin-left: 10vw;
  }

  .text-home {
    margin-top: 10vh;
    h1 {
      font-size: 44px;
    }
  }
  .text-focus-in {
    margin-top: 20px;
    font-size: 12px;
  }
}

@media screen and (min-width: 1920px) {
  .text-home {
    margin-top: 10vh;

    h1 {
      font-weight: 400;
      font-size: 70px;
    }
    h3 {
      font-size: 20px;
    }
  }
  .additional {
    margin-top: 22vh;
  }
  .two {
    margin-right: 10vw;
    margin-left: 10vw;
    p {
      font-size: 20px;
    }
  }
  .one {
    p {
      font-size: 20px;
    }
  }
  .cursor {
    margin-top: 25%;
  }
}
@media screen and (min-width: 2800px) {
  .additional {
    margin-top: 25vh;
    font-size: 23px;
  }
  .two {
    p {
      font-size: 35px;
    }
  }
  .one {
    p {
      font-size: 40px;
    }
  }
  .cursor {
    margin-top: 14%;
    width: 6vw;
  }
  .icon-blur {
    width: 5vw;
  }

  .text-home {
    margin-top: 10vh;
    h1 {
      font-size: 130px;
    }
    h3 {
      font-size: 35px;
    }
  }
}
@media screen and (min-width: 3800px) {
  .additional {
    margin-top: 25vh;
    font-size: 23px;
  }
  .two {
    p {
      font-size: 35px;
    }
  }
  .one {
    p {
      font-size: 40px;
    }
  }
  .cursor {
    margin-top: 14%;
    width: 6vw;
  }
  .icon-blur {
    width: 5vw;
  }

  .text-home {
    margin-top: 10vh;
    h1 {
      font-size: 130px;
    }
    h3 {
      font-size: 35px;
    }
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .home-page {
    min-width: 100vw;
    display: flex;
    align-items: center;
    margin-top: -70px !important;
    height: 100vh;
    // background-image: linear-gradient(
    //     rgba(37, 33, 33, 0) 70%,
    //     rgba(37, 33, 33, 0.9) 95%
    //   ),
    //   linear-gradient(
    //     180deg,
    //     rgba(51, 179, 196, 0.1) 0%,
    //     rgba(237, 63, 189, 0.1) 47.41%,
    //     rgba(237, 63, 189, 0) 91.44%
    //   ),
    //   url("../../assets/main-page-content-mobile.jpg");
    background-size: cover;
    flex-direction: column;
    color: white;
  }
  .full-screen__video {
    display: none;
  }
  .full-screen__video_tablet {
    display: none;
  }
  .full-screen__video_mobile {
    // display: block;
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;

    // z-index: -2;
    display: block;
    position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 110%;

    // z-index: -2;
    width: 110vw;
    height: 197.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width:  197.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .additional-tablet {
    display: none;
  }
  .additional {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    margin-top: 18vh;
    font-size: 13px;
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 135%;
      text-align: center;
    }
  }
  .one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
  }
  .two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
  }
  .three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .cursor {
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 45vh;
    opacity: 1;
    width: 35vw;
  }
  .icon-blur-mobile {
    width: 25vw;
    margin-bottom: 2vh;
  }

  .text-home {
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    h1 {
      font-weight: 500;
      font-family: Poppins;
      font-style: normal;

      font-size: 40px;
      line-height: 1.5;
      // font-size: 48px;
    }
    h3 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.5;
    }
  }
  .slide-bottom {
    -webkit-animation: slide-bottom 1s cubic-bezier(0.55, 0.055, 0.675, 0.19)
      alternate both;
    animation: slide-bottom 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) alternate
      both;
  }
  @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 0;
      transition: 1s;
    }
    100% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
      opacity: 1;
      transition: 1s;
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 0;
      transition: 1s;
    }
    100% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
      opacity: 1;
      transition: 1s;
    }
  }
  .text-focus-in {
    -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53)
      both;
    animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    margin-top: 20px;
    font-size: 14px;
    font-weight: normal;
  }

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }

  .rotate-center {
    -webkit-animation: rotate-center 0.6s ease-in-out both;
    animation: rotate-center 1s ease-in-out both;
    animation-delay: 1;
  }

  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .scale-out-ver-top {
    -webkit-animation: scale-out-ver-top 0.5s
      cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: scale-out-ver-top 4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  @-webkit-keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  .animonFirst {
    opacity: 1;
    will-change: opacity, transform;
    transition: opacity 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1),
      transform 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1);
  }
  .animonFirst.is-visible {
    opacity: 0;
  }

  .animonSecond {
    opacity: 0;
    will-change: opacity, transform;
    transition: opacity 2000ms 2000ms cubic-bezier(0.5, 1, 0.89, 1),
      transform 2000ms 2000ms cubic-bezier(0.5, 1, 0.89, 1);
  }
  .animonSecond.is-visible {
    opacity: 1;
  }
  .animonSecond[data-effect="fadeInUp"] {
    opacity: 0;
  }
  .animonSecond[data-effect="fadeInUp"].is-visible {
    opacity: 1;
  }

  @media screen and (max-width: 375px) {
    .text-home {
      margin-top: 10vh;
    }
  }
  @media screen and (max-height: 600px) {
    .cursor {
      display: none;
      justify-content: center;
      align-items: center;
      margin-top: 40vh;
      opacity: 1;
      width: 35vw;
    }
    .icon-blur {
      width: 25vw;
      margin-bottom: 2vh;
    }

    .text-home {
      h1 {
        font-size: 36px;
      }
      h3 {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-height: 500px) {
    .cursor {
      margin-top: 30vh;
    }
  }
}
</style>
