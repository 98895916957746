<template>
    <v-snackbar
      :value="snackbar.visible"
      v-model="showSnackbar"
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout || 2500"
      bottom
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>
            {{ snackbar.text }}
            <span></span>
          </div>

        </v-layout>
      </v-layout>
      <template v-slot:action v-if="snackbar.timeout == -1">
        <v-btn
          text
          @click="showSnackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    computed: {
      ...mapState({
        snackbar: (state) => state.ui.snackbar,
      }),
      showSnackbar: {
        get() {
          return this.$store.state.ui.snackbar.visible;
        },
        set(value) {
          this.$store.commit('updateSnackbar', value);
        }
      }
    },
  }
</script>
