import HomePage from './components/homepage-new/HomePage.vue';
import AuthComponent from './components/User/Auth';
import SignOut from './components/User/SignOut';
import ErrorPage from './components/homepage-new/ErrorPage.vue';
import SetPassword from './components/profile/SetPassword';
import ContactMobile from './components/homepage-new/MobileVersion/ContactMobile';
import SessionEnded from '@/components/online/SessionEnded';

const routes = [
  {
    path: '/',
    name: 'Main',
    component: HomePage
  },
  {
    path: '/auth',
    name: 'Login',
    component: AuthComponent,
    meta: { public: true, disableIfLoggedIn: true }
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: ErrorPage,
    meta: { public: true, errorMessage: "Access forbidden" }
  },
  {
    path: '/banned',
    name: 'Banned',
    component: ErrorPage,
    meta: { requiresAuth: true, errorMessage: "We are sorry, but the administrator has restricted your access to the RendezVu. If you think an error has occurred, please contact the support team." }
  },
  {
    path: '/terminated',
    component: SignOut,
    meta: { errorMessage: "Your account was terminated. Please contact your administrator." }
  },
  {
    path: '/sessionEnded',
    component: SessionEnded,
    meta: { requiresAuth: true }
  },
  {
    path: '/sign-out',
    component: SignOut
  },
  {
    path: '/admin',
    redirect: '/admin/users'
    // component: () => import('@/components/admin/AdminWrapper'),
    // meta: { requiresAuth: true, requiresAdmin: true },
    // children: [
    //   { path: '', component: () => import('@/components/admin/users/Users') }
    // ]
  },
  {
    path: '/admin/users',
    component: () => import('@/components/admin/AdminWrapper'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: '', component: () => import('@/components/admin/users/Users') }
    ]
  },
  {
    path: '/admin/companies',
    component: () => import('@/components/admin/AdminWrapper'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: '', component: () => import('@/components/admin/company/Companies') }
    ]
  },
  {
    path: '/admin/companies/:id',
    component: () => import('@/components/admin/AdminWrapper'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: '', component: () => import('@/components/admin/company/CompanyDetail') }
    ]
  },
  {
    path: '/admin/servers',
    component: () => import('@/components/admin/AdminWrapper'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: '', component: () => import('@/components/admin/servers/Servers') }
    ]
  },
  {
    path: '/admin/servers/:caption',
    component: () => import('@/components/admin/AdminWrapper'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: '', component: () => import('@/components/admin/servers/ServerDetail') }
    ]
  },
  {
    path: '/admin/import',
    component: () => import('@/components/admin/AdminWrapper'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: '', component: () => import('@/components/admin/AdminImport') }
    ]
  },
  {
    path: '/admin/stream',
    component: () => import('@/components/admin/AdminWrapper'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: '', component: () => import('@/components/admin/stream/Stream.vue') }
    ]
  },
  {
    path: '/admin/stream/:caption',
    component: () => import('@/components/admin/AdminWrapper'),
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: '', component: () => import('@/components/admin/stream/StreamSettings.vue') }
    ]
  },
  {
    path: '/profile',
    component: () => import('@/components/profile/ProfileWrapper'),
    meta: { requiresAuth: true },
    children: [
      { path: '', component: () => import('@/components/profile/MyProfile') }
    ]
  },
  {
    path: '/online',
    redirect: '/forbidden'
  },
  {
    path: '/password',
    component: () => import('@/components/profile/ProfileWrapper'),
    meta: { requiresAuth: true },
    children: [
      { path: '', component: () => import('@/components/profile/PasswordEdit') }
    ]
  },
  {
    path: '/confirmuser',
    meta: { disableIfLoggedIn: true },
    component: SetPassword,

  },
  {
    path: '/create-avatar/:guid',
    component: () => import('@/components/avatar/AvatarPage.vue')
  },
  {
    path: '/download',
    component: () => import('@/components/profile/AppDownloadLinks.vue'),
  },
  {
    path: '/contacts',
    component: ContactMobile
  },
];

export default routes;
