<template>
  <article class="page" v-bind:class="'route-' + $route.path.replace('/', '')">
    <header
      :class="$route.path !== '/' ? '' : 'header'" v-show="!hide"
    >
      <page-header :transparent="transparentHeader" />
    </header>
    <snackbar />

    <v-main :class="$route.path.includes('admin') ? 'admin-main-wrapper' : ''">
      <v-container
        class="v-container"
        :class="$route.path !== '/' ? 'container-main' : 'container-main-page'"
      >
        <slot />
      </v-container>
    </v-main>
  </article>
</template>

<script>
import PageHeader from "./PageHeader";

import { mapState } from "vuex";
import loader from "@/config.loader";
import Snackbar from "./Snackbar";

export default {
  props: {
    transparentHeader: { type: Boolean, default: false },
  },
  data() {
    return {
      imgVisible: false,
    };
  },
  created() {
    this.openZendesk();
    // this.$store.dispatch("toggleLogging");
  },
  mounted() {
    window.addEventListener("DOMContentLoaded", () => {
      this.imgVisible = true;
    });
  },
  computed: {
    ...mapState({
      hide: (state) => state.ui.hideUI,
      hideFooter: (state) => state.ui.hideFooter,
      // logs: (state) => state.logs,
    }),
  },
  components: {
    PageHeader,

    Snackbar,
  },
  methods: {
    async openZendesk() {
      const key = loader.getConfigValue("VUE_ZENDESK_KEY");
      var self = this;
      self.$zendesk.$on("loaded", () => {
        this.$zendesk.setLocale("en");
        this.$zendesk.show();
      });
      self.$zendesk.load(key);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  flex-flow: column nowrap;
  position: relative;
}

.container-main {
  height: 100%;
}
.container-main-page {
  margin: 0;
  padding: 0;
  height: 100%;
}
.v-container {
  max-width: 100% !important;
  padding: 0 !important;
}
.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 12vh;
}
.info-header {
  background-color: white;
  height: 10%;
  position: relative;
  margin-top: 65vh;
  width: 65vw;
  border: white solid 1px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 25%;
    margin: 15px 10px;
  }
}

main {
  flex-grow: 2;
}

.wrapper-img {
  display: inline;
  margin-top: 65vw;
  position: absolute;
}
.header-img {
  position: absolute;
}

footer {
  background-color: white;
  min-height: 75px;

  .v-footer {
    background-color: white;
  }
}
// @media screen and (min-width: 581px) and (max-width: 1025px) {
// .header {
//     height: 12vh;
//     width: 100vh;
//   }
// }
@media screen and (max-width: 580px) {
  .v-main {
    // margin-top: 100px;
    width: 100vw;
  }
  .header {
    height: 70px;
    width: 100%;
  }
}
@media screen and (min-width: 3800px) {
  //   .header {
  //   height: 20vh;
  // }
}
</style>
