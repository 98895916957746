<template>
  <div class="auth-signup-form signup-form">
    <v-card-text v-if="!reveal">
      <div>
        <v-col class="log-input log-google">
          <v-btn block> log in with google </v-btn>
        </v-col>
        <div class="form-header">
          <span class="text-registration">Registration</span>
          <invited-by-badge
            v-if="invitedBy"
            class="invited"
            :invitedBy="invitedBy"
          />
        </div>
        <div class="form-error">{{ notification }}</div>
        <v-row class="signup-form-mobile" gutter="20">
          <v-col class="log-input">
            <v-text-field
              label="Username"
              v-model="form.username"
              outlined
              maxlength="15"
              :rules="rules"
              hide-details="auto"
              @input="usernameInput"
              ><template v-slot:prepend-inner>
                <img
                  class="profile-icon"
                  height="20px"
                  src="../../assets/icon-people-profile.svg"
                  alt=""
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col class="log-input">
            <v-text-field
              label="Email"
              v-model="form.email"
              :rules="emailConfirmationRules"
              hide-details="auto"
              outlined
              ><template v-slot:prepend-inner>
                <img
                  class="profile-icon"
                  height="20px"
                  src="../../assets/icon-email-feedback.svg"
                  alt=""
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="signup-form-mobile">
          <v-col class="log-input">
            <v-text-field
              label="Enter Password"
              :type="hidePassword ? 'password' : 'text'"
              v-model="form.password"
              :rules="passwordRules"
              hide-details="auto"
              outlined
              :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
              @input="passwordInput"
              @click:append="hidePassword = !hidePassword"
              ><template v-slot:prepend-inner>
                <img
                  class="profile-icon"
                  height="20px"
                  src="../../assets/icon-lock.svg"
                  alt=""
                /> </template
            ></v-text-field>
          </v-col>
          <v-col class="log-input">
            <v-text-field
              ref="confirmPassword"
              label="Repeat Password"
              :type="hideConfirm ? 'password' : 'text'"
              v-model="form.confirmPassword"
              :rules="passwordConfirmRules"
              hide-details="auto"
              outlined
              :append-icon="hideConfirm ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="hideConfirm = !hideConfirm"
              @keyup.enter="signUp"
              ><template v-slot:prepend-inner>
                <img
                  class="profile-icon"
                  height="20px"
                  src="../../assets/icon-lock.svg"
                  alt=""
                /> </template
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions v-if="!reveal">
      <v-row>
        <v-col cols="6">
          <v-btn
            color="primary"
            @click="signUp"
            :loading="isLoading"
            height="60px"
            class="primary-btn"
            :disabled="!registerButtonDisabled"
          >
            Register
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-expand-transition>
      <div
        v-if="reveal"
        class="
          transition-fast-in-fast-out
          align-self-center
          v-card--reveal
          pa-5
          sign-up-full
        "
      >
        <v-card-text>
          <p class="display-1">Confirmation</p>
          <p class="p-confirm">Please, enter the received code</p>
          <div class="text-field-signup">
            <v-text-field
              class="text-field-confirm"
              label="confirmation code"
              v-model="form.authCode"
              :rules="rules"
              outlined
              hide-details="auto"
              height="40px"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-card-actions class="pt-">
          <v-btn
            class="signup-btn"
            color="white"
            height="60px"
            @click="confirmSignUp"
          >
            Confirm Sign Up
          </v-btn>
          <v-btn class="signup-btn" height="60px" @click="reveal = false">
            Close
          </v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
    <v-overlay :absolute="absolute" :value="overlay"> </v-overlay>
    <br />
    <v-progress-linear v-if="overlay" indeterminate color="gray accent-4">
    </v-progress-linear>
  </div>
</template>

<script>
//import { Auth } from 'aws-amplify'
import awsAuth from "@/cognito/aws-auth";
import { mapActions } from "vuex";
import InvitedByBadge from "./InvitedByBadge";
import { Constants } from "@/config/constants";

export default {
  name: "signup",
  components: {
    InvitedByBadge,
  },
  props: ["toggle"],
  data() {
    return {
      invitedBy:
        Constants.Invitations[this.$route.query.from?.toLowerCase()] || "",
      isLoading: false,
      hidePassword: true,
      hideConfirm: true,
      formState: "signUp",
      reveal: false,
      absolute: true,
      overlay: false,
      multiLine: true,
      notification: "Enter your information",
      rules: [
        (value) => !!value || "Required",
        (value) => (value && value.length >= 4) || "Min 4 characters",
      ],
      passwordRules: [
        (value) => !!value || "Required",
        (value) => (value && value.length >= 6) || "Min 6 characters",
      ],
      passwordConfirmRules: [
        (value) => !!value || "Required",
        (value) => value === this.form.password || "Password mismatch",
      ],
      emailConfirmationRules: [
        (value) =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Please enter valid email",
        (value) => !!value || "Required",
      ],
      form: {
        username: "",
        password: "",
        email: "",
      },
    };
  },
  computed: {
    registerButtonDisabled() {
      if (
        this.form.username &&
        this.form.password &&
        this.form.confirmPassword &&
        this.form.email
      ) {
        return (
          this.form.username.length > 3 &&
          this.form.password.length > 5 &&
          this.form.confirmPassword === this.form.password &&
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.form.email
          )
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    async signIn() {
      const { username, password } = this.form;
      const self = this;
      self.overlay = true;
      await self.$store
        .dispatch("auth/login", { username, password })
        .catch((error) => {
          self.overlay = false;
          self.notification = `ERROR! ${error.message}`;
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: error.message,
          });
        });
    },
    async signUp() {
      this.overlay = true;
      this.isLoading = true;
      const { username, password, email } = this.form;
      const company = this.invitedBy || "";

      this.$store
        .dispatch("auth/signup", {
          ...{ username, email, password },
          ...{ company },
        })
        .then(() => {
          this.isLoading = false;
          this.formState = "confirmSignUp";
          this.reveal = true;
        })
        .catch((error) => {
          if (error.message === "This email is not confirmed") {
            awsAuth
              .preSignUp(email)
              .then((res) => {
                if (
                  res.data.statusText === "UNCONFIRMED" &&
                  res.data.username
                ) {
                  awsAuth
                    .resendConfirmationCode(res.data.username)
                    .then((confirmationRes) => {
                      this.snackbar({
                        color: "success",
                        icon: "mdi-check-circle",
                        text:
                          "Confirmation code was sent to " +
                          confirmationRes.data.CodeDeliveryDetails.Destination,
                        timeout: -1,
                      });

                      this.isLoading = false;
                      this.formState = "confirmSignUp";
                      this.form.username = res.data.username;
                      this.reveal = true;
                    })
                    .catch((error) => {
                      this.isLoading = false;
                      this.notification = `ERROR! ${error.message}`;

                      this.snackbar({
                        color: "error",
                        icon: "mdi-alert-circle-outline",
                        title: "Error",
                        text: error.message,
                      });
                    });
                }
              })
              .catch(() => {
                this.notification =
                  "ERROR! Something went wrong, please contact your administrator";
                this.isLoading = false;

                this.snackbar({
                  color: "error",
                  icon: "mdi-alert-circle-outline",
                  title: "Error",
                  text: "Something went wrong, please contact your administrator",
                  timeout: -1,
                });
              });
          } else {
            this.isLoading = false;
            this.notification = `ERROR! ${error.message}`;

            this.snackbar({
              color: "error",
              icon: "mdi-alert-circle-outline",
              title: "Error",
              text: error.message,
            });
          }
        });
      this.overlay = false;
    },
    async confirmSignUp() {
      const self = this;
      try {
        const { username, authCode } = this.form;
        this.overlay = true;
        await awsAuth.confirmRegister(username, authCode);

        this.signIn().then(async () => {
          await awsAuth.addUserToGroup(username, "guest");
          await awsAuth.refreshJWTToken();

          self.notification = `Welcome to RendezVu!`;
          this.overlay = false;
          self.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: self.notification,
          });
          self.$router.push("/profile");
        });
      } catch (error) {
        this.overlay = false;
        this.notification = `ERROR! ${error.message}`;
        this.snackbar({
          color: "error",
          icon: "mdi-alert-circle-outline",
          title: "Error",
          text: error.message,
        });
      }
    },
    usernameInput(value) {
      this.form.username = value.replaceAll(" ", "_");
    },
    passwordInput() {
      if (this.form.confirmPassword) {
        this.$refs.confirmPassword.validate();
      }
    },
  },
};
</script>

<style scoped>
.signup-form {
  padding: 20px 30px 10px 30px;
  width: 50vw;
  background-color: white;
  border: 1px solid #e9eeef;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
h2 span {
  background: #fff;
  padding: 0 10px;
}
.log-google {
  display: none;
}
.display-1 {
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  color: #252733;
}
.p-confirm {
  font-size: 13px;
  line-height: 100%;
  color: #99b4b9;
}
.login-form {
  padding: 8px 0px;
  padding-top: 16px;
}
.text-registration {
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  color: #252733;
}
.form-error {
  font-weight: normal;
  font-size: 15px;
  line-height: 100%;
  color: #99b4b9;
}
 .profile-icon {
    margin-right: 0.5vw;
  }
.primary-btn {
  width: 21vw;
  text-transform: none !important;
}
.text-field-signup {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 20vw;
  margin-right: auto;
  margin-left: auto;
}
.signup-btn {
  text-transform: none;
  width: 9.7vw;
}
.auth-signup-form span {
    text-align: left !important;
  }
  .auth-signup-form .form-error {
    text-align: left;
  }
@media screen and (min-width: 1500px) {
  .primary-btn {
    width: 19vw;
  }
}
@media screen and (min-width: 1800px) {
  .primary-btn {
    width: 17vw;
  }
  .signup-form {
    /* width: 30vw; */
  }
  .text-field-signup {
    width: 20vw;
  }
  .signup-btn {
    width: 9.7vw !important;
  }
}
@media screen and (min-width: 1920px) {
  .primary-btn {
    width: 15.5vw;
  }
  .signup-form {
    /* width: 30vw; */
  }
  .text-field-signup {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 20vw !important;
  }
  .signup-btn {
    width: 10vw !important;
  }
}
@media screen and (min-width: 2100px) {
  .primary-btn {
    width: 14vw;
  }
  .text-field-signup {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 20vw !important;
  }
}
@media screen and (min-width: 2400px) {
 .primary-btn {
    width: 12.5vw;
  }
}
@media screen and (min-width: 2560px) {
 .primary-btn {
    width: 11.7vw;
  }
}
@media screen and (min-width: 2870px) {
  .primary-btn {
    width: 10.5vw;
  }
  .text-field-signup {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 15vw !important;
  }
  .signup-btn {
    width: 7.4vw !important;
  }
  .text-registration {
    font-size: 32px;
  }

  .log-input {
    height: 90px;
  }

  h2 {
   font-size: 24px;
  }
  h2 span {
    background: #fff;
    padding: 0 10px;
    color: #000;
    font-size: 0.9em;
    font-weight: 450;
  }

  .log-google {
    display: none;
  }

  .v-text-field--outlined.v-input--dense .v-label {
    top: 20px;
  }
}
/* >>> .v-label {
  top: 50% !important;
  transform: translateY(-50%);
} */

>>> .v-text-field .v-input__prepend-inner {
  color: #242424 !important;
  align-self: center;
}

>>> .v-icon__component {
  height: 20px;
  width: 20px;
}

.v-btn.v-btn--has-bg {
  background-color: #1eafc1;
}

.v-btn {
  background-color: #1eafc1 !important;
  color: white !important;
}

>>> .v-text-field--outlined legend {
  display: none;
}

>>> .v-input--is-focused label,
>>> .v-input--is-dirty label {
  display: none;
}

@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .signup-form {
    width: 90vw !important;
  }
  .v-card--reveal {
    width: 50vw;
  }
  .login-form {
    padding: 8px 0px;
    padding-top: 16px;
  }

  .log-input {
    height: 80px;
  }
  .text-registration {
    font-weight: 600;
    font-size: 26px;
    line-height: 100%;
    color: #252733;
  }
  .form-error {
    font-size: 18px;
  }
  .primary-btn {
    width: 300px;
    font-size: 18px;
  }
  .signup-btn {
    width: 24.5vw;
    font-size: 18px;
  }
  .text-field-signup {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 50vw;
  }

  .profile-icon {
    margin-right: 2vw;
  }
  @media screen and (max-width: 768px) {
    .signup-btn {
      font-size: 16px;
    }
    .primary-btn {
      width: 34vw;
      font-size: 16px;
    }
    .signup-form {
      width: 80vw !important;
    }
    .log-input {
      height: 80px;
    }
    .profile-icon {
      margin-right: 2vw;
      height: 15px;
    }
  }

  @media screen and (max-width: 700px) {
    .primary-btn {
      width: 33vw;
      height: 50px !important;
      font-size: 15px;
    }
    .profile-icon {
      margin-right: 3vw;
      height: 15px;
      margin-top: 3px;
    }
    .signup-btn {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 3800px) {
  .signup-form {
    padding: 80px 40px 40px 60px;
  }

  h2 {
    line-height: 0.1em;
    margin: 20px 0 40px;
  }
  h2 span {
    background: #fff;
    padding: 0 50px;
  }

  .display-1 {
    font-size: 55px !important;
  }
  .p-confirm {
    font-size: 24px;
    line-height: 100%;
    color: #99b4b9;
  }

  .login-form {
    padding: 8px 0px;
    padding-top: 16px;
  }
  .text-registration {
    font-weight: 600;
    font-size: 52px;
    line-height: 100%;
    color: #252733;
  }
  .form-error {
    font-weight: normal;
    font-size: 26px;
    line-height: 200%;
    color: #99b4b9;
  }
  .primary-btn {
    width: 13.3vw;
    height: 100px !important;
    font-size: 24px !important;
    margin-top: 10px;
  }
  .signup-btn {
    width: 8vw;
    height: 90px !important;
    font-size: 24px !important;
  }

  .log-input {
    height: 120px !important;
    width: 200px !important;
  }
  .profile-icon {
    margin-right: 0.2vw;
    height: 40px !important;
    padding: 3px;
    /* margin-bottom: 20px; */
  }
  .text-field-signup {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  h2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  h2 span {
    background: #fff;
    padding: 0 10px;
    color: #000;
    font-size: 0.9em;
    font-weight: 450;
  }

  .v-btn {
    background-color: #1eafc1 !important;
    color: white !important;
  }

  .text-field-confirm {
    width: 16vw !important;
    margin-left: auto;
    margin-right: auto;
  }

  .sign-up-full {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .v-card__text {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  .primary-btn {
    width: 22vw;
    font-size: 16px;
  }
  @media screen and (max-device-width: 1200px) {
    .primary-btn {
      width: 21.5vw;
      font-size: 16px;
    }
  }
  @media screen and (max-device-width: 1024px) {
    .signup-form {
      width: 65vw !important;
      display: flex;
      /* align-items: center; */
    }
    .primary-btn {
      width: 28.5vw;
      font-size: 16px;
    }
    /*  */
    /* .text-field-signup {
      width: 30vw;
    } */
    .signup-btn {
      width: 15vw;
      font-size: 11px;
    }
  }
  @media screen and (max-device-width: 900px) {
    .primary-btn {
      width: 25.5vw;
      font-size: 16px;
      height: 50px !important;
    }
    .signup-btn {
      width: 13vw;
    }
    .profile-icon {
      margin-right: 2vw;
      width: 20px !important;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-device-width: 800px) {
    .primary-btn {
      width: 27vw;
      font-size: 16px;
      height: 50px !important;
      margin-top: 10px;
    }
    .log-input {
      height: 70px !important;
    }
    .text-field-signup {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 30vw;
    }
    .signup-btn {
      width: 14.5vw;
    }
  }
}
@media screen and (max-width: 580px) {
  .signup-form {
    padding: 20px 30px 10px 30px;
    width: 90vw;
    height: 60vh;
    background-color: white;
    border: none;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signup-form-mobile {
    display: flex;
    flex-direction: column;
  }
  .log-input {
    width: 90vw !important;
  }
  .primary-btn {
    width: 75vw;
  }
  .v-card--reveal {
    width: 100vw;
  }
  .profile-icon {
    margin-right: 2vw;
    width: 15px !important;
    /* margin-top: 5px; */
  }
  .text-field-signup {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 80vw;
    margin-right: auto;
    margin-left: auto;
  }
  .v-card__actions {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .signup-btn {
    width: 39vw !important;
  }
  .form-header {
    text-align: center !important;
  }
  .form-error {
    text-align: center !important;
  }
}
</style>
