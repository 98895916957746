import axios from 'axios'
import auth from '@/cognito/aws-auth'
import loader from '@/config.loader'

import store from '@/plugins/vuex';

export default function request (config){
  return new Promise((resolve, reject) => {
    (async ()=> {
      const sign = await auth.signCheck();
      var token = config.token || sign;
      var url = config.fullUrl || (`${loader.getConfigValue('RENDEZVU_API')}${config.url}`)
      const options = {
        url: url,
        method: config.method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': config.type || 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*'
        },
        data: config.body,
      }
      axios(options)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response.status === 401) {
            store.dispatch('auth/logout')
          }
          reject(error)
        })
    })();
  })
}
