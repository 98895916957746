<template>
  <v-card max-width="600" max-height="600" class="justify-center mt-16 mx-auto pa-6" flat>
    <v-card-title primary-title class="justify-center">
      <div class="pa-6">
        <p style="word-break:normal;">
          {{ message }}
        </p>
      </div>
    </v-card-title>
    <v-card-actions class="justify-center pb-10">
      <v-btn large dark color="primary" min-width="180" @click="backOnline">Back Online</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      message: 'Your session has been stopped due to timeout. Get back to online page to continue.',
    };
  },

  methods: {
    backOnline() {
      this.$router.push('/online');
    },
  },
}
</script>


<style scoped>
</style>