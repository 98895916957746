<template>
<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.00025 7.85518C7.91977 7.85518 9.48131 5.99289 9.48131 3.70452C9.48131 1.52315 8.04951 0 6.00025 0C3.95098 0 2.51919 1.52308 2.51919 3.70452C2.51919 5.99286 4.08012 7.85518 6.00025 7.85518ZM6.00025 1.15871C7.38898 1.15871 8.32203 2.18151 8.32203 3.70395C8.32203 5.35321 7.28066 6.69515 6.00025 6.69515C4.71984 6.69515 3.67846 5.35317 3.67846 3.70395C3.67772 2.18235 4.61147 1.15871 6.00025 1.15871ZM6.35578 8.2411H5.64323C2.26744 8.2411 0 10.1918 0 13.0939C0 13.4138 0.259787 13.6736 0.579695 13.6736C0.899603 13.6736 1.15939 13.4145 1.15939 13.0939C1.15939 10.3675 3.57466 9.40038 5.64334 9.40038H6.3559C8.42458 9.40038 10.8406 10.3675 10.8406 13.0939C10.8406 13.4138 11.0997 13.6736 11.4203 13.6736C11.7402 13.6736 12 13.4145 12 13.0939C12 10.1918 9.73237 8.2411 6.35601 8.2411H6.35578Z" fill="#666666"/>
</svg>

</template>
<script>
export default {

}
</script>