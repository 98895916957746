<template>
  <div class="cases-page" id="cases">
    <div class="common-block">
      <div class="about-case-block">
        <div>
          <div class="case-info">
            <div class="use-cases" style="opacity: 0.6">Use case</div>
            <div class="menu-cases">
              <a class="link-width" id="linkOne" href="#">Corporate</a>
              <a class="link-width" id="linkTwo" href="#">Education</a>
              <a class="link-width" id="linkThree" href="#">Events</a>
            </div>
          </div>
          <div class="cases-items">
            <div class="indicate">
              <div class="indicate-second"></div>
              <div class="indicate-second indicate-middle"></div>
              <div class="indicate-main"></div>
            </div>
            <case-events class="case-events" />
          </div>
        </div>
        <div class="logo-user">
          <v-btn to="/online" class="cases-btn" color="primary" exact>
            Join in RendezVu
          </v-btn>
        </div>
      </div>
      <div class="background-case-three"></div>
    </div>
  </div>
</template>

<script>
import CaseEvents from "./CasesBlock/CaseEvents.vue";

export default {
  data() {
    return {};
  },
  components: {
    CaseEvents,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.cases-page {
  display: flex;
  min-width: 100vw;
  height: 100vh;
  background-color: white;
}
.common-block {
  margin-top: 12vh;
  min-width: 100vw;
  width: 55vw;
  // height: 88vh;
  display: flex;
  opacity: 1;
  background: url("../../assets/back-corp.svg"),
    url("../../assets/back-corp-line.svg");
  background-size: 55vw 88vh;
}
.about-case-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 52vw;
  margin-top: 5%;
  opacity: 1;
}
.background-case-three {
  display: block;
  background-image: url("../../assets/Events.jpg");
  width: 48vw;
  height: calc(100vh - 12vh);
  background-size: cover;
  z-index: 1;
}
.menu-cases {
  display: flex;
  transform: translateX(-700px);
}
.link-width {
  letter-spacing: 1px;
  width: 350px;
  min-width: 350px;
  position: relative;
  // margin-right: 57px;
  font-size: 46px;
}
.list-cases {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  div {
    margin-left: 20px;
    font-size: 12px;
  }
  img {
    width: 35px;
  }
}
.case-info {
  margin-bottom: 20px;
  padding-left: 120px;
}

a {
  opacity: 0.2;
  color: black !important;
  text-decoration: none;
  font-size: 40px;
  // margin-right: 50px;
}
.logo-user {
  color: white !important;
  text-transform: uppercase;
  font-size: 0.6rem;
  opacity: 1;
  margin-bottom: 130px;
  padding-left: 120px;
}
#linkThree {
  opacity: 1;
  position: relative;
}
.cases-btn {
  height: 55px !important;
  width: 200px;
  font-size: 13px;
  border-radius: 50px;
  opacity: 1;
  color: white !important;
}
.case-events {
  display: block;
  position: relative;
}

.cases-items {
  // height: 36vh;
  display: flex;
  .indicate {
    padding-right: 30px;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
    .indicate-main {
      background-color: #1eafc1 !important;
      height: 20px;
      width: 3px;
      border-radius: 5px;
    }
    .indicate-second {
      background-color: #1eafc1 !important;
      height: 13px;
      width: 3px;
      border-radius: 5px;
      opacity: 0.2;
    }
    .indicate-middle {
      margin: 8px 0px;
    }
  }
}
.case-corporate {
  --linkOne: 1;
  opacity: var(--linkOne);
}
.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@media screen and (max-width: 1440px) {
  .link-width {
    font-size: 52px;
    // margin-right: 70px;
  }
  .logo-user {
    // margin-top: 189px;
  }
  .menu-cases {
    display: flex;
    // transform: translateX(-684px);
  }
}
@media screen and (min-width: 1500px) {
  .logo-user {
    margin-bottom: 120px;
    // margin-top: 189px;
  }
  .link-width {
    // margin-right: 70px;
    font-size: 56px;
  }
  .menu-cases {
    display: flex;
    // transform: translateX(-724px);
  }
}
@media screen and (min-width: 1600px) {
  .link-width {
    font-size: 58px;
    // margin-right: 70px;
  }
  .logo-user {
    // margin-top: 220px;
    padding-left: 140px;
  }
  .cases-btn {
    height: 60px !important;
    width: 220px;
    font-size: 13px;
    border-radius: 50px;
    opacity: 1;
    color: white !important;
  }
  .case-info {
    padding-left: 140px;
  }
  .cases-items {
    display: flex;
    .indicate {
      width: 140px;
      padding-top: 100px;
      .indicate-main {
        height: 22px;
        width: 4px;
      }
      .indicate-second {
        height: 16px;
        width: 4px;
      }
      .indicate-middle {
        margin: 9px 0px;
      }
    }
  }

  .menu-cases {
    display: flex;
    // transform: translateX(-744px);
  }
}
@media screen and (min-width: 1800px) {
  .link-width {
    letter-spacing: 1px;
    width: 420px;
    min-width: 420px;
    position: relative;
    // margin-right: 70px;
    font-size: 68px;
  }
  .case-info {
    padding-left: 150px;
  }
  .use-cases {
    font-size: 16px;
  }
  .logo-user {
    // margin-top: 232px;
    padding-left: 150px;
  }
  .cases-btn {
    height: 70px !important;
    width: 240px;
    font-size: 15px;
  }
  .cases-items {
    .indicate {
      width: 150px;
      padding-top: 100px;
      .indicate-main {
        height: 30px;
        width: 5px;
      }
      .indicate-second {
        height: 18px;
        width: 5px;
      }
      .indicate-middle {
        margin: 10px 0px;
      }
    }
  }
  .menu-cases {
    display: flex;
    transform: translateX(-840px);
  }
}
@media screen and (min-width: 1920px) {
  .about-case-block {
    width: 52vw;
    margin-top: 5%;
  }
  .background-case-one {
    width: 48vw;
  }
  .link-width {
    // margin-right: 70px;
    font-size: 60px;
  }
  .case-info {
    padding-left: 230px;
  }
  .use-cases {
    font-size: 17px;
  }
  .logo-user {
    margin-bottom: 100px;
    // margin-top: 242px;
    padding-left: 230px;
  }
  .cases-btn {
    height: 80px !important;
    width: 260px;
    font-size: 16px;
  }
  .cases-items {
    .indicate {
      width: 230px;
      padding-right: 120px;
      padding-top: 100px;
      .indicate-main {
        height: 33px;
        width: 6px;
      }
      .indicate-second {
        height: 21px;
        width: 6px;
      }
      .indicate-middle {
        margin: 12px 0px;
      }
    }
  }

  .menu-cases {
    display: flex;
    // transform: translateX(-766px);
  }
}
@media screen and (min-width: 2100px) {
  .link-width {
    letter-spacing: 1px;
    width: 500px;
    min-width: 500px;
    position: relative;
    // margin-right: 84px;
    font-size: 82px;
  }
  .logo-user {
    margin-bottom: 150px;
    // margin-top: 20px;
  }
  .menu-cases {
    display: flex;
    transform: translateX(-1000px);
  }
}
@media screen and (min-width: 2400px) {
  .logo-user {
    margin-bottom: 230px;
  }
  .link-width {
    letter-spacing: 1px;
    width: 550px;
    min-width: 550px;
    position: relative;
    // margin-right: 84px;
    font-size: 82px;
  }
  .menu-cases {
    display: flex;
    transform: translateX(-1100px);
  }
}
@media screen and (min-width: 2800px) {
  .about-case-block {
    margin-top: 120px;
  }
  .link-width {
    // margin-right: 160px;
    width: 700px;
    min-width: 700px;
    font-size: 102px;
  }
  .case-info {
    padding-left: 250px;
  }
  .use-cases {
    font-size: 22px;
  }
  .logo-user {
    margin-bottom: 170px;
    padding-left: 250px;
  }
  .cases-btn {
    height: 110px !important;
    width: 320px;
    font-size: 20px;
    border-radius: 100px;
  }
  .cases-items {
    .indicate {
      width: 250px;
      padding-top: 200px;
      .indicate-main {
        height: 60px;
        width: 8px;
      }
      .indicate-second {
        height: 40px;
        width: 8px;
      }
      .indicate-middle {
        margin: 15px 0px;
      }
    }
  }
  .menu-cases {
    display: flex;
    transform: translateX(-1400px);
  }
}
@media screen and (min-width: 3800px) {
  .about-case-block {
    margin-top: 150px;
  }
  .link-width {
    width: 1000px;
    min-width: 1000px;
    // margin-right: 257px;
    font-size: 132px;
  }
  .case-info {
    padding-left: 290px;
  }
  .use-cases {
    font-size: 24px;
  }
  .logo-user {
    margin-top: 510px;
    padding-left: 290px;
  }
  .cases-btn {
    height: 130px !important;
    width: 400px;
    font-size: 25px;
    border-radius: 100px;
  }
  .cases-items {
    .indicate {
      width: 290px;
      padding-top: 200px;
      .indicate-main {
        height: 80px;
        width: 8px;
      }
      .indicate-second {
        height: 45px;
        width: 8px;
      }
      .indicate-middle {
        margin: 25px 0px;
      }
    }
  }
  .menu-cases {
    display: flex;
    transform: translateX(-2000px);
  }
}

@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  #linkOne {
    display: none;
  }
  #linkTwo {
    display: none;
  }
  .common-block {
    margin-top: 12vh;
    min-width: 100vw;
    width: 100vw;
    height: 88vh;
    flex-direction: column;
    background: url("../../assets/back-corp-tablet.svg"),
      url("../../assets/back-corp-line.svg");
    background-size: 100vw 88vh;
  }
  .about-case-block {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    width: 100vw;
    height: 35vh;
    margin-top: 0px;
    padding-top: 20px;
    opacity: 1;
    @media screen and (max-width: 834px) {
      padding-top: 10px;
    }
    @media screen and (max-width: 810px) {
      padding-top: 7px;
    }

    @media screen and (max-height: 900px) {
      height: 33vh;
    }
  }
  .use-cases {
    font-size: 20px;
    @media screen and (max-width: 834px) {
      font-size: 18px;
    }
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  .cases-items {
    .indicate {
      display: none;
    }
  }
  .background-case-three {
    background-image: url("../../assets/Events-tablet.jpg");
    width: 100vw;
    height: 53vh;
    display: block;
    background-size: cover;
    @media screen and (max-height: 900px) {
      height: 55vh;
    }
  }
  .link-width {
    letter-spacing: 1px;
    width: 350px;
    position: relative;
    // margin-right: 50px;
    font-size: 62px;
    margin-bottom: 10px;
    @media screen and (max-width: 834px) {
      font-size: 56px;
    }
    @media screen and (max-width: 810px) {
      font-size: 48px;
    }
    @media screen and (max-width: 768px) {
      font-size: 46px;
    }
    @media screen and (max-width: 700px) {
      font-size: 42px;
      margin-bottom: 0px;
    }
  }
  .list-cases {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    div {
      margin-left: 20px;
      text-align: end;
      font-size: 22px;
    }
    img {
      width: 45px;
    }
  }
  .case-info {
    margin-bottom: 20px;
    padding-left: 0px;
    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  a {
    opacity: 0.2;
    color: black !important;
    text-decoration: none;
    font-size: 49px;
    margin-right: 50px;
  }
  .logo-user {
    display: none;
  }

  .case-corporate {
    display: block;
    position: absolute;
  }

  .cases-items {
    height: 50vh;
  }
  .menu-cases {
    display: flex;
    transform: translateX(-0px);
  }
}
@media screen and (max-width: 1366px) and (orientation: landscape) {
  .about-case-block {
    // margin-top: 4%;
    width: 46vw;
    @media screen and (max-width: 1200px) {
      width: 47vw;
       margin-top: 4%;
    }
    @media screen and (max-width: 1100px) {
      margin-top: 2%;
    }
    @media screen and (max-height: 580px) {
      margin-top: 2%;
    }
  }
  .background-case-three {
    width: 54vw;
    @media screen and (max-width: 1200px) {
      width: 53vw;
    }
  }
  .case-info {
    padding-left: 95px;
     @media screen and (max-width: 1100px) {
       margin-bottom: 10px;
      padding-left: 75px;
    }
    @media screen and (max-width: 1024px) {
      padding-left: 65px;
    }
    @media screen and (max-width: 950px) {
      padding-left: 60px;
      margin-top: 20px;
    }
    @media screen and (max-height: 580px) {
      margin-bottom: 20px;
      @media screen and (max-height: 400px) {
        padding-left: 45px;
      }
    }
  }
  .use-cases {
     font-size: 12px;
    @media screen and (max-width: 800px) {
      font-size: 9px;
    }
    @media screen and (max-height: 580px) {
      font-size: 10px;
    }
  }
  .logo-user {
    margin-bottom: 110px;
    padding-left: 95px;
    @media screen and (max-width: 1200px) {
      // padding-left: 75px;
      margin-bottom: 160px;
    }
    @media screen and (max-width: 1100px) {
      margin-bottom: 160px;
      padding-left: 75px;
    }
    @media screen and (max-width: 1024px) {
      // margin-top: 30px;
      padding-left: 65px;
      margin-bottom: 150px;
    }
    @media screen and (max-width: 950px) {
      padding-left: 60px;
      margin-bottom: 120px;
    }
    @media screen and (max-width: 800px) {
      // margin-top: 20px;
      margin-bottom: 140px;
    }
    @media screen and (max-height: 580px) {
      margin-bottom: 50px;
      @media screen and (max-width: 740px) {
        padding-left: 45px;
      }
    }
  }
  .cases-items {
    .indicate {
      width: 95px;
      // @media screen and (max-device-width: 1200px) {
      //   width: 75px;
      // }
      @media screen and (max-width: 1100px) {
       width: 75px;
      }
      @media screen and (max-width: 1024px) {
        width: 65px;
        padding-right: 0px;
      }
      @media screen and (max-width: 950px) {
        width: 60px;
      }
      @media screen and (max-height: 580px) {
        padding-top: 50px;
        @media screen and (max-height: 400px) {
          width: 45px;
        }
      }
      .indicate-main {
        height: 25px;
        width: 3px;
        @media screen and (max-width: 950px) {
          height: 20px;
        }
      }
      .indicate-second {
        height: 15px;
        width: 3px;
        @media screen and (max-width: 950px) {
          height: 13px;
        }
      }
      .indicate-middle {
        margin: 8px 0px;
      }
    }
  }
  .link-width {
    // margin-right: 80px;
    width: 300px;
    min-width: 300px;
     font-size: 38px;
    // @media screen and (max-device-width: 1200px) {
    //   // margin-right: 80px;
    //   font-size: 52px;
    //    width: 300px;
    // min-width:300px;
    // }
    // @media screen and (max-device-width: 1024px) {
    //   font-size: 40px;

    // }
    @media screen and (max-width: 1200px) {
      width: 250px;
      min-width: 250px;
      font-size: 32px;
    }

    @media screen and (max-width: 1100px) {
     width: 235px;
    min-width:235px;
    font-size: 32px;
    }
    @media screen and (max-device-width: 950px) {
      // font-size: 32px;
      // margin-right: 70px;
    }
    @media screen and (max-width: 800px) {
      font-size: 25px;
      width: 170px;
      min-width: 170px;
      // margin-right: 37px;
    }
    @media screen and (max-height: 580px) {
      font-size: 25px;
      width: 200px;
      min-width: 200px;

      @media screen and (max-height: 400px) {
        width: 150px;
        min-width: 150px;
        font-size: 22px;
      }
    }
  }

  .cases-btn {
    @media screen and (max-width: 1200px) {
      height: 50px !important;
      width: 150px;
      font-size: 11px !important;
    }
    @media screen and (max-width: 950px) {
      height: 40px !important;
      width: 140px;
      font-size: 10px !important;
    }
    @media screen and (max-height: 580px) {
      height: 30px !important;
      width: 100px;
      font-size: 7px !important;
    }
  }
  .menu-cases {
    display: flex;
    transform: translateX(-600px);
    @media screen and (max-width: 1200px) {
      transform: translateX(-500px);
    }
    
    @media screen and (max-width: 1100px) {
      transform: translateX(-470px);
    }
    @media screen and (max-width: 950px) {
      // transform: translateX(-402px);
    }
    @media screen and (max-width: 800px) {
      transform: translateX(-340px);
    }
     @media screen and (max-height: 580px) {
      transform: translateX(-400px);
      @media screen and (max-height: 400px) {
        transform: translateX(-300px);
          }

    }
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  #linkOne {
    display: none;
  }
  #linkTwo {
    display: none;
  }
  .cases-items {
    .indicate {
      display: none;
    }
  }
  .logo-user {
    display: none;
  }
  .use-cases {
    display: none;
  }
  .menu-cases {
    display: flex;
    transform: translateX(-0px);
  }
  #linkThree {
    opacity: 1;
    display: block;
    position: relative;
  }
  .cases-page {
    display: flex;
    min-width: 100%;
    height: 100%;
    background-color: white;
  }
  .common-block {
    margin-top: 70px;
    min-width: 100vw;
    height: calc(100vh -70px);
    display: flex;
    flex-direction: column;
    opacity: 1;
    background: url("../../assets/corp-back-mobile.svg");
    background-size: cover;
    // radial-gradient(
    //   circle at left,
    //   rgba(148, 203, 245, 0.2) 0%,
    //   rgba(148, 203, 245, 0.2) 10%,
    //   rgba(148, 203, 245, 0.3) 30%,
    //   rgba(238, 174, 202, 0.2) 50%,
    //   rgba(238, 174, 202, 0.3) 60%,
    //   rgba(148, 203, 245, 0.2) 90%,
    //   rgba(148, 203, 245, 0.3) 100%
    // );
  }
  .about-case-block {
    display: flex;
    width: 100vw;
    flex-direction: column;
    opacity: 1;
    height: 43vh;
    padding-left: 20px;
    padding-right: 30px;
  }
  .about-case {
    width: 100vw;
    //  margin-top: 70px;
  }
  .link-width {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    // margin-right: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .case-info {
    padding-left: 0px;
  }
  .corporate-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    height: 50vh;
    padding-bottom: 10px;

    h1 {
      margin: 40px 0 35px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 100%;
    }
  }
  .menu-cases {
    display: flex;
  }

  .background-case-three {
    background-image: url("../../assets/case-events-mobile.jpg");
    background-size: cover;
    width: 100%;
    height: 57vh;
  }

  .slide-out-blurred-top {
    -webkit-animation: slide-out-blurred-top 0.45s
      cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
    animation: slide-out-blurred-top 0.45s
      cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  }

  @-webkit-keyframes slide-out-blurred-top {
    0% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }
  }
  @keyframes slide-out-blurred-top {
    0% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      transform: translateY(-1000px) scaleY(2) scaleX(0.2);
      -webkit-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }
  }
  @media screen and (max-width: 420px) {
    .about-case-block {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      height: 39vh;
      h1 {
        margin: 35px 0 30px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 100%;
      }
    }
    .background-case-three {
      height: 51vh;
    }
    .link-width {
      margin-bottom: 5px;
      margin-top: 15px;
    }
  }
  @media screen and (max-width: 375px) {
    .about-case-block {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 38vh;
      h1 {
        margin: 30px 0 25px 0;
        font-size: 30px;
      }
    }
    .background-case-three {
      height: 51vh;
    }
    .link-width {
      margin-bottom: 5px;
      margin-top: 15px;
    }
  }
  @media screen and (max-height: 760px) {
    .about-case-block {
      margin-top: 2%;
      height: 37vh;
      h1 {
        margin: 30px 0 25px 0;
        font-size: 30px;
      }
    }
    .background-case-three {
      height: 53vh;
    }
    .link-width {
      margin-bottom: 10px;
      margin-top: 10px;
      font-size: 34px;
    }
  }
  @media screen and (max-height: 670px) {
    .about-case-block {
      margin-top: 2%;
      height: 32vh;
      h1 {
        margin: 30px 0 25px 0;
        font-size: 30px;
      }
    }
    .background-case-three {
      height: 58vh;
    }
    .link-width {
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 32px;
      font-weight: 400;
    }
  }

  @media screen and (max-height: 600px) {
    .about-case-block {
      height: 55vh;
      h1 {
        margin: 15px 0 15px 0;
        font-size: 22px;
      }
    }

    .background-case-three {
      height: 33vh;
    }
  }
  @media screen and (max-height: 570px) {
    .about-case-block {
      height: 30vh;
      h1 {
        margin: 10px 0 10px 0;
        font-size: 16px;
      }
    }

    .background-case-three {
      height: 57vh;
    }
    .link-width {
      margin-bottom: 0px;
      margin-top: 5px;
      font-size: 22px;
    }
    .case-info {
      margin-bottom: 10px;
    }
  }
}
</style>
