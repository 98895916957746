<template>
<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 0C0.223967 0 0 0.223967 0 0.5V9.5C0 9.77603 0.223967 10 0.5 10H13.5C13.776 10 14 9.77603 14 9.5V0.5C14 0.223967 13.776 0 13.5 0H0.5ZM1.8698 1H12.1301L6.99997 5.34367L1.8698 1ZM1 1.57813L6.677 6.38013C6.8632 6.53768 7.13663 6.53768 7.32283 6.38013L12.9998 1.57813V8.99997H0.999833L1 1.57813Z" fill="#666666"/>
</svg>


</template>
<script>
export default {

}
</script>