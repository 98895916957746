<template>
  <div class="restore-pwd-card">
    <img class="icon-logo" src="../../assets/icon-logo-navbar.svg" alt="">
    <v-card-text class="restore-pass-text" v-if="!reveal">
      <div>
        <v-col class="log-input log-google">
          <v-btn block> log in with google </v-btn>
        </v-col>

        <div class="form-header">
          <span class="forget-pass">Forget Password?</span>
        </div>
        <div class="form-error">{{ notification }}</div>
        <v-col class="log-input">
          <v-text-field
            @input="emailInput"
            label="Enter email"
            v-model="form.username"
            outlined
            :rules="emailRules"
            hide-details="auto"
            height="60px"
            @keyup.enter="confirmRestore"
            ><template v-slot:prepend-inner>
              <img
                class="profile-icon"
                height="20px"
                src="../../assets/icon-email-feedback.svg"
                alt=""
              />
            </template>
          </v-text-field>
        </v-col>
      </div>
    </v-card-text>
    <v-card-actions v-if="!reveal">
      <v-btn
        @click="confirmRestore"
        color="primary"
        class="confirm-restore-btn"
        :disabled="restoreBtnDisabled"
        :loading="overlay"
      >
        Restore
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-card
        v-if="reveal"
        class="
          transition-fast-in-fast-out
          align-self-center
          restore-confirm-pass
        "
        elevation="0"
      >
        <div class="pb-0">
          <p class="display-1">Restore password</p>
          <p class="message-recover">
            Please, enter the received code from {{ restoreEmail }}
          </p>
          <div>
            <v-text-field
              label="confirmation code"
              v-model="form.authCode"
              hide-details="auto"
              outlined
              class="text-field-recover-pass"
              height="60px"
            ></v-text-field>
            <v-text-field
              label="enter new password"
              class="text-field-recover-pass"
              :type="hidePassword ? 'password' : 'text'"
              v-model="form.newPassword"
              :rules="newPasswordRules"
              outlined
              hide-details="auto"
              height="60px"
              :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="hidePassword = !hidePassword"
              @keyup.enter="confirmPassword"
            ></v-text-field>
          </div>
        </div>
        <v-card-actions>
          <v-btn
            class="restore-pass-btn"
            color="primary"
            :loading="overlay"
            :disabled="overlay"
            @click="confirmPassword"
          >
            Restore
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
    <!--<v-overlay :absolute="absolute" :value="overlay"></v-overlay>-->
    <br />
    <br />
    <v-progress-linear v-if="overlay" indeterminate color="teal accent-4">
    </v-progress-linear>
  </div>
</template>

<script>
//import { Auth } from 'aws-amplify'
import awsAuth from "@/cognito/aws-auth";
import { mapActions } from "vuex";
export default {
  name: "restore",
  props: ["toggle"],
  data() {
    return {
      hidePassword: true,
      formState: "restore",
      reveal: false,
      absolute: true,
      overlay: false,
      multiLine: true,
      notification: "Enter email and recover password",
      restoreEmail: "",
      emailRules: [
        (value) => !!value || "Required",
        (value) =>
          (value &&
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              value
            )) ||
          "Please enter a valid email",
      ],
      rules: [
        (value) => !!value || "Required",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      emailConfirmationRules: [
        (v) =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must match",
        (v) => !!v || "Confirmation E-mail is required",
      ],
      newPasswordRules: [
        (value) => !!value || "Required",
        (value) => (value && value.length >= 6) || "Min 6 characters",
      ],
      form: {
        username: "",
      },
      restoreBtnDisabled: true,
    };
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    async signIn(username, password) {
      const self = this;
      self.overlay = true;
      self.$store
        .dispatch("auth/login", { username, password })
        .catch((error) => {
          self.overlay = false;
          self.notification = `ERROR! ${error.message}`;
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: error.message,
          });
        });
    },
    async confirmRestore() {
      const { username } = this.form;
      console.log(username);
      let self = this;
      this.form.authCode = "";
      self.overlay = true;
      awsAuth.preSignUp(username).then((result) => {
        if (!result.data.isExist) {
          self.notification = `This email is not registered yet`;
          self.overlay = false;
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: self.notification,
          });

          return;
        } else if (result.data.isExist && result.data.status === true) {
          self.notification =
            "Sorry, you have not verified your account and it has been disabled. To get a new invitation link - contact the administrators.";
          self.overlay = false;
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: self.notification,
          });

          return;
        }
        awsAuth
          .restorePassword(username)
          .then((result) => {
            self.restoreEmail = result.data;
            self.reveal = true;
            self.overlay = false;
            self.formState = "confirmRestore";
            self.notification = `Please, enter code and set new password `;
            self.snackbar({
              color: "success",
              icon: "mdi-check-circle",
              title: "Success",
              text: self.notification,
            });
          })
          .catch((error) => {
            self.overlay = false;
            self.notification = `ERROR! ${error.message}`;
            self.snackbar({
              color: "error",
              icon: "mdi-alert-circle-outline",
              title: "Error",
              text: error.message,
            });
          });
      });
    },
    async confirmPassword() {
      const { username, authCode, newPassword } = this.form;
      let self = this;
      self.overlay = true;
      awsAuth
        .confirmPassword(username, authCode, newPassword)
        .then(() => {
          self.notification = "Successfully! Password restored.";
          self.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: self.notification,
          });
          self.signIn(username, newPassword).then(() => {
            setTimeout(self.redirect, 2300);
          });
        })
        .catch((error) => {
          self.overlay = false;
          self.notification = `ERROR! ${error.message}`;
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: error.message,
          });
        });
    },
    emailInput(value) {
      this.restoreBtnDisabled =
        !/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        ) || this.overlay;
    },
    redirect() {
      this.overlay = false;
      // this.toggle("signIn");
      this.$router.push("/profile");

      //this.$router.push(this.$route.query.redirect || "/auth")
    },
  },
};
</script>

<style scoped>
.restore-pwd-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  width: 35vw;
  padding-bottom: 30px;
  border: 1px solid #e9eeef;
  border-radius: 4px;
}
.restore-pass-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.restore-btn {
  height: 60px !important;
  width: 25vw;
  margin: 20px 0px;
}
.form-error {
  font-size: 15px;
  line-height: 100%;
  opacity: 0.6;
}
.forget-pass {
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  color: #252733;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  height: 100%;
  width: 100%;
}
.icon-logo {
  margin-top: 20px;
  width: 65px;
}
.profile-icon {
  margin-right: 0.5vw;
}
h2 {
  width: 100%;
  text-align: center;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
h2 span {
  padding: 0 10px;
}
.log-google {
  display: none;
}
.login-form {
  padding: 8px;
  padding-top: 16px;
}
.log-input {
  width: 315px;
  height: 90px;
  margin-right: auto;
  margin-left: auto;
}
h2 {
  width: 100%;
  text-align: center;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
h2 span {
  background: #fff;
  padding: 0 10px;
  color: #000;
  font-size: 0.9em;
  font-weight: 450;
}
.text-field-recover-pass {
  width: 20vw;
  margin-top: 25px;
}

.log-google {
  display: none;
}
.primary {
  font-weight: 600;
  font-size: 14px;
}
.confirm-restore-btn {
  height: 60px !important;
  width: 300px;
}
.restore-pass-btn {
  margin-top: 10px;
  height: 60px !important;
  width: 20vw;
}
.restore-confirm-pass {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  /* margin-left: 25%;
  margin-right: 25%; */
}

.display-1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  color: #252733;
}

.v-text-field--outlined.v-input--dense .v-label {
  top: 20px;
}

>>> .v-label {
  top: 50% !important;
  transform: translateY(-50%);
}

>>> .v-icon__component {
  height: 20px;
  width: 20px;
}

>>> .v-text-field--outlined legend {
  display: none;
}

>>> .v-input--is-focused label,
>>> .v-input--is-dirty label {
  display: none;
}
.v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar) {
  display: flex;
  flex-direction: column;
  align-items: center !important;
}


@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  @media screen and (max-device-width: 1024px) {
    .restore-pwd-card {
      width: 45vw;
      height: 40vw;
    }
    .icon-logo-pass {
      width: 59px;
      height: 50px;
    }
    .restore-pass-btn {
      height: 60px !important;
      width: 20vw;
      font-size: 12px;
      text-transform: none;
    }
    .text-field-recover-pass {
      margin-top: 15px;
    }
    .display-1 {
      font-size: 18px !important;
      font-weight: 600;
    }
    .message-recover {
      font-size: 12px;
    }
    .confirm-restore-btn {
      height: 60px !important;
      width: 26vw;
      font-size: 12px;
      text-transform: none;
    }
    .log-input {
      min-height: 26px !important;

      width: 28vw;
      margin-right: auto;
      margin-left: auto;
    }
    .forget-pass {
      font-size: 18px;
    }
    .form-error {
      font-size: 12px;
      margin-bottom: 15px !important;
    }
  }
  .v-application p {
    margin-bottom: 10px;
  }
  .restore-confirm-pass {
    padding-top: 20px;
  }
  @media screen and (max-width: 834px) {
    .restore-pwd-card {
      width: 58vw;
    }
    .log-input {
      width: 44.5vw;
    }
    .confirm-restore-btn {
      height: 70px !important;
      width: 41.5vw;
    }
    .form-error {
      font-size: 20px;
    }
    .forget-pass {
      font-weight: 600;
      font-size: 32px;
    }
  }
  @media screen and (max-width: 800px) {
    .restore-pwd-card {
      width: 45vw;
      padding-bottom: 10px;
    }
    .icon-logo-pass {
      width: 59px;
      height: 50px;
      margin-top: 10px;
    }
    .restore-pass-btn {
      height: 45px !important;
      width: 25vw;
      font-size: 12px;
      text-transform: none;
    }
    .text-field-recover-pass {
      margin-top: 15px;
    }
    .display-1 {
      font-size: 18px !important;
      font-weight: 600;
    }
    .message-recover {
      font-size: 12px;
    }
    .confirm-restore-btn {
      height: 50px !important;
      width: 33vw;
      font-size: 12px;
      text-transform: none;
      margin-top: 25px;
    }
    .log-input {
      min-height: 50px !important;
      max-height: 26px !important;
      width: 36vw;
      margin-right: auto;
      margin-left: auto;
    }
    .forget-pass {
      font-size: 18px;
    }
    .form-error {
      font-size: 12px;
      margin-bottom: 15px !important;
    }
    .profile-icon {
      margin-right: 2vw;
    }
    .v-application p {
      margin-bottom: 10px;
    }
  
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .restore-pwd-card {
    width: 60vw;
  }
  .icon-logo-pass {
    width: 59px;
    height: 50px;
  }
  .restore-pass-btn {
    height: 60px !important;
    width: 28vw;
    font-size: 12px;
    text-transform: none;
  }
  .text-field-recover-pass {
    margin-top: 15px;
    width: 28vw;
  }
  .display-1 {
    font-size: 24px !important;
  }
  .message-recover {
    font-size: 14px;
  }
  .confirm-restore-btn {
    height: 60px !important;
    width: 31vw;
    font-size: 12px;
    text-transform: none;
  }
  .log-input {
    width: 33vw;
  }

  .form-error {
    font-size: 16px;
    margin-bottom: 15px !important;
  }
  .profile-icon {
      margin-right: 2vw;
      margin-top: 2px;
    }
  @media screen and (max-width: 834px) {
    .confirm-restore-btn {
      width: 33vw;
    }
    .log-input {
      width: 36vw;
    }
    .profile-icon {
      margin-right: 3vw;
      margin-top: 2px;
    }
  }
  @media screen and (max-width: 768px) {
    .confirm-restore-btn {
      width: 33vw;
      height: 50px !important;
      margin-top: 25px;
    }
    .log-input {
      width: 35.5vw;
      height: 50px !important;
    }
    .profile-icon {
      margin-right: 2vw;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 700px) {
    .confirm-restore-btn {
      width: 33vw;
      height: 50px !important;
    }
    .log-input {
      width: 36.5vw;
      height: 50px !important;
      margin-right: auto;
      margin-left: auto;
    }
  }
   @media screen and (max-width: 600px) {
.profile-icon {
      margin-right: 2vw;
      margin-top: 3px;
      padding: 5px;
    }
      .icon-logo {
      width: 50px !important;
    }
   }
}
@media screen and (min-width: 3800px) {
  .restore-pwd-card {
    width: 25vw;
    padding: 70px 0 70px 0;
  }
  .restore-btn {
    height: 80px !important;
    width: 30vw;
    margin: 20px 0px;
  }
  .form-error {
    font-size: 26px;
    line-height: 200%;
    opacity: 0.6;
  }
  .forget-pass {
    font-weight: 600;
    font-size: 44px;
    line-height: 100%;
    text-align: center;
    color: #252733;
  }
  .icon-loro-pass {
    width: 125px !important;
    margin-bottom: 30px;
  }
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .profile-icon {
    margin-right: 1vw;
    height: 30px !important;
    margin-bottom: 25px;
  }
  h2 {
    width: 100%;
    text-align: center;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  h2 span {
    padding: 0 10px;
  }
  .login-form {
    padding: 8px;
    padding-top: 16px;
  }
  .log-input {
    width: 13vw;
    height: 90px;
  }
  h2 {
    width: 100%;
    text-align: center;
    font-size: 44px;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  h2 span {
    background: #fff;
    padding: 0 10px;
    color: #000;
    font-size: 22px !important;
    font-weight: 450;
  }
  .text-field-recover-pass {
    width: 15vw;
    margin-top: 25px;
  }
  .email-input {
    width: 40vw !important;
  }
  .primary {
    font-weight: 600;
    font-size: 14px;
  }
  .confirm-restore-btn {
    height: 90px !important;
    width: 12.5vw;
    margin-top: 20px;
    font-size: 24px;
  }
  .restore-pass-btn {
    margin-top: 40px;
    height: 90px !important;
    width: 15vw;
    font-size: 24px;
  }
  .restore-confirm-pass {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;

    width: 20vw !important;
  }

  .display-1 {
    font-size: 60px !important;
  }
  .message-recover {
    font-size: 22px;
  }
  .v-text-field--outlined.v-input--dense .v-label {
    top: 30px;
  }

  >>> .v-icon__component {
    height: 20px;
    width: 20px;
  }
}
@media screen and (max-width: 580px) {
  .icon-logo {
    display: none;
  }
  .restore-pwd-card {
    width: 90vw;
    border: none;
    /* margin-top: 50px; */
  }

  .text-field-recover-pass {
    width: 85vw;
    margin-top: 25px;
  }
  .log-input {
    width: 90vw !important;
    height: 80px;
  }
  .restore-btn {
    height: 60px !important;
    width: 25vw;
    margin: 20px 0px;
  }
  .restore-pass-btn {
    margin-top: 10px;
    height: 60px !important;
    width: 85vw;
  }
  .restore-pass-text {
    margin-top: 50px;
  }
  .confirm-restore-btn {
    height: 60px !important;
    width: 83vw;
  }
  .restore-confirm-pass {
    padding-top: 100px;
  }
  .profile-icon {
    height: 15px;
    margin-top: 5px;
    margin-right: 2vw;
  }
  .icon-logo-pass {
    display: none;
  }
}
</style>
<style>
/* .v-text-field--full-width .v-input__prepend-outer, */
/* .v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 19px;
} */
@media screen and (min-width: 3800px) {
  
  .v-text-field--full-width .v-input__prepend-outer,
  .v-text-field--full-width .v-input__prepend-inner,
  .v-text-field--full-width .v-input__append-inner,
  .v-text-field--full-width .v-input__append-outer,
  .v-text-field--enclosed .v-input__prepend-outer,
  .v-text-field--enclosed .v-input__prepend-inner,
  .v-text-field--enclosed .v-input__append-inner,
  .v-text-field--enclosed .v-input__append-outer {
    margin-top: 28px;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed {
    height: 100px !important;
    margin-bottom: 30px;
  }
}
/* @media screen and (max-device-width: 900px) and (orientation: landscape) { */
  /* .v-text-field--outlined > .v-input__control > .v-input__slot {
    align-items: stretch;
    min-height: 36px !important;
    max-height: 36px !important;
  } */
  /* .v-label {
    font-size: 12px;
  }

  .v-input__icon {
    height: 14px;
    min-width: 10px;
    width: 10px;
  }
  .v-icon.v-icon {
    font-size: 14px;
  } */

  /* .v-text-field--full-width .v-input__prepend-outer,
  .v-text-field--full-width .v-input__prepend-inner,
  .v-text-field--full-width .v-input__append-inner,
  .v-text-field--full-width .v-input__append-outer,
  .v-text-field--enclosed .v-input__prepend-outer,
  .v-text-field--enclosed .v-input__prepend-inner,
  .v-text-field--enclosed .v-input__append-inner,
  .v-text-field--enclosed .v-input__append-outer {
    margin-top: 8px;
  } */
/* } */
/* @media screen and (max-device-width: 834px) and (orientation: portrait) { */
/* .v-label {
    font-size: 18px;
  }
} */
/* @media screen and (max-device-width: 800px) and (orientation: portrait) { */
  /* .v-label {
    font-size: 12px;
  } */
  /* .v-text-field--full-width .v-input__prepend-outer,
  .v-text-field--full-width .v-input__prepend-inner,
  .v-text-field--full-width .v-input__append-inner,
  .v-text-field--full-width .v-input__append-outer,
  .v-text-field--enclosed .v-input__prepend-outer,
  .v-text-field--enclosed .v-input__prepend-inner,
  .v-text-field--enclosed .v-input__append-inner,
  .v-text-field--enclosed .v-input__append-outer {
    margin-top: 9px;
  } */
  /* .v-input__icon {
    height: 14px;
    min-width: 10px;
    width: 10px;
  } */
  /* .v-icon.v-icon {
    font-size: 14px;
  } */
/* } */

/* @media screen and (max-device-width: 768px) and (orientation: portrait) { */
/* .v-text-field--outlined > .v-input__control > .v-input__slot {
    align-items: stretch;
    min-height: 40px !important;
    max-height: 66px !important;
  } */
/* .v-label {
    font-size: 18px;
  }
  .v-icon.v-icon {
    font-size: 14px;
    /* margin-top: 15px; */
  /* } */ 
/* } */

</style>
