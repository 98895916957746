import Vue from 'vue';
import Vuex from 'vuex';

import SignalingModule from '@/store/signaling';
import AuthModule from '@/store/auth';
import UIModule from '@/store/ui';
import SocketModule from '@/store/socket';
import overrideLog from '@/store/override-log'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: {
    logs: [''],
    logging: true,
    originalConsoleLog: console.log,
    originalConsoleError: console.error
  },

  mutations: {
    updateSnackbar: (state, value) => state.ui.snackbar = Object.assign({}, state.ui.snackbar, { visible: value }),
    appendLog: (state, log) => state.logs.push(log),
    enableLogging(state) {
      overrideLog(this);
      state.logging = true;
    },
  },
  actions: {
    appendLog: ({ commit }, opts) => {
      commit("appendLog", opts.log);
    },
    toggleLogging: ({ commit }) => {
      commit("enableLogging");
    },
  },
  modules: {
    signaling: SignalingModule,
    auth: AuthModule,
    ui: UIModule,
    socket: SocketModule,
    logger: overrideLog
  },
  strict: debug
});
