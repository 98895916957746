<template>
  <div class="feature-block" id="feature">
    <div class="background-image">
      <div class="text-feature">
        <h1>Coming soon</h1>
        <h3 class="text-h3-small">
          Our team is working to improve the product every day <br />
          and we are preparing a lot of interesting things for you.
        </h3>
        <h3 class="text-h3-mobile">
          Our team is working to improve<br />
          the product every day and we are preparing<br />
          a lot of interesting things for you.
        </h3>
        <img
          class="cursor-mobile rotate-center"
          src="../../assets/icon-cursor.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  data() {
    return {};
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
  },
};
</script>

<style lang="scss" scoped>
.feature-block {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  opacity: 1;
  background-color: white;
}
.background-image {
  height: 100vh;
  width: 100vw;
  position: absolute;
  opacity: 1;
  background-image: linear-gradient(
      rgba(33, 28, 28, 0.8) 20%,
      rgba(33, 28, 28, 0) 80%
    ),
    url("../../assets/Coming-soon.jpg");
  background-size: cover;
  z-index: 1;
}

.text-feature {
  h1 {
    font-weight: 500;
    font-size: 48px;
  }
  .text-h3-mobile {
    display: none;
  }
  .text-h3-small {
    display: block;
    font-weight: 500;
    font-size: 14px;
  }
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 19vh;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}
.text-change {
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 100%;
    padding-top: 10px;
  }
  div {
    opacity: 0.8;
    font-size: 12px;
    letter-spacing: 0.1em;
    line-height: 1;
  }
  h3 {
    font-size: 13px;
    font-weight: 500;
    padding-top: 3vh;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
  }
  text-align: center;
  opacity: 0;
  animation: new 2s forwards;
  animation-delay: 4s;
  @keyframes new {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.cursor-mobile {
  display: none;
}

@media screen and (min-width: 581px) and (max-width: 1025px) and (orientation: portrait) {
  .background-image {
    background-image: linear-gradient(
        rgba(33, 28, 28, 0.8) 0%,
        rgba(33, 28, 28, 0) 0%
      ),
      url("../../assets/Coming-soon-tablet.jpg");
  }
  .text-feature {
    h1 {
      font-weight: 500;
      font-size: 78px;
    }
    .text-h3-mobile {
      display: none;
    }
    .text-h3-small {
      font-size: 23px;
      font-weight: 400;
      line-height: 155%;
      display: block;
    }
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 18vh;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
  }
  .cursor-mobile {
    display: block;
    margin-top: 65%;
  }

  @media screen and (max-width: 700px) {
    .text-feature {
      h1 {
        font-weight: 400;
        font-size: 60px;
      }
      .text-h3-mobile {
        display: none;
      }
      .text-h3-small {
        font-size: 18px;
        display: block;
      }
      margin-top: 18vh;
    }
    .cursor-mobile {
      margin-top: 55%;
      width: 100px !important;
    }
  }
}
@media screen and (min-width: 1920px) {
  .text-feature {
    h1 {
      font-size: 64px;
    }
    .text-h3-mobile {
      display: none;
    }
    .text-h3-small {
      font-size: 18px;
      display: block;
    }

    margin-top: 20vh;
  }
}
@media screen and (min-width: 2800px) {
 .text-feature {
    h1 {
      font-size: 100px;
      margin-top: 0px;
    }
    .text-h3-small {
      display: block;
      font-size: 28px;
    }
  }
}
@media screen and (min-width: 3800px) {
  .background-change {
    margin-top: 20px;
    border-radius: 5px 5px 5px 5px;
  }
  .text-feature {
    h1 {
      font-weight: 500;
      font-size: 130px;
      margin-top: 50px;
    }
    .text-h3-mobile {
      display: none;
    }
    .text-h3-small {
      display: block;
      font-size: 36px;
    }
  }
  .text-change {
    h1 {
      font-size: 96px;
      padding-top: 0px;
    }
    div {
      font-size: 34px;
    }
    h3 {
      font-size: 36px;
      padding-bottom: 50px;
    }
  }

  .first-line {
    .identical-block {
      span {
        font-size: 32px;
      }
      img {
        width: 240px;
      }
      div {
        font-size: 40px;
        padding-left: 30px;
      }
    }
  }
  .second-line {
    .around-block {
      span {
        color: #86868b;
        font-size: 32px;
      }
      img {
        width: 400px;
      }
      div {
        font-size: 40px;
        padding: 20px 0px 20px 20px;
      }
    }
  }
  .middle-block {
    img {
      width: 560px;
    }
    div {
      font-size: 40px;
      padding-left: 20px;
    }
    span {
      color: #86868b;
      font-size: 32px;
    }
  }
  .third-line {
    .single-block {
      span {
        color: #86868b;
        font-size: 32px;
      }
      img {
        width: 240px;
      }
      div {
        font-size: 40px;
        padding: 20px 0px 20px 20px;
      }
    }

    .big-block {
      span {
        color: #86868b;
        font-size: 32px;
      }
      img {
        width: 560px;
      }
      div {
        font-size: 40px;
        padding: 20px 20px 20px 20px;
      }
    }
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .feature-block {
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;
    opacity: 1;
    background-color: white;
  }
  .background-image {
    display: block;
    height: 100vh;
    width: 100vw;
    position: absolute;
    opacity: 1;
    background-image: linear-gradient(
        rgba(33, 28, 28, 0.8) 20%,
        rgba(33, 28, 28, 0) 80%
      ),
      url("../../assets/Coming-soon-mobile.jpg");
    background-size: cover;
    z-index: 1;
  }
  .cursor-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40vh;
    opacity: 1;
  }
  .text-feature {
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 18vh;
    margin-left: auto;
    margin-right: auto;
    h1 {
      font-weight: 500;
      font-family: Poppins;
      font-style: normal;
      font-size: 40px;
      line-height: 1.5;
      margin-bottom: 10px;
    }
    .text-h3-small {
      display: none;
    }
    .text-h3-mobile {
      display: block;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.5;
      @media screen and (max-width: 390px) {
        font-size: 16px;
      }
      @media screen and (max-width: 375px) {
        font-size: 15px;
      }
       @media screen and (max-width: 360px) {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-height: 670px) {
    .cursor-mobile {
      margin-top: 43vh;
      width: 100px;
    }
    .text-feature {
      h1 {
        font-size: 32px;
      }
      .text-h3-small {
        display: none;
      }
      .text-h3-mobile {
        display: block;
        font-size: 13px;
      }
    }
  }

  @media screen and (max-height: 600px) {
    .cursor-mobile {
      margin-top: 43vh;
      width: 100px;
    }
    .text-feature {
      h1 {
        font-size: 31px;
      }
      .text-h3-small {
        display: none;
      }
      .text-h3-mobile {
        display: block;
        font-size: 12px;
      }
    }
  }
}
</style>
