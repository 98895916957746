<template>
<svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.21245 4.77626H3.4692V3.29753C3.4692 2.15996 4.3786 1.19406 5.57267 1.19406H6.31148C7.16439 1.19406 7.90321 1.70581 8.24437 2.44463C8.35844 2.72929 8.69961 2.84228 8.98319 2.72929C9.26785 2.61521 9.38084 2.27404 9.26785 1.99047C8.75611 0.796407 7.61854 0 6.31148 0H5.57267C3.81036 0 2.33163 1.42114 2.33163 3.24103V4.77626C1.02348 5.00334 0 6.1974 0 7.56205V13.8735C0 15.4653 1.25056 16.7169 2.84337 16.7169H9.15486C10.7466 16.7169 11.9982 15.4663 11.9982 13.8735V7.61963C12.0547 6.02685 10.7477 4.77626 9.21245 4.77626ZM10.9183 13.9311C10.9183 14.8981 10.1794 15.6369 9.21245 15.6369H2.84337C1.87639 15.6369 1.13757 14.8981 1.13757 13.9311V7.61963C1.13757 6.65265 1.87639 5.91383 2.84337 5.91383H9.15486C10.1218 5.91383 10.8607 6.65265 10.8607 7.61963V13.9311H10.9183ZM6.88081 9.83718C6.88081 10.0643 6.76674 10.2924 6.59615 10.463V11.7712C6.59615 12.1123 6.36906 12.3394 6.02791 12.3394C5.68676 12.3394 5.45967 12.1123 5.45967 11.7712V10.463C5.28909 10.2924 5.17501 10.0654 5.17501 9.83718C5.17501 9.38194 5.57267 8.98428 6.02791 8.98428C6.48316 8.98428 6.88081 9.38194 6.88081 9.83718Z" fill="#666666"/>
</svg>


</template>
<script>
export default {

}
</script>