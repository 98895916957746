 <template>
  <div cols="9" class="nav-bar-header">
    <div class="site-logo">
      <a href="/">
        <img
          class="icon-logo-navbar"
          src="../../assets/icon-logo-navbar.svg"
          alt=""
        />
      </a>
    </div>
    <v-list-item-group class="d-flex flex-row">
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :hash="`#${item.id}`"
        :class="$route.path === '/' ? '' : 'nav-bar-border'"
        :ripple="false"
      >
        <v-list-item-title
          :class="
            $route.path === '/'
              ? `nav-bar-item ${item.id}`
              : `nav-bar ${item.id}`
          "
        >
          {{ item.title }}</v-list-item-title
        >
      </v-list-item>
    </v-list-item-group>
  </div>
</template>



<script>
import { Constants } from "@/config/constants";
// import IconLogo from "../icons/IconLogo.vue";
import { SET_FEEDBACK } from "@/store/ui";
import { mapMutations } from "vuex";
import { gsap } from "gsap";

export default {
  data() {
    return {
      items: Constants.NavMenuItems,
    };
  },
  // components: { IconLogo },
  mounted() {},
  methods: {
    ...mapMutations("ui", {
      changeIsFeedback: SET_FEEDBACK,
    }),
    toMainPage() {
      console.log(this.$route.path);
      if (this.$route.path !== "/") {
        gsap.to(".rendezvu, .cases, .feature, .technology, .getInTouch", {
          color: "white",
        });
        gsap.to(".home", { color: "#1eafc1" });
        gsap.to(".icon-apple", { color: "white" });
        gsap.to(".icon-windows-download", { color: "white" });
        this.$router.push("/");
      }
    },
  },
  // },
};
</script>

<style lang="scss" scoped>
.list-item {
  background-color: none !important;
}
a {
  text-decoration: none;
}

.nav-bar-header {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-left: 20px;
  z-index: 8;
}
.nav-bar-item {
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 100%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @media screen and (min-width: 1600px) {
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1800px) {
    font-size: 0.9rem;
  }
  @media screen and (min-width: 2100px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 2800px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 580px) {
    font-size: 9px;
  }
}
.nav-bar {
  color: black !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 100%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @media screen and (min-width: 1600px) {
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1800px) {
    font-size: 0.9rem;
  }
  @media screen and (min-width: 2100px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 2800px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 580px) {
    font-size: 9px;
  }
}
.icon-logo-navbar {
  width: 50px !important;
  max-width: 55px !important;

  @media screen and (min-width: 1600px) {
    width: 50px !important;
    max-width: 55px !important;
  }
  @media screen and (min-width: 1800px) {
    width: 60px !important;
    max-width: 60px !important;
  }
  @media screen and (min-width: 2100px) {
    width: 70px !important;
    max-width: 70px !important;
  }
  @media screen and (min-width: 2800px) {
    width: 100px !important;
    max-width: 100px !important;
  }
}
.site-logo {
  display: flex;
  align-items: center;
  margin-left: 10px;
  @media screen and (min-width: 2100px) {
    margin-left: 40px;
  }
  @media screen and (min-width: 2800px) {
    margin-left: 60px;
  }
}
.nav-bar-border {
  border: none !important;
  border: none !important;
}
.v-application a {
  color: rgba(255, 255, 255, 0) !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: rgb(0 0 0 / 0%) !important;
}

.theme--light.v-list-item[data-v-3f1ae435]:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: rgba(0, 0, 0, 0) !important;
}

.v-list-item--link:before {
  background-color: rgba(0, 0, 0, 0);
  transition: 0s cubic-bezier(0.25, 0.8, 0.5, 0) !important;
}
.theme--light.v-list-item--active::before {
  opacity: 0;
}
.d-flex {
  margin-left: 40px;
  align-items: center;
}
.v-list-item--link {
  cursor: default !important;
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
  .nav-bar-header {
    margin-left: 15px;
    @media screen and (max-width: 1024px) {
      margin-left: 10px;
    }
  }
  .nav-bar-item {
    font-size: 9px;
    @media screen and (max-width: 1024px) {
      font-size: 8px;
    }
    @media screen and (max-height: 580px) {
      @media screen and (max-width: 740px) {
        font-size: 7px;
      }
    }
  }
  .nav-bar {
    font-size: 9px;
    @media screen and (max-width: 1024px) {
      font-size: 8px;
    }
  }
  .icon-logo-navbar {
    width: 45px !important;
    @media screen and (max-width: 1024px) {
      width: 45px;
    }
  }
  .d-flex {
    @media screen and (max-width: 1024px) {
      margin-left: 15px;
    }
    @media screen and (max-width: 800px) {
      margin-left: 10px;
    }
  }
  @media screen and (max-height: 580px) {
    @media screen and (max-width: 740px) {
      .d-flex {
        margin-left: 7px;
      }
      .icon-logo-navbar {
        width: 30px !important;
      }
    }
  }
}
@media screen and (min-width: 3800px) {
  .nav-bar-item {
    font-weight: 500;
  
    text-transform: uppercase;
    margin-right: 20px;
    letter-spacing: -0.1px;
   
    font-size: 1.7rem;
  
  }
  .icon-logo-navbar {
    width: 120px !important;
    max-width: 120px !important;
  }
  .nav-bar-header {
    margin-left: 0px;
  }
  .nav-bar {
    font-weight: 500;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    margin-right: 20px;
  }
  .site-logo {
    margin-left: 140px;
  }
}
@media screen and(max-width:580px) and (orientation: portrait) {
  .mobile-menu {
    margin-left: 1em;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    position: fixed;
  }
  .mobile-menu-img {
    margin: 0 25vw 0 22vw;
  }
  .wrapper {
    background-color: white !important;
    height: 50px;
    // width: 100%;
    padding: 0.125rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
  .navigation-main {
    display: none;
  }
}
</style>
