<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9512 0C13.024 0 14.056 0.412222 14.8152 1.15189C15.5752 1.89 16 2.88556 16 3.92778V10.0722C16 12.2422 14.184 14 11.9512 14H4.048C1.8152 14 0 12.2422 0 10.0722V3.92778C0 1.75778 1.8072 0 4.048 0H11.9512ZM12.856 4.04444C12.688 4.03589 12.528 4.09111 12.4072 4.2L8.8 7C8.336 7.37411 7.6712 7.37411 7.2 7L3.6 4.2C3.3512 4.02111 3.0072 4.04444 2.8 4.25444C2.584 4.46444 2.56 4.79889 2.7432 5.03222L2.848 5.13333L6.488 7.89444C6.936 8.23667 7.4792 8.42333 8.048 8.42333C8.6152 8.42333 9.168 8.23667 9.6152 7.89444L13.224 5.08667L13.288 5.02444C13.4792 4.79889 13.4792 4.47222 13.2792 4.24667C13.168 4.13078 13.0152 4.06 12.856 4.04444Z"
      fill="#ADC7CC"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {};
</script>
