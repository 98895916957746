<template>
  <div class="about-rendezvu" id="rendezvu">
    <div class="all-content">
      <div class="about-rendezvu-text animonRend" data-effect="fadeInUp">
        <div class="first-column">
          <h1>What is RendezVu?</h1>
          <p>Where the physical and digital worlds meet in the Metaverse</p>
        </div>
        <div class="second-column">
          <p>
            RendezVu's Virtual Universe (Vu) is an interactive <br />
            3D world designed to give people an opportunity to meet <br />
            in a comfortable, hybrid environment instantly from any device.<br />
            RendezVu is an entry way to the Metaverse.
          </p>
          <img width="100px" src="../../assets/icon-scroll.svg" alt="" />
        </div>
        <div class="second-column-mobile">
          <p>
            RendezVu's Virtual Universe (Vu) is an interactive <br />
            3D world designed to give people an opportunity<br />
            to meet in a comfortable, hybrid environment <br />instantly from
            any device.
          </p>
        </div>
      </div>
      <div class="image-block animon" data-delay="400" data-effect="fadeIn">
        <div class="img1">
          <img src="../../assets/icon-window.svg" alt="" />
          <div class="info-text">
            <h3>Collaboration Tools</h3>
            <p>
              Presentation, screen sharing,<br />
              Zoom integration
            </p>
          </div>
        </div>
        <div class="img2">
          <img src="../../assets/icon-voice.svg" alt="" />
          <div class="info-text">
            <h3>Spatial Audio</h3>
            <p>
              Distance-based and location-based spatial audio<br />
              that allows for realistic audio conversations
            </p>
          </div>
        </div>
        <div class="img3">
          <img src="../../assets/icon-friends.svg" alt="" />
          <div class="info-text">
            <h3>Multiplayer Mode</h3>
            <p>
              Real-time interaction between<br />
              hundreds of users
            </p>
          </div>
        </div>
        <div class="img4">
          <img src="../../assets/icon-hybrid.svg" alt="" />
          <div class="info-text">
            <h3>Hybrid</h3>
            <p>
              Bridge your physical and virtual audiences<br />
              in one interactive experience
            </p>
          </div>
        </div>
        <div class="img1-mobile">
          <div class="img1-mobile-row">
            <img src="../../assets/icon-window.svg" alt="" />
            <div class="info-text">
              <h3>Collaboration Tools</h3>
              <p>
                Presentation,<br />
                screen sharing,<br />
                Zoom integration
              </p>
            </div>
          </div>
          <div class="img1-mobile-row">
            <img src="../../assets/icon-voice.svg" alt="" />
            <div class="info-text">
              <h3>Spatial Audio</h3>
              <p>
                Distance-based and location<br />-based spatial audio that
                allows<br />
                for realistic audio conversations
              </p>
            </div>
          </div>
          <div class="img1-mobile-row">
            <img src="../../assets/icon-friends.svg" alt="" />
            <div class="info-text">
              <h3>Multiplayer Mode</h3>
              <p>
                Real-time interaction<br />
                between hundreds<br />
                of users
              </p>
            </div>
          </div>
          <div class="img1-mobile-row">
            <img src="../../assets/icon-hybrid.svg" alt="" />
            <div class="info-text">
              <h3>Hybrid</h3>
              <p>
                Bridge your physical and<br />
                virtual audiences in one<br />
                interactive experience
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animon } from "animon";

export default {
  data() {
    return {};
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    animon(".animonRend");
    animon(".animon");
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.about-rendezvu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}
.all-content {
  margin-top: 12vh;
  height: calc(100vh - 12vh);
  min-width: 100vw;
  justify-content: flex-end;
  background: url("../../assets/background-rendezvu.jpg");

  background-size: cover;
  opacity: 1;
}

.about-rendezvu-text {
  display: flex;
  height: 26vh;
  text-align: justify;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
}

.first-column {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  text-align: start;
  h1 {
    font-weight: 500;
    font-size: 32px;
    font-style: normal;
    letter-spacing: 1px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: -0.5px;
  }
}
.second-column-mobile {
  display: none;
}
.second-column {
  display: flex;
  margin-right: 30px;
  text-align: start;
  align-items: flex-end;
  margin-top: 40px;
  margin-bottom: 40px;
  p {
    font-size: 13px;
  }
  img {
    margin-left: 20px;
  }
}
.image-block {
  display: grid;
  width: 100vw;
  height: 62vh;
  grid-template-columns: 25vw 25vw 25vw 25vw;
  grid-template-areas: "A B C D ";
  opacity: 1;
  color: white;
  .img1-mobile {
    display: none;
  }
  .img1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-area: A;
    width: 25vw;
    background-image: linear-gradient(
        rgba(37, 33, 33, 0) 50%,
        rgba(37, 33, 33, 0.9) 90%
      ),
      url("../../assets/Collaboration-tools.jpg");
    background-size: cover;
  }

  .img2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-area: B;
    width: 25vw;
    background-image: linear-gradient(
        rgba(37, 33, 33, 0) 50%,
        rgba(37, 33, 33, 0.9) 90%
      ),
      url("../../assets/spatial-audio.jpg");
    background-size: cover;
  }

  .img3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-area: C;
    width: 25vw;
    background-image: linear-gradient(
        rgba(37, 33, 33, 0) 50%,
        rgba(37, 33, 33, 0.9) 90%
      ),
      url("../../assets/multiplayer-mode.jpg");
    background-size: cover;
  }

  .img4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-area: D;
    width: 25vw;
    background: linear-gradient(
        rgba(37, 33, 33, 0) 50%,
        rgba(37, 33, 33, 0.9) 90%
      ),
      url("../../assets/Hybrid.jpg");
    background-size: cover;
  }

  img {
    width: 80px;
    margin-left: 1.5vw;
    margin-bottom: 4vh;
    backdrop-filter: blur(1.5px);
    border-radius: 50%;
  }
}

.info-text {
  text-align: start;
  margin-left: 30px;
  margin-bottom: 15px;
  h3 {
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    letter-spacing: -0.5px;
  }
}

.text-focus-in {
  -webkit-animation: text-focus-in 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  font-size: 14px;
  font-weight: normal;
  opacity: 0;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@media screen and (min-width: 1920px) {
  .about-rendezvu-text {
    height: 24vh;
  }
  .first-column {
    margin-left: 75px;
    h1 {
      font-size: 44px;
    }
    p {
      font-size: 15px;
    }
  }
  .second-column {
    margin-right: 98px;
    p {
      font-size: 18px;
    }
    img {
      margin-left: 20px;
      width: 167px;
    }
  }
  .image-block {
    height: 64vh;

    img {
      width: 135px;
      margin-left: 45px;
      margin-bottom: 24px;
    }
  }
  .info-text {
    text-align: start;
    margin-left: 50px;
    margin-bottom: 15px;
    h3 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    p {
      font-size: 1rem;
    }
  }
}
@media screen and (min-width: 2100px) {
  .info-text {
    h3 {
      font-size: 1.6rem;
      margin-bottom: 15px;
    }
    p {
      font-size: 1.1rem;
    }
  }
}
@media screen and (min-width: 2400px) {
  .info-text {
    h3 {
      font-size: 1.8rem;
      margin-bottom: 15px;
    }
    p {
      font-size: 1.2rem;
    }
  }
}
@media screen and (min-width: 2800px) {
  .first-column {
    margin-left: 100px;
    h1 {
      font-size: 64px;
    }
    p {
      font-size: 26px;
    }
  }
  .second-column {
    margin-right: 100px;
    // margin-top: 0px;
    // margin-bottom: 80px;
    p {
      font-size: 28px;
    }
    img {
      margin-left: 130px;
      width: 200px;
    }
  }
  .image-block {
    img {
      width: 170px;
      margin-bottom: 2vh;
    }
  }
  .info-text {
    margin-left: 50px;
    margin-bottom: 70px;
    h3 {
      font-size: 1.9rem;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.5rem;
      letter-spacing: -0.5px;
    }
  }
}
@media screen and (min-width: 3800px) {
  .first-column {
    margin-left: 150px;
    h1 {
      font-size: 94px;
      letter-spacing: -1px;
    }
    p {
      font-size: 38px;
    }
  }
  .second-column {
    margin-right: 160px;
    margin-top: 80px;
    margin-bottom: 80px;
    p {
      font-size: 38px;
    }
    img {
      margin-left: 130px;
      width: 250px;
    }
  }
  .image-block {
    img {
      width: 200px;
      margin-bottom: 2vh;
    }
  }
  .info-text {
    margin-left: 75px;
    margin-bottom: 70px;
    h3 {
      font-size: 2.7rem;
      margin-bottom: 30px;
    }
    p {
      font-size: 1.9rem;
      letter-spacing: -0.5px;
    }
  }
  .text-focus-in {
    font-size: 28px;
  }
}

@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .image-block {
    display: grid;
    width: 100%;
    height: 65%;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(2, 50%);
    grid-template-areas:
      "A B"
      "C D";
    opacity: 1;
    color: white;

    .img1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      grid-area: A;
      width: 100%;
      background-image: linear-gradient(
          rgba(37, 33, 33, 0) 30%,
          rgba(37, 33, 33, 0.9) 70%
        ),
        url("../../assets/Collaboration-tools-tablet.jpg");
      background-size: cover;
    }

    .img2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      grid-area: B;
      width: 100%;
      background-image: linear-gradient(
          rgba(37, 33, 33, 0) 30%,
          rgba(37, 33, 33, 0.9) 70%
        ),
        url("../../assets/spatial-audio-tablet.jpg");
      background-size: cover;
    }

    .img3 {
      grid-area: C;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      background-image: linear-gradient(
          rgba(37, 33, 33, 0) 30%,
          rgba(37, 33, 33, 0.9) 70%
        ),
        url("../../assets/multiplayer-mode-tablet.jpg");
      background-size: cover;
    }

    .img4 {
      grid-area: D;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      background-image: linear-gradient(
          rgba(37, 33, 33, 0) 30%,
          rgba(37, 33, 33, 0.9) 70%
        ),
        url("../../assets/Hybrid-tablet.jpg");
      background-size: cover;
    }

    h3 {
      font-style: normal;
      text-align: center;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0px;
      font-size: 28px;
      @media screen and (max-width: 700px) {
        font-size: 24px;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      text-align: center;
      // font-size: 1.1rem;
      letter-spacing: -0.5px;
      @media screen and (max-width: 934px) {
        // font-size: 0.9rem;
      }
      @media screen and (max-width: 700px) {
        // font-size: 0.7rem;
      }
    }
    img {
      width: 80px;
      margin-left: 0vw;
      margin-top: 0%;
      margin-bottom: 5%;
      backdrop-filter: blur(1.5px);
      border-radius: 50%;
      @media screen and (max-width: 820px) {
        // margin-top: 87%;
      }
      @media screen and (max-width: 810px) {
        // margin-top: 77%;
      }
      @media screen and (max-width: 700px) {
        // margin-top: 77%;
        width: 90px;
      }
    }
  }

  .info-text {
    text-align: center;
    margin-left: 0vw;
    margin-bottom: 15px;
  }
  .about-rendezvu-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 35%;
  }
  .first-column {
    margin-left: 50px;
    h1 {
      font-weight: 500;
      font-size: 3rem;
      font-style: normal;
      letter-spacing: 1px;
      margin-top: 20px;
      @media screen and (max-width: 700px) {
        font-size: 2.3rem;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      letter-spacing: -0.5px;
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
      @media screen and (max-width: 700px) {
        font-size: 16px;
      }
    }
  }
  .second-column-mobile {
    display: none;
  }
  .second-column {
    display: flex;
    margin-right: 30px;
    text-align: start;
    align-items: flex-end;
    margin-top: 10px;
    margin-left: 50px;
    margin-bottom: 10px;
    p {
      font-size: 22px;
      @media screen and (max-width: 820px) {
        font-size: 18px;
      }
      @media screen and (max-width: 700px) {
        font-size: 16px;
      }
    }
    img {
      display: none;
    }
  }
  .animon {
    opacity: 1 !important;
    will-change: opacity;
    transition: opacity 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1);
  }
  .animon.is-visible {
    opacity: 1 !important;
  }
  .animon[data-effect="fadeIn"].is-visible {
    opacity: 1;
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .about-rendezvu-text {
    height: 36vh;
  }

  .first-column {
    margin-left: 70px;
    h1 {
      font-size: 34px;
      @media screen and (max-device-width: 1150px) {
        font-size: 24px;
      }
    }
    p {
      font-size: 14px;
      @media screen and (max-device-width: 1150px) {
        font-size: 12px;
      }
      @media screen and (max-device-width: 1000px) {
        font-size: 11px;
      }
      @media screen and (max-device-width: 900px) {
        font-size: 9px;
      }
    }
  }

  .second-column {
    p {
      font-size: 14px;
      @media screen and (max-device-width: 1150px) {
        font-size: 12px;
      }
      @media screen and (max-device-width: 1000px) {
        font-size: 11px;
      }
      @media screen and (max-device-width: 900px) {
        font-size: 9px;
      }
    }
    img {
      width: 100px !important;
      @media screen and (max-device-width: 1150px) {
        width: 90px !important;
      }
      @media screen and (max-device-width: 1000px) {
        width: 70px !important;
      }
    }
  }
  .image-block {
    height: 52vh;
    h3 {
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0px;
      margin-bottom: 10px;
    }
    p {
      // font-size: 12px;
      @media screen and (max-device-width: 1250px) {
        // font-size: 11px;
      }
      @media screen and (max-device-width: 1150px) {
        // font-size: 10px;
      }
      @media screen and (max-device-width: 1030px) {
        // font-size: 9px;
      }

      @media screen and (max-device-width: 900px) {
        // font-size: 8px;
      }
    }
    img {
      width: 80px;
      margin-left: 2vw;
      margin-bottom: 4vh;
      backdrop-filter: blur(1.5px);
      border-radius: 50%;
      @media screen and (max-device-width: 1024px) {
        width: 70px;
        margin-left: 1vw;
      }
      @media screen and (max-device-width: 950px) {
        width: 60px;
      }
    }
  }
  .info-text {
    text-align: start;
    margin-left: 2vw;
    margin-bottom: 15px;
    @media screen and (max-width: 1200px) {
      p {
        font-size: 0.7rem;
      }
    }
    @media screen and (max-width: 800px) {
      p {
        font-size: 0.5rem;
      }
      h3 {
        font-size: 1rem;
      }
    }
  }
  @media screen and (max-height: 580px) {
    @media screen and (max-width: 740px) {
      .first-column {
        margin-left: 20px;
        text-align: start;
        h1 {
          font-size: 20px;
          letter-spacing: 1px;
        }
        p {
          font-size: 8px;
          letter-spacing: -0.5px;
          opacity: 0.7;
        }
      }

      .second-column {
        margin-right: 0px;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 0px;
        p {
          font-size: 8px;
        }
        img {
          margin-left: 10px;
          width: 50px !important;
        }
      }
      .image-block {
        h3 {
          font-size: 12px;
          margin-bottom: 2px;
        }
        p {
          // font-size: 7px;
        }
        img {
          width: 40px;
        }
      }
    }
  }
}
.animonRend {
  opacity: 1;
  will-change: opacity, transform;
  transition: opacity 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1),
    transform 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1);
}
.animonRend.is-visible {
  opacity: 1;
}

.animonRend[data-effect="fadeInUp"] {
  transform: translateY(-20px);
}
.animonRend[data-effect="fadeInUp"].is-visible {
  transform: translateY(0);
}
.animon {
  opacity: 1;
  will-change: opacity;
  transition: opacity 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1);
}
.animon.is-visible {
  opacity: 1;
}

.animon[data-effect="fadeIn"] {
  opacity: 1;
}
.animon[data-effect="fadeIn"].is-visible {
  opacity: 1;
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .about-rendezvu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgb(255, 255, 255);
  }
  .all-content {
    margin-top: 70px;
    height: calc(100vh - 70px);
    min-width: 100vw;
    justify-content: flex-end;
    background: url("../../assets/back-rendezvu-mobile.jpg");
    background-size: cover;
    // radial-gradient(
    //   circle at left,
    //   rgba(255, 255, 255, 0.1) 0%,
    //   rgba(255, 255, 255, 0.2) 10%,
    //   rgba(255, 255, 255, 0.2) 10%,
    //   rgba(238, 174, 202, 0.3) 50%,
    //   rgba(238, 174, 202, 0.4) 60%,
    //   rgba(148, 203, 245, 0.3) 90%,
    //   rgba(148, 203, 245, 0.4) 100%
    // );
    opacity: 1;
  }
  .first-column {
    margin-left: 0px;
  }
  .second-column {
    display: none;
  }

  .about-rendezvu-text {
    display: flex;
    flex-direction: column;
    text-align: start;

    opacity: 1;
    margin-left: 5vw;
    font-family: Poppins;
    font-style: normal;
    height: 33.5vh;
    align-items: flex-start;
    justify-content: center;

    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 170%;
    }
    p {
      letter-spacing: -0.5px;
      font-size: 15px;
      line-height: 155%;
    }
    div {
      font-weight: normal;
      font-size: 15px;
      line-height: 165%;
      text-align: start;
      // margin: 15px 0 15px 0;
    }
  }
  .second-column-mobile {
    display: flex;
    p {
      font-size: 15px;
    }
  }
  .image-block {
    display: grid;
    width: 100vw;

    opacity: 1;
    color: white;
    grid-template-areas: "A";

    .img1-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      // align-items: center;
      padding-top: 5vh;
      grid-area: A;
      width: 100vw;
      height: 59vh;
      background-image: linear-gradient(
          to left,
          rgba(37, 33, 33, 0) 20%,
          rgba(37, 33, 33, 0.8) 50%
        ),
        url("../../assets/Collaboration-tools-mobile.jpg");
      background-size: cover;
      h3 {
        font-style: normal;
        font-weight: 500;
        line-height: 50%;
        letter-spacing: 0px;
        font-size: 15px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        // font-size: 13px;
        letter-spacing: -0.5px;
      }
      img {
        width: 70px;
        // margin-bottom: 2vh;
        backdrop-filter: blur(1.5px);
        border-radius: 50%;
      }
    }
    .img1 {
      display: none;
    }
    .img2 {
      display: none;
    }
    .img3 {
      display: none;
    }
    .img3 {
      display: none;
    }
  }

  .info-text {
    margin-bottom: 0px;
    margin-left: 7px;
  }
  .img1-mobile-row {
    display: flex;
  }
  .text-focus-in {
    -webkit-animation: text-focus-in 2s cubic-bezier(0.55, 0.085, 0.68, 0.53)
      both;
    animation: text-focus-in 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    font-size: 14px;
    font-weight: normal;
    opacity: 0;
  }

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }

  @media screen and (max-width: 375px) {
    .about-rendezvu-text {
      margin-top: 2vh;
      margin-left: 4vw;

      h1 {
        font-size: 28px;
      }
      p {
        font-size: 13px;
        line-height: 155%;
        letter-spacing: 0.1px;
      }
      div {
        font-size: 14px;
        line-height: 155%;
        margin: 7px 0 7px 0;
      }
    }
    .second-column-mobile {
      display: flex;
      p {
        font-size: 13px;
      }
    }
    .image-block {
      .img1-mobile {
        padding-top: 4vh;

        h3 {
          font-size: 17px;
        }
        img {
          width: 60px;
        }
      }
    }
    .info-text {
      margin-bottom: 3px;
      margin-left: 7px;
    }
  }
  @media screen and (max-width: 360px) {
    .about-rendezvu-text {
      margin-top: 2vh;
      margin-left: 4vw;
      height: 30.5vh;
      h1 {
        font-size: 28px;
      }
      p {
        font-size: 13px;
        line-height: 155%;
        letter-spacing: 0.1px;
      }
      div {
        font-size: 14px;
        line-height: 155%;
        margin: 0px 0 0px 0;
      }
    }
    .second-column-mobile {
      display: flex;
      p {
        font-size: 13px;
      }
    }
    .image-block {
      .img1-mobile {
        padding-top: 4vh;

        h3 {
          font-size: 16px;
        }
        p {
          // font-size: 12px;
        }
        img {
          width: 60px;
        }
      }
    }
    .info-text {
      margin-bottom: 3px;
      margin-left: 7px;
    }
  }
  @media screen and (max-height: 760px) {
    .about-rendezvu-text {
      height: 25vh;
      h1 {
        font-size: 22px;
      }
      p {
        font-size: 12px;
        line-height: 155%;
        letter-spacing: 0.1px;
      }
    }
    .image-block {
      margin-bottom: 0px;
      .img1-mobile {
        height: 66vh;
        h3 {
          font-size: 20px;
        }
        p {
          // font-size: 13px;
        }
        img {
          width: 60px;
        }
      }
    }
    .info-text {
      margin-bottom: 2px;
    }
  }
  @media screen and (max-height: 670px) {
    .about-rendezvu-text {
      height: 25vh;
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 11px;
        line-height: 155%;
        letter-spacing: 0.1px;
      }
    }
    .image-block {
      margin-bottom: 0px;
      .img1-mobile {
        height: 66vh;
        h3 {
          font-size: 16px;
        }
        p {
          // font-size: 11px;
        }
        img {
          width: 55px;
        }
      }
    }
    .info-text {
      margin-bottom: 2px;
    }
  }

  @media screen and (max-height: 600px) {
    .about-rendezvu-text {
      margin-top: 0vh;
      margin-left: 5vw;

      h1 {
        font-size: 20px;
        margin-top: 15px;
      }
      p {
        font-size: 11px;
        line-height: 130%;
        letter-spacing: 0.1px;
      }
      div {
        font-size: 11px;
        line-height: 130%;
        margin: 10px 0 10px 0;
      }
    }
    .image-block {
      .img1-mobile {
        h3 {
          font-size: 22px;
        }
        p {
          // font-size: 12px;
        }
        img {
          margin-bottom: 0vh;
        }
      }
    }
  }
  @media screen and (max-height: 570px) {
    .about-rendezvu-text {
      margin-left: 5vw;
      height: 27vh;
      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 170%;
      }
      p {
        letter-spacing: -0.5px;
        font-size: 12px;
        line-height: 100%;
      }
      div {
        font-size: 11px;
        line-height: 10%;
        margin: 0px 0 0px 0;
      }
    }
    .second-column-mobile {
      display: flex;
      justify-content: center;

      p {
        font-size: 11px;
        line-height: 110%;
      }
    }
    .image-block {
      .img1-mobile {
        padding-top: 5vh;
        height: 61vh;
        h3 {
          line-height: 0%;
          font-size: 15px;
        }
        p {
          // font-size: 11px;
          line-height: 130%;
        }
        img {
          width: 55px;
        }
      }
      .img1 {
        display: none;
      }
      .img2 {
        display: none;
      }
      .img3 {
        display: none;
      }
      .img3 {
        display: none;
      }
    }

    .info-text {
      margin-bottom: 5px;
      margin-left: 7px;
    }
    .img1-mobile-row {
      display: flex;
      align-items: flex-start;
    }
  }
}
</style>
