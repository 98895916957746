<template>
  <div>
    <v-alert color="red" dense type="error">
      {{ errorMessage }}
    </v-alert>
  </div>
</template>
  
<script>  
  export default {
    data() {
      return {
        errorMessage: null
      };
    },
    mounted() {
      this.errorMessage = this.$route.meta.errorMessage ?? 'Something went wrong'
    },
  };
  </script>
  
  