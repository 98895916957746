let AmazonCognitoIdentity = require('amazon-cognito-identity-js');
let CognitoRefreshToken = require('amazon-cognito-identity-js').CognitoRefreshToken;
let authenticated = false;
let isAdmin = false;

/* eslint-disable no-unused-vars */
import loader from '@/config.loader'
import request from "@/plugins/request";
import decoder from 'jwt-decode'


let AWS = require('aws-sdk')

let poolData = {
  UserPoolId: loader.getConfigValue('VUE_APP_COGNITO_USER_POOL_ID'),//'ca-central-1_9YYvbuWst', // Your user pool id here
  ClientId: loader.getConfigValue('VUE_APP_COGNITO_CLIENT_ID') // Your client id here
}

let ServerListJsonPath = loader.getConfigValue('VUE_APP_SERVER_LIST_JSON_PATH');
let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export default {
  preSignUp(email) {
    return new Promise((resolve, reject) => {
      const url = `/users/checkEmail?email=${encodeURIComponent(email.replace(/\s+/g, ''))}`

      request({ method: 'POST', url: url })
        .then(result => {
          resolve({ data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },

  signUp(username, password, email, company = null) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      let attributeList = [];
      let dataEmail = {
        Name: 'email',
        Value: email,
      };
      let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

      attributeList.push(attributeEmail);
      if (company) {
        attributeList.push({
          Name: 'family_name',
          Value: company.toLowerCase()
        });
      }
      this.preSignUp(email)
        .then((res) => {
          if (res.data.isExist && res.data.statusText === "UNCONFIRMED") {
            reject({ message: 'This email is not confirmed' })
          }
          else if (res.data.isExist) {
            reject({ message: 'This email is already registered' })
          } else {
            userPool.signUp(username, password, attributeList, null, function (err, result) {
              if (err) {
                reject({ status: err.code, message: err.message });
              } else {
                let cognitoUser = result.user;
                resolve({ status: result.statusCode, data: cognitoUser.getUsername() });
              }
            })
          }
        })

    })
  },

  updatePassword(username, password) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      const body = {
        userName: username,
        password: password
      }

      request({ method: 'POST', url: `/users/updatePassword`, body: JSON.stringify(body) })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },

  adminConfirmUser(username) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      request({ method: 'POST', url: `/users/confirm?userName=${username}` })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },

  resendConfirmationCode(username) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      let userData = {
        Username: username,
        Pool: userPool,
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          reject({ status: err.code, message: err.message });
        } else
          resolve({ status: result.statusCode, data: result });
      });
    });
  },

  confirmRegister(username, confirmCode) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      let userData = {
        Username: username,
        Pool: userPool,
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.confirmRegistration(confirmCode, true, function (err, result) {
        if (err) {
          reject({ status: err.code, message: err.message });
        } else
          resolve({ status: result.statusCode, data: result });
      });
    });
  },

  addUserToGroup(username, groupName) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      const body = {
        userName: username,
        groupName: groupName.toLowerCase()
      }

      request({ method: 'POST', url: `/groups/addToGroup`, body: JSON.stringify(body) })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },

  removeUserFromGroup(username, groupName) {
    username = username.replaceAll(' ', '_');
    const body = {
      userName: username,
      groupName: groupName.toLowerCase()
    }

    return new Promise((resolve, reject) => {
      request({ method: 'POST', url: `/groups/removeFromGroup`, body: JSON.stringify(body) })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message, response: err.response.data });
        })
    });
  },

  signIn(username, password) {
    username = username.replaceAll(' ', '_');
    return new Promise((resolve, reject) => {
      let authenticationData = {
        Username: username,
        Password: password,
      };
      let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      let userData = {
        Username: username,
        Pool: userPool
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          cognitoUser.getSession(function (err, session) {
            if (err) {
              reject({ status: err.statusCode, message: err.message });
            }
            let idToken = result.getIdToken().getJwtToken();
            let accessToken = result.getAccessToken().getJwtToken();
            let decodeToken = decoder(idToken);
            authenticated = true;
            localStorage.setItem('cognito-user-token', idToken);
            localStorage.setItem('cognito-user-access-token', accessToken);
            localStorage.setItem('cognito-user-id', decodeToken.sub);
            localStorage.setItem('cognito-user-info', decodeToken['cognito:username']);
            localStorage.setItem('session-token', session.getRefreshToken().token);

            resolve({ status: result.statusCode, data: idToken });
          })
        },
        onFailure: function (err) {
          reject({ status: err.statusCode, message: err.message });
        }
      });
    });
  },

  restorePassword(username) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      let userData = {
        Username: username,
        Pool: userPool
      }
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
          resolve({ status: 200, data: `email: ${data.CodeDeliveryDetails.Destination}` });
        },
        onFailure: function (err) {
          reject({ status: err.code, message: err.message });
        }
      });
    })
  },

  confirmPassword(username, confirmCode, newPassword) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      let userData = {
        Username: username,
        Pool: userPool,
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.confirmPassword(confirmCode, newPassword, {
        onFailure(err) {
          reject({ status: err.code, message: err.message });
        },
        onSuccess() {
          resolve({ status: 200, data: 'Password updated!' });
        },
      });
    });
  },

  editPassword(username, oldPassword, newPassword) {
    let self = this
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      let authenticationData = {
        Username: username,
        Password: oldPassword,
      };
      let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      let userData = {
        Username: username,
        Pool: userPool
      };

      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          self.updatePassword(username, newPassword)
            .then(() => {
              resolve({ status: result.statusCode, data: username });
            })
            .catch((error) => {
              reject({ status: error.statusCode, message: error.message });
            })
        },
        onFailure: function (err) {
          reject({ status: err.statusCode, message: err.message });
        }
      });
    });
  },
  editTemporaryPassword(username, oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      username = username.replaceAll(' ', '_');
      let authenticationData = {
        Username: username,
        Password: oldPassword,
      };
      let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      let userData = {
        Username: username,
        Pool: userPool
      };

      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve({ status: result.statusCode, data: username });
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;

          // Get these details and call
          cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
        },
        onFailure: function (err) {
          reject({ status: err.statusCode, message: err.message });
        }
      });
    });
  },

  authenticated() {
    return new Promise((resolve, reject) => {
      const currentUser = userPool.getCurrentUser();
      authenticated = currentUser && currentUser.storage['cognito-user-token'];
      if (authenticated)
        resolve({ username: currentUser.storage['cognito-user-info'] });
      else
        reject({ status: 401, message: 'Unathorized status' });
    });
  },

  currentUserInfo() {
    return new Promise((resolve, reject) => {
      if (!userPool.getCurrentUser()) {
        reject({ err: 'Err' });
      }
      resolve({ userToken: localStorage.getItem('cognito-user-token'), userId: localStorage.getItem('cognito-user-id') });
    })
  },

  userData() {
    return new Promise((resolve, reject) => {
      let username = localStorage.getItem('cognito-user-info')
      let cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject({ status: 401, message: 'Unauthorized access' });
      }
      cognitoUser.getSession(function (err, session) {
        if (err) {
          reject({ status: err.statusCode, message: err.message });
          return;
        }
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
            reject({ status: err.statusCode, message: err.message });
            return;
          }
          let data = { username: username }
          for (let i = 0; i < result.length; i++) {
            data[result[i].getName()] = result[i].getValue()
          }
          resolve({ status: 200, data: data });
        });
      });
    });
  },

  adminUpdateAttributes(user, data, pool = 'user') {
    console.log('user, data====>', user, data);
    return new Promise((resolve, reject) => {
      let attributeList = [];
      let keys = Object.keys(data)
      for (const key of keys) {
        attributeList.push(
          {
            Name: key,
            Value: data[key]
          }
        )
      }
      const body = { attributes: attributeList };


      request({ method: 'POST', url: `/users/updateAttributes?userName=${user}&pool=${pool}`, body: JSON.stringify(body) })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message, response: err.response.data });
        })
    });
  },

  updateAttributes(data) {
    return new Promise((resolve, reject) => {
      let cognitoUser = userPool.getCurrentUser();
      let attributeList = [];
      if (!cognitoUser) {
        reject({ status: 401, message: 'Unauthorized access' });
      }

      let keys = Object.keys(data)
      for (const key of keys) {
        attributeList.push(
          {
            Name: key,
            Value: data[key]
          }
        )
      }

      cognitoUser.getSession(function (err, session) {
        if (err) {
          reject({ status: err.statusCode, message: err.message });
        }
        cognitoUser.updateAttributes(attributeList, function (err, result) {
          if (err) {
            reject({ status: err.statusCode, message: err.message });
          }
          resolve({ status: 200, data: result });
        });
      });
    });
  },
  uploadImage(data) {
    return new Promise((resolve, reject) => {
      const body = {
        fileContent: data.fileContent,
        username: data.username
      };

      request({
        method: 'POST',
        url: `/users/uploadImage`,
        body: JSON.stringify(body)
      })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        });
    });
  },

  signCheck2() {
    const currentuser = userPool.getCurrentUser();
    authenticated = currentuser && currentuser.storage['cognito-user-token'];
    if (!authenticated) {
      return null;
    } else {
      const decoded = decoder(currentuser.storage['cognito-user-token']);

      if (Date.now() < decoded["exp"] * 1000) {
        return currentuser.storage['cognito-user-token'];
      } else {
        this.refreshJWTToken()
          .then(result => {
            return result.data;
          })
      }
    }
  },

  async signCheck() {
    return new Promise(resolve => {
      (async () => {
        const currentuser = userPool.getCurrentUser();
        authenticated = currentuser && currentuser.storage['cognito-user-token'];
        if (!authenticated) {
          return resolve(null);
        } else {
          const decoded = decoder(currentuser.storage['cognito-user-token']);

          if (Date.now() < decoded["exp"] * 1000) {
            return resolve(currentuser.storage['cognito-user-token']);
          } else {
            this.refreshJWTToken()
              .then(result => {
                return resolve(result.data);
              })
          }
        }
      })();
    })
  },

  signOut() {
    let username = localStorage.getItem('cognito-user-info');
    let userData = {
      Username: username,
      Pool: userPool
    };
    let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.signOut();
    authenticated = false;
    isAdmin = false;
    localStorage.removeItem('cognito-user-token');
    localStorage.removeItem('cognito-user-id');
    localStorage.removeItem('cognito-user-info');
    localStorage.removeItem('session-token');
    localStorage.removeItem('cognito-is-admin');
  },

  getUserTokens() {
    const currentuser = userPool.getCurrentUser();
    if (currentuser) {
      return {
        accessToken: currentuser.storage['cognito-user-token'],
        userId: currentuser.storage['cognito-user-id'],
        accessToken2: currentuser.storage['cognito-user-access-token'],
        refreshToken: currentuser.storage['session-token']
      };
    }
    return null;
  },

  currentUser() {
    return userPool.getCurrentUser();
  },

  createUser(user) {
    return new Promise((resolve, reject) => {
      this.preSignUp(user.email)
        .then((res) => {
          if (res.data.isExist) {
            reject({message: 'This email is already registered'})
          } else {
            var body = {
              username: user.username.replaceAll(' ', '_'),
              attributes: [
                {
                  Name: 'email', /* required */
                  Value: user.email,
                },
                {
                  Name: 'email_verified', /* required */
                  Value: user.email_verified ? 'true' : 'false',
                },
                {
                  Name: 'address',
                  Value: user.address ? user.address : '',
                },
                {
                  Name: 'family_name',
                  Value: user.family_name ? user.family_name : ''
                },
                {
                  Name: 'nickname',
                  Value: user.nickname ? user.nickname : ''
                },
              ]
            };
            request({ method: 'POST', url: `/users/create`, body: JSON.stringify(body) })
              .then(result => {
                resolve({ status: 200, data: result.data });
              })
              .catch(err => {
                reject({ status: err.code, message: err.message, response: err.response.data });
              })
          }
        });
    });
  },

  deleteUser(username, pool = 'user') {
    return new Promise((resolve, reject) => {
      request({ method: 'POST', url: `/users/delete?userName=${username}&pool=${pool}` })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message, response: err.response.data });
        })
    });
  },

  refreshJWTToken() {
    return new Promise((resolve, reject) => {
      const currentuser = userPool.getCurrentUser();
      let refreshToken = currentuser.storage['session-token'];//localStorage.getItem('session-token')
      var token = new CognitoRefreshToken({ RefreshToken: refreshToken })
      let username = localStorage.getItem('cognito-user-info');
      if (!refreshToken || !username) {
        localStorage.setItem('cognito-user-info', currentuser.username);
        this.signOut();
        return;
      }
      let userData = {
        Username: username,
        Pool: userPool
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.refreshSession(token, (err, session) => {
        if (err) {
          reject({ status: err.statusCode, message: err.message });
        }
        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();

        localStorage.setItem('cognito-user-token', idToken);
        localStorage.setItem('cognito-user-access-token', accessToken);

        resolve({ status: 200, data: accessToken });
      });
    })
  },

  isUserAdmin() {
    isAdmin = localStorage.getItem('cognito-is-admin');
    if (isAdmin == null || isAdmin === 'undefined') {
      // try to auth
      let accessToken = localStorage.getItem('cognito-user-token');
      let decodeToken = decoder(accessToken);
      let groups = decodeToken["cognito:groups"];
      isAdmin = groups?.some(g => g == "administrator");
      localStorage.setItem('cognito-is-admin', isAdmin);
    }
    return (isAdmin === true || isAdmin === 'true');
  },

  getAllGroups() {
    return new Promise((resolve, reject) => {
      request({ method: 'GET', url: `/groups/all` })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },
  getAllCompanies() {
    return new Promise((resolve, reject) => {
      request({ method: 'GET', url: `/groups/companies` })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },

  getUsersGroups(username) {
    return new Promise((resolve, reject) => {
      request({ method: 'GET', url: `/groups/userGroups?userName=${username}` })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },

  getUserGroups() {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('cognito-user-token');
      let decodeToken = decoder(accessToken);
      let groups = decodeToken["cognito:groups"] || [];
      resolve(groups);
    });
  },
  getAllUsers() {
    return new Promise((resolve, reject) => {
      request({ method: 'GET', url: `/users/all` })
        .then(result => {
          resolve(result.data);
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },
  getUsersOfCompany(company) {
    return new Promise((resolve, reject) => {
      request({ method: 'GET', url: `/users/${company}` })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },

  getCompany(company) {
    return new Promise((resolve, reject) => {
      request({ method: 'GET', url: `/groups/company/${company}` })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        })
    });
  },

  createCompany(user) {
    return new Promise((resolve, reject) => {
      var body = {
        username: user.username,
        attributes: [
          {
            Name: 'picture', /* required */
            Value: user.picture,
          },
        ]
      };

      request({ method: 'POST', url: `/groups/company/create`, body: JSON.stringify(body) })
        .then(result => {
          resolve({ status: 200, data: result.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message, response: err.response.data });
        })
    });
  },
  updateCompany() {

  },
  /* Server Management */
  getAllServers() {
    return request({
      method: "GET",
      url: '/servers/stream'
      // fullUrl: ServerListJsonPath
    })
      .then((result) => {
        return result.data;
      })
      .catch(err => {
        return { status: err.code, message: err.message };
      })
  },

  getServerStatus(server) {
    return request({
      method: "GET",
      url: `/servers/statsByLink?server=${server}`
      // fullUrl: ServerListJsonPath
    })
      .then((result) => {
        return result.data;
      })
      .catch(err => {
        return { status: err.code, message: err.message };
      })
  },

  getUsersOfServer(server) {
    return request({
      method: "GET",
      url: `/servers/${server}/users`
    })
      .then((result) => {
        return result.data;
      })
      .catch(err => {
        return { status: err.code, message: err.message };
      })
  },

  getServerConfig(server) {
    return request({
      method: "GET",
      url: `/servers/${server}/config`
    })
      .then((result) => {
        return result.data;
      })
      .catch(err => {
        return { status: err.code, message: err.message };
      })
  },
  updateDataRoles(serverName, dataUsers) {
    return request({
      method: "PUT",
      url: `/servers/data?server=${serverName}`,
      body: dataUsers
    })
    .then((result) => {
      return result.data;
    })
    .catch(err => {
      return { status: err.code, message: err.message };
    })
  },

  getRoomsCredentials(serverName) {
    return request({
      method: "GET",
      url: `/servers/${serverName}/roomscredentials`,
    })
      .then((result) => {
        return result.data.Rooms;
      })
      .catch(err => {
        return { status: err.code, message: err.message };
      })
  },

  async getRoomsStatus(serverName) {
    return request({
      method: "GET",
      url: `/servers/${serverName}/roomsstatus`,
    })
      .then((result) => {
        return result.data.Rooms;
      })
      .catch(err => {
        return { status: err.code, message: err.message };
      })
  },
}



/* eslint-enable no-unused-vars */
