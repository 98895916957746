<template>
  <div :class="['wrapper', transparent && 'transparent', isOpen && 'is-open']">
    <div :class="$route.path !== '/' ? 'mobile-menu' : 'mobile-menu-main'">
      <div class="mobile-menu--user">
        <user-profile-menu v-if="user" />
        <a v-else href="/auth">
          <div class="icon-profile-circle">
            <v-icon small class="icon-user-mobile"> fa-solid fa-user</v-icon>
          </div>
        </a>
      </div>
      <a href="/">
        <img
          class="icon-logo-navbar"
          src="../../assets/icon-logo-navbar.svg"
          alt=""
        />
      </a>
      <v-menu transition="fade-transition" class="hamburger-menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn to="/contacts" class="mobile-menu--icon" icon>
            <img
              class="btn-menu"
              v-bind="attrs"
              v-on="on"
              color="#1EAFC1"
              v-if="isOpen === false"
              src="../../assets/icon-menu-hamburger.svg"
              alt=""
            />
          </v-btn>
        </template>
      </v-menu>
    </div>

    <div
      :class="$route.path !== '/' ? 'navigation' : 'navigation-main'"
      class="slide-bottom unvisible"
    >
      <nav-bar></nav-bar>

      <v-spacer></v-spacer>
      <div class="icon-div-header">
        <a href="/download?link=windows">
          <v-icon
            :style="$route.query.link === 'windows' && 'color:#1eafc1'"
            :class="
              $route.path !== '/'
                ? 'icon-windows-download'
                : 'icon-windows-download-main'
            "
          >
            fab fa-windows</v-icon
          >
        </a>
        <a href="/download?link=apple">
          <v-icon
            :style="$route.query.link === 'apple' && 'color:#1eafc1'"
            :class="$route.path !== '/' ? 'icon-apple' : 'icon-apple-main'"
          >
            fab fa-apple</v-icon
          ></a
        >
      </div>
      <div :class="$route.path !== '/' ? 'login' : 'logo-user'">
        <user-profile-menu v-if="user" />
        <div class="login-navbar" v-else>
          <p
            @click="$router.push('/auth?state=signup&from=web')"
            class="register-header"
          >
            Register
          </p>
          <v-btn
            elevation="0"
            to="/auth?state=signIn"
            color="primary"
            style="font-size: 12px"
            exact
            class="loginbtn"
          >
            Join in RendezVu
          </v-btn>
        </div>
      </div>
    </div>

    <div v-if="isOpen === true" class="mobile-menu-list">
      <div class="login">
        <user-profile-menu v-if="user" />
        <v-btn @click="toggleMenu(false)" v-else text to="/auth" exact>
          Login
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import { SET_MOBILE_CONTACT } from "@/store/ui";

import NavBar from "./NavBar";
import UserProfileMenu from "./PageProfileMenu";

export default {
  watch: {
    $route() {
      this.toggleMenu(false);
    },
  },
  components: {
    UserProfileMenu,
    NavBar,
  },
  data: () => ({
    isOpen: false,
    isMobile: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      transparent: (state) => state.ui.transparentHeader,
      isMobileContact: (state) => state.ui.mobileContact,
    }),
  },
  mounted() {
    var isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if (isAndroid) {
      document.write(
        '<meta name="viewport" content="width=device-width,height=' +
          window.innerHeight +
          ', initial-scale=1.0">'
      );
    }
    if (window.screen.width <= 767) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    ...mapMutations("ui", {
      changeMobileContact: SET_MOBILE_CONTACT,
    }),
    toggleMenu(state = null) {
      if (state !== null) {
        this.isOpen = state;
        return;
      }
      this.isOpen = !this.isOpen;
    },
    navigate(path) {
      this.$router.push({ path: path });
      this.isOpen = false;
    },
    showContact() {
      this.changeMobileContact(true);
      console.log(this.isMobileContact);
    },
    changeColor(value) {
      console.log(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-bottom {
  -webkit-animation: slide-bottom 1s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    alternate both;
  animation: slide-bottom 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) alternate
    both;
}
.wrapper {
  height: 12vh;
  width: 100%;
  padding: 0.125rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.wrapper.transparent {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.site-logo {
  display: flex;
}
.login-navbar {
  display: flex;
  align-items: center;
}
.logo-user {
  color: white;
  font-weight: 500;
  line-height: 100%;
  font-size: 11px;
  margin-right: 30px;
  margin-left: 10px;
}
.login {
  font-weight: 500;
  line-height: 100%;
  font-size: 11px;
  margin-right: 26px;
  margin-left: 10px;
  color: black !important;
}
.loginbtn {
  font-size: 0.8rem !important;
  text-transform: none !important;
  letter-spacing: -0.5px;
  height: 7vh !important;
  width: 11vw !important;
  @media screen and (min-width: 1600px) {
    font-size: 0.9rem !important;
  }
  @media screen and (min-width: 1800px) {
    font-size: 1rem !important;
  }
  @media screen and (min-width: 2100px) {
    font-size: 1.3rem !important;
  }
  @media screen and (min-width: 2800px) {
    font-size: 1.6rem !important;
  }
}
.navigation-main {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  z-index: 123;

  a {
    text-decoration: none;
    font-family: Poppins;
    color: white;
    &.v-btn--active {
      &:before {
        opacity: 0 !important;
      }
      font-weight: 700;
    }
  }
}

.navigation {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  z-index: 123;
  a {
    text-decoration: none;
    font-family: Poppins;
    &.v-btn--active {
      &:before {
        opacity: 0 !important;
      }
      font-weight: 700;
    }
  }
}
.icon-div-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-apple {
  margin-right: 1.5vw;
  margin-left: 1.5vw;
  font-size: 24px;
  color: black;
  @media screen and (min-width: 1800px) {
    font-size: 1.8rem !important;
  }
  @media screen and (min-width: 2100px) {
    font-size: 2.1rem !important;
  }
  @media screen and (min-width: 2800px) {
    font-size: 2.8rem !important;
  }
}
.icon-apple-main {
  margin-right: 1.5vw;
  margin-left: 1.5vw;
  font-size: 24px;
  color: rgb(255, 255, 255);
  @media screen and (min-width: 1800px) {
    font-size: 1.8rem !important;
  }
  @media screen and (min-width: 2100px) {
    font-size: 2.1rem !important;
  }
  @media screen and (min-width: 2800px) {
    font-size: 2.8rem !important;
  }
}
.icon-windows-download {
  color: black;
  font-size: 24px;
  @media screen and (min-width: 1800px) {
    font-size: 1.8rem !important;
  }
  @media screen and (min-width: 2100px) {
    font-size: 2.1rem !important;
  }
  @media screen and (min-width: 2800px) {
    font-size: 2.8rem !important;
  }
}
.icon-windows-download-main {
  color: rgb(255, 255, 255);
  font-size: 24px;
  @media screen and (min-width: 1800px) {
    font-size: 1.8rem !important;
  }
  @media screen and (min-width: 2100px) {
    font-size: 2.1rem !important;
  }
  @media screen and (min-width: 2800px) {
    font-size: 2.8rem !important;
  }
}
.icon-play {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 24px;
  // background-size: 25px 25px;
  @media screen and (min-width: 1800px) {
    //  width: 26px;
  }
}
.icon-online-play {
  width: 25px;
  @media screen and (min-width: 1800px) {
    width: 30px;
  }
  @media screen and (min-width: 2100px) {
    width: 35px;
  }
  @media screen and (min-width: 2800px) {
    width: 45px;
  }
}

.activeClass {
  color: #1eafc1 !important;
}
.register-header {
  margin-left: 2vw;
  margin-right: 1vw;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 100%;
  text-align: center;
  color: #1eafc1 !important;
  cursor: pointer;
  @media screen and (min-width: 1600px) {
    font-size: 0.9rem;
  }
  @media screen and (min-width: 1800px) {
    font-size: 1rem !important;
  }
  @media screen and (min-width: 2100px) {
    font-size: 1.3rem !important;
  }
  @media screen and (min-width: 2800px) {
    font-size: 1.6rem !important;
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
    transition: 1s;
  }
  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 0.5;
    transition: 1s;
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    transform: 1;
    transition: 1s;
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
    transition: 1s;
  }
  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 0.5;
    transition: 1s;
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 1;
    transition: 1s;
  }
}
.profile {
  text-transform: uppercase;
  font-weight: bold;
}
.mobile-menu {
  display: none;
}
.mobile-menu-main {
  display: none;
}
@media screen and(max-width:1024px) and (orientation: portrait) {
  .navigation-main {
    display: none;
  }
  .navigation {
    display: none;
  }
  .v-btn > .v-btn__content .v-icon {
    color: white;
  }
  .icon-user-mobile {
    color: #1eafc1;
    font-size: 22px;
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .mobile-menu {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 123;
    //  padding-left: 10px;
    // padding-right: 15px;
  }
  .mobile-menu-main {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 123;
    //  padding-left: 10px;
    // padding-right: 15px;
  }
  .mobile-menu--icon {
    width: 150px !important;
    background: none !important;
    @media screen and (max-width: 950px) {
      width: 110px !important;
    }
  }
  .mobile-menu--user {
    width: 110px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 950px) {
      // width: 100px !important;
    }
  }
  .mobile-menu-img {
    width: 90px;
  }
  .btn-menu {
    width: 45px !important;
  }
  .icon-profile-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/icon-circle-mobile.svg");
    background-size: cover;
    height: 70px;
    width: 70px;
    // margin-left: 30px;
    // margin-right: 10px;
  }
  .icon-user-mobile {
    color: #1eafc1;
    font-size: 27px !important;
  }
  .icon-logo-navbar {
    width: 118px !important;
    margin-right: 16px;
    @media screen and (max-width: 600px) {
      width: 70px !important;
    }
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .icon-apple {
    margin-right: 1vw;
    margin-left: 1vw;
    font-size: 22px;
  }
  .icon-apple-main {
    margin-right: 1vw;
    margin-left: 1vw;
    font-size: 22px;
  }
  .icon-windows-download {
    font-size: 22px;
  }
  .icon-windows-download-main {
    font-size: 22px;
  }
  .icon-online-play {
    width: 20px;
  }
  .register-header {
    font-size: 11px;
    letter-spacing: 0.1px;
  }
  .loginbtn {
    width: 120px !important;
  }
  .navigation-main {
    margin-left: 15px;
  }

  .navigation {
    margin-left: 15px;
    margin-right: 10px;
  }
  .logo-user {
    font-size: 11px;
    margin-right: 30px !important;
    margin-left: 5px;
    @media screen and (max-device-width: 800px) {
      margin-right: 0px !important;
    }
  }
  .login {
    font-size: 11px;
    margin-right: 0px;
    margin-left: 5px;
  }
  .loginbtn {
    font-size: 11px !important;
    letter-spacing: 0.1px;
  }

  @media screen and (max-width: 1024px) {
    .icon-apple {
      font-size: 20px;
      @media screen and (max-device-width: 900px) {
        font-size: 15px;
      }
    }
    .icon-apple-main {
      font-size: 20px;
      @media screen and (max-device-width: 900px) {
        font-size: 15px;
      }
    }
    .icon-windows-download {
      font-size: 20px;
      @media screen and (max-device-width: 900px) {
        font-size: 15px;
      }
    }
    .icon-windows-download-main {
      font-size: 20px;
      @media screen and (max-device-width: 900px) {
        font-size: 15px;
      }
    }
    .icon-online-play {
      width: 19px;
      @media screen and (max-device-width: 900px) {
        width: 15px;
      }
    }
    .register-header {
      font-size: 11px;
      letter-spacing: 0.1px;
      @media screen and (max-height: 580px) {
        margin-right: 10px;
        @media screen and (max-height: 400px) {
          margin-right: 5px;
          margin-left: 0px;
          font-size: 9px;
        }
      }
    }
    .loginbtn {
      width: 120px !important;
      @media screen and (max-device-width: 1000px) {
        width: 110px !important;
        height: 40px !important;
      }
      @media screen and (max-height: 580px) {
        @media screen and (max-height: 400px) {
          width: 90px !important;
          height: 30px !important;
          font-size: 9px !important;
        }
      }
    }
    .navigation-main {
      margin-left: 15px;
      @media screen and (max-device-width: 800px) {
        margin-left: 0px;
      }
    }

    .navigation {
      margin-left: 15px;
      margin-right: 4px;
      @media screen and (max-device-width: 800px) {
        margin-left: 0px;
      }
    }

    .login {
      font-size: 11px;
      margin-right: 17px;
      margin-left: 5px;
    }
  }
  @media screen and (max-height: 580px) {
    
    .logo-user {
      margin-right: 25px !important;
    }
    @media screen and (max-height: 400px) {
      .spacer {
      flex-grow: 0 !important;
    }
      .logo-user {
        margin-left: 5px;
        @media screen and (max-width: 740px) {
          margin-right: 15px !important;
        }
      }
      .navigation-main {
        padding-left: 0px;
      }
      .navigation {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
      }
    }
  }
}
@media screen and(max-width:580px) {
  .mobile-menu {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 123;
    padding-left: 10px;
    padding-right: 10px;
  }
  .mobile-menu-main {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 123;
    padding-left: 10px;
    padding-right: 10px;
  }
  .mobile-menu--icon {
    // width: 70px !important;
    width: 40px !important;
    background: none !important;
    // margin-right: 5px;
  }
  .mobile-menu--user {
    width: 40px !important;
  }
  .mobile-menu-img {
    width: 60px;
    z-index: 88888;
  }

  .theme--light.v-btn.v-btn--icon {
    color: rgb(0 0 0 / 0%);
  }
  .theme--light.v-btn {
    color: rgba(0, 0, 0, 0);
  }

  .wrapper {
    background-color: white !important;
    height: 70px;
    padding: 0.125rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .icon-profile-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/icon-circle-mobile.svg");
    background-size: cover;
    height: 40px;
    width: 40px;
    // margin-left: 15px;
    // margin-right: 10px;
  }

  .contact-mobile {
    width: 100vw !important;
    height: calc(100vh - 70px);
    margin-top: 70px;
    background: radial-gradient(
      circle at left,
      rgb(238, 216, 225) 0%,
      rgb(245, 219, 230) 0%,
      rgb(229, 244, 255) 30%,
      rgb(229, 244, 255) 30%,
      rgb(229, 244, 255) 30%,
      rgb(229, 244, 255) 60%,
      rgb(229, 244, 255) 60%,
      rgb(249, 217, 231) 90%,
      rgb(247, 214, 228) 90%
    );
    z-index: 0 !important;
  }
  .v-menu__content {
    background-color: white;
    position: fixed;
    display: inline-block;
    min-width: 100vw !important;
    top: 0 !important;
    contain: content;
    height: 100vh !important;
    left: 0px;
    border: none;
    border-radius: 0px;
    z-index: 1 !important;
  }
  .btn-menu {
    width: 30px !important;
  }
  btn {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .icon-logo-navbar {
    width: 70px !important;
    margin-right: 4px;
  }
}
@media screen and (min-width: 3800px) {
  .wrapper {
    width: 100%;
    padding: 0.125rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .icon-apple {
    margin-right: 1.2vw;
    margin-left: 1.2vw;
  
   font-size: 4rem !important;
  
  }
  .icon-apple-main {
    margin-right: 1.2vw;
    margin-left: 1.2vw;
   
    font-size: 4rem !important;
  }
  .icon-windows-download {
    font-size: 4rem !important;
  }
  .icon-windows-download-main {
    font-size: 4rem !important;
  }
  .icon-online-play {
    width: 70px;
  }
  .icon-play {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    // background-size: 25px 25px;
  }
  .loginbtn {
    width: 330px !important;
    height: 120px !important;
     font-size: 1.8rem !important;
  }
  .register-header {
    font-size: 1.8rem !important;
    line-height: 100%;
  }
  .logo-user {

  margin-right: 50px;
  margin-left: 10px;
}
.login {
 
  margin-right: 46px;
  margin-left: 10px;

}
}
</style>

<style >
@media screen and (max-width: 1024px) {
  .v-list-item {
    padding: 0 10px !important;
  }

  .theme--light.v-btn.v-btn--icon {
    color: rgb(0 0 0 / 0%) !important;
  }
  /* .theme--light.v-btn {
    color: rgba(0, 0, 0, 0) !important;
  } */
}
@media screen and (max-width: 850px) {
  .v-list-item {
    padding: 0 7px !important;
  }
}
@media screen and (max-height: 580px) {
  .v-list-item {
    padding: 0 6px !important;
  }
}
</style>
