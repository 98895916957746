<template>
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 13.1739C12.3379 13.1739 16 13.8783 16 16.5987C16 19.3191 12.3144 20 8 20C3.66208 20 0 19.2945 0 16.5752C0 13.8548 3.68451 13.1739 8 13.1739ZM8 0C10.9382 0 13.2935 2.35435 13.2935 5.29141C13.2935 8.22848 10.9382 10.5828 8 10.5828C5.06179 10.5828 2.70653 8.22848 2.70653 5.29141C2.70653 2.35435 5.06179 0 8 0Z"
      fill="#ADC7CC"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {};
</script>
