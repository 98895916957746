<template>
  <div class="feedback">
    <div class="menu-nav-mobile">
      <a href="/">
        <div>HOME</div>
      </a>
      <div class="upper-case">What is RendezVu</div>
      <div class="upper-case">Use cases</div>
      <div class="upper-case">Coming soon</div>
      <div class="upper-case">Technology</div>
      <div class="upper-case">Get in touch</div>
    </div>

    <div class="feedback-form">
      <div class="contact-content">
        <div class="send-contact">
          <div class="contact-title">
            <h1>Get in touch</h1>
            <p>Fill out the form or call us</p>
          </div>
          <v-btn elevation="0" class="to-feedback-form" color="primary" exact>
            Get in touch
          </v-btn>
        </div>
        <div class="contact">
          <div class="contact-column">
            <div class="first-contact">
              <img src="../../../assets/icon-phone.svg" alt="" />
              <div>
                Want to talk?<br />
                <span style="color: black">1 (844) 741-4210</span>
              </div>
            </div>
            <div class="first-contact">
              <img src="../../../assets/icon-rupor.svg" alt="" />
              <div>
                Press<br />
                <span>media@rendezvu.com</span>
              </div>
            </div>
          </div>
          <div class="first-contact">
            <img src="../../../assets/icon-map.svg" alt="" />
            <div>
              Office<br />
              <span>7, Oxford Street, London</span>
            </div>
          </div>
        </div>
        <div class="btn-get-in-touch-mobile">
          <v-btn elevation="0" class="send-get-in-touch" color="primary" exact>
            Get in touch
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/plugins/request";
import { Constants } from "@/config/constants";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SET_MOBILE_CONTACT } from "@/store/ui";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      feedbackForm: {
        username: "",
        email: "",
        phone: "",
        message: "",
      },
      isShow: true,
      items: Constants.NavMenuItems,

      emailRules: [
        (value) => !!value || "Required.",
        (value) =>
          (value &&
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              value
            )) ||
          "Please enter a valid email",
      ],
      phoneRules: [
        (value) => !!value || "Required.",
        (value) =>
          (value && /^[\d]{11}$/.test(value)) || "Please enter only number",
      ],
    };
  },
  computed: {
    formValidated() {
      return (
        this.feedbackForm.email &&
        this.feedbackForm.username &&
        this.feedbackForm.phone &&
        this.feedbackForm.message
      );
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
  },
  methods: {
    ...mapMutations("ui", {
      changeMobileContact: SET_MOBILE_CONTACT,
    }),
    closeForm() {
      this.changeMobileContact(false);
    },

    async sendFeedbackForm() {
      const response = await request({
        method: "POST",
        url: "/users/sendFeedback",
        body: JSON.stringify(this.feedbackForm),
      });
      if (response.status === 200) {
        this.isShow = false;
        setTimeout(() => {
          this.feedbackForm = {
            username: "",
            email: "",
            phone: "",
            message: "",
          };
          this.isShow = true;
          this.changeIsFeedback(false);
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1366px) {
  .feedback {
    display: none;
  }
}
@media screen and (max-width: 1024px) and (orientation: portrait) {
.feedback {
  width: 100vw;
  opacity: 1;
  color: rgb(0, 0, 0);
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: url("../../../assets/contact-back.svg");
  background-size: cover;
  // radial-gradient(
  //   circle at left,
  //   rgb(238, 216, 225) 0%,
  //   rgb(245, 219, 230) 0%,
  //   rgb(229, 244, 255) 30%,
  //   rgb(229, 244, 255) 30%,
  //   rgb(229, 244, 255) 30%,
  //   rgb(229, 244, 255) 60%,
  //   rgb(229, 244, 255) 60%,
  //   rgb(249, 217, 231) 90%,
  //   rgb(247, 214, 228) 90%
  // );
  padding-left: 10px;
}

.feedback-form {
  display: flex;
  text-align: start;
  border-radius: 5px;
  position: relative;
  padding-bottom: 10vh;
}

.contact-content {
  display: flex;
  flex-direction: column;
  padding-left: 4vw;
  padding-top: 5vh;
  opacity: 1;
}

.contact {
  display: flex;
  flex-direction: column;
  // align-items: center;
  flex-wrap: wrap;
  div {
    font-size: 16px;
    color: #99b4b9;
  }
}
span {
  font-weight: 500;
  font-size: 16px;

  color: #185fbf;
  opacity: 0.9;
}
.first-contact {
  // width: 100vw;
  display: flex;
  align-items: center;
  margin-bottom: 3vh;
  img {
    margin-right: 4vw;
    width: 15vw;
  }
}
.btn-feedback {
  margin-top: 3vh;
}
.profile-icon {
  margin-right: 0.5vw;
  height: 20px !important;
}
.send-feedback {
  height: 8vh !important;
  width: 18.5vw;
}
.send-get-in-touch {
  width: 87vw;
  height: 60px !important;
}
.v-application a {
  color: rgba(255, 255, 255, 0) !important;
}
.contact-column {
  display: flex;
  flex-direction: column;
}
.mobile-list-item {
  padding-top: 40px;
  // padding-bottom: 20vh;
  padding-left: 5vw;
  font-size: 18px;
  font-weight: 500;
  color: black !important;
}
.upper-case {
  text-transform: uppercase;
  font-size: 22px;
  line-height: 270%;
  @media screen and (max-height: 860px) {
    line-height: 250%;
  }
  @media screen and (max-height: 800px) {
    line-height: 230%;
    font-size: 20px;
  }
  @media screen and (max-height: 720px) {
    line-height: 210%;
    font-size: 18px;
  }
}
.v-application a[data-v-14f22ceb] {
  color: #1eafc1 !important;
  text-decoration: none;
  font-size: 22px;
  line-height: 270%;
  @media screen and (max-height: 860px) {
    line-height: 250%;
  }
  @media screen and (max-height: 800px) {
    line-height: 230%;
    font-size: 20px;
  }
  @media screen and (max-height: 720px) {
    line-height: 210%;
    font-size: 18px;
  }
}
.menu-nav-mobile {
  // padding-top: 20px;
  padding-left: 30px;
}
.send-contact {
  display: none;
}
// .btn-get-in-touch-mobile {
//     display: none;
//   }
@media screen and (min-width: 581px) and (max-width: 1024px) {
  .btn-get-in-touch-mobile {
    display: none;
  }
  .feedback {
    height: calc(100vh - 12vh);
    background: url("../../../assets/contact-back-tablet.svg");
    background-size: cover;
  }
  .feedback-form {
    // padding-top: 27vh;
  }

  .contact-content {
    padding-left: 4vw;
    padding-top: 5vh;
  }

  .contact {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    padding-right: 10px;
    div {
      font-size: 18px;
      color: #99b4b9;
    }
  }
  span {
    font-size: 18px;
  }
  .first-contact {
    margin-bottom: 3vh;
    img {
      margin-right: 4vw;
      width: 8vw;
    }
  }
  .btn-feedback {
    margin-top: 3vh;
  }
  .profile-icon {
    margin-right: 0.5vw;
    height: 20px !important;
  }
  .send-feedback {
    height: 8vh !important;
    width: 18.5vw;
  }

  .mobile-list-item {
    font-size: 28px;
    margin-left: 25px;
    font-weight: 500;
    color: black !important;
  }
  // .btn-get-in-touch-mobile {
  //   display: none;
  // }
  .contact-column {
    margin-right: 40px;
    width: 45vw;
  }
  .send-contact {
    display: flex;
    align-items: stretch;
    margin-bottom: 10px;
  }
  .to-feedback-form {
    display: flex;
    width: 45vw;
    height: 60px !important;
    margin-right: 20px;
  }
  .contact-title {
    width: 42vw;
    text-align: start;
    h1 {
      line-height: 2;
      font-size: 24px;
      letter-spacing: 0.1rem;
    }
    p {
      font-size: 13px;
      line-height: 100%;
      color: #99b4b9;
      margin-bottom: 5vh;
    }
  }
}
}
</style>
