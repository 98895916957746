<template>
  <div class="feedback">
    <div class="common-feedback">
      <feedback-form
        v-show="isFeedback"
        class="feedback-component animonContact"
      />
    </div>
  </div>
</template>

<script>
import request from "@/plugins/request";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SET_FEEDBACK } from "@/store/ui";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import FeedbackForm from "./FeedbackForm.vue";
import { animon } from "animon";

export default {
  data() {
    return {};
  },
  components: {
    FeedbackForm,
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.changeIsFeedback(true);

    animon(".animonContact");
  },
  computed: {
    ...mapState({
      isFeedback: (state) => state.ui.feedback,
    }),
  },
  methods: {
    ...mapMutations("ui", {
      changeIsFeedback: SET_FEEDBACK,
    }),
    async sendFeedbackForm() {
      const response = await request({
        method: "POST",
        url: "/users/sendFeedback",
        body: JSON.stringify(this.feedbackForm),
      });
      if (response.status === 200) {
        this.isShow = false;
        setTimeout(() => {
          this.feedbackForm = {
            username: "",
            email: "",
            phone: "",
            message: "",
          };
          this.isShow = true;
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .feedback {
    width: 100vw;
    height: 100vh;
    opacity: 1;
    color: rgb(0, 0, 0);
    // margin-top: -12vh;
  }
  .common-feedback {
    display: flex;
    width: 100vw;
    align-items: flex-end;
    height: 100vh;
    background-image: url("../../assets/Hero.jpg");
    background-size: cover;
    opacity: 1;
    position: relative;
    background-position: center;
    color: rgb(0, 0, 0);
    z-index: 1;
  }
  .feedback-component {
    z-index: 888;
    display: flex;
    width: 80vw;
    height: 70vh;
    // max-height: 70vh;
    bottom: 0;
    // position: absolute;
    // margin-top: 30vh;
    margin-left: auto;
    margin-right: auto;
    z-index: 123;
  }

  .animonContact {
    opacity: 1;
    will-change: opacity;
    transition: opacity 1000ms 1000ms cubic-bezier(0.5, 1, 0.89, 1);
  }
  .animonContact.is-visible {
    opacity: 1;
  }

@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  
  .common-feedback {
    background-image: url("../../assets/Hero-tablet.jpg");
    background-size: cover;
  }
  .feedback-component {
    width: 100vw;
    // position: absolute;
    height: 65vh;
  }
}

@media screen and (min-width: 3800px) {
  .feedback-component {
    width: 60vw;
    height: 55vh;
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .feedback {
    display: block;
    min-width: 100vw;
    height: 100vh;
    opacity: 1;
    background-color: white;
    //        background: linear-gradient(
    // rgba(114, 112, 112, 0.1) 10%,
    // rgba(97, 97, 97, 0) 80% );
  }
  .common-feedback {
    display: flex;
    min-width: 100vw;
    flex-direction: column;
    height: calc(100vh- 70px);
    opacity: 1;
    color: rgb(0, 0, 0);
    z-index: 1;
    background-image: none;
    background-color: white;
    //
  }
}
@media screen and (max-height: 580px) and (orientation: landscape) {
.feedback-component {
    display: none;
  }
}
</style>

