<template>
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.98565 0C10.8739 0 13.1978 2.29405 13.1978 5.1262V6.58288C14.8189 7.09361 16 8.57483 16 10.344V16.034C16 18.2242 14.2011 20 11.9833 20H4.01769C1.79892 20 0 18.2242 0 16.034V10.344C0 8.57483 1.18207 7.09361 2.80215 6.58288V5.1262C2.81172 2.29405 5.13568 0 7.98565 0ZM7.99522 11.8151C7.53616 11.8151 7.16318 12.1832 7.16318 12.6364V14.7322C7.16318 15.1948 7.53616 15.563 7.99522 15.563C8.46384 15.563 8.83682 15.1948 8.83682 14.7322V12.6364C8.83682 12.1832 8.46384 11.8151 7.99522 11.8151ZM8.00478 1.65209C6.06336 1.65209 4.48536 3.20034 4.47579 5.10732V6.37802H11.5242V5.1262C11.5242 3.20978 9.9462 1.65209 8.00478 1.65209Z"
      fill="#ADC7CC"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {};
</script>
