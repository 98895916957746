<template>
  <div>
    <v-card
      flat
      class="auth-form auth-form-setpassword pa-10 align-self-center align-center"
    >
      <v-img
        :src="require('@/assets/login-logo.png')"
        class="login-logo align-center mb-10"
        contain
      />

      <v-form ref="form">
        <v-row>
          <v-col cols="12" class="fields">
            <v-text-field
              :label="form.username"
              v-model="form.username"
              disabled
              outlined
            />
            <v-text-field
              :label="form.oldPassword"
              type="password"
              v-model="form.oldPassword"
              outlined
              disabled
            />
            <v-text-field
              label="New Password"
              :type="hidePassword ? 'password' : 'text'"
              v-model="form.newPassword"
              outlined
              requried
              :rules="passwordRules"
              :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
              @input="newPasswordInput"
              @click:append="hidePassword = !hidePassword"
            />
            <v-text-field
              ref="confirmPassword"
              label="Confirm Password"
              :type="hideConfirm ? 'password' : 'text'"
              v-model="form.confirmPassword"
              outlined
              requried
              :rules="passwordConfirmRules"
              :append-icon="hideConfirm ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="hideConfirm = !hideConfirm"
            />
            <v-btn
              :disabled="!submitEnable()"
              width="100%"
              @click="changePassword"
              color="primary"
            >
              Set Password
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <loader-component v-if="isUpdating"></loader-component>
  </div>
</template>

<script>
import awsAuth from "../../cognito/aws-auth";
import LoaderComponent from "@/components/OverlayComponent";
import { mapActions } from "vuex";

export default {
  components: {
    LoaderComponent,
  },
  name: "SetPassword",
  props: {},
  data() {
    return {
      hidePassword: true,
      hideConfirm: true,
      isUpdating: false,
      notify: false,
      form: {
        username: "",
        oldPassword: "",
        newPassword: "",
      },
      passwordRules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 6) || "Min 6 characters",
      ],
      passwordConfirmRules: [
        (value) => !!value || "Required.",
        (value) => value === this.form.newPassword || "Password mismatch",
      ],
    };
  },
  mounted() {
    this.form.username = this.$route.query.username
      ? this.$route.query.username
      : "";
    this.form.oldPassword = this.$route.query.pass
      ? this.$route.query.pass
      : "";
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    async signIn(username, password) {
      const self = this;
      self.overlay = true;
      await self.$store
        .dispatch("auth/login", { username, password })
        .catch((error) => {
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: error.message,
          });
        });
    },
    changePassword() {
      this.isUpdating = true;

      awsAuth
        .editTemporaryPassword(
          this.form.username,
          this.form.oldPassword,
          this.form.newPassword
        )
        .then(() => {
          this.signIn(this.form.username, this.form.newPassword)
            .then(() => {
              this.snackbar({
                color: "success",
                icon: "mdi-check-circle",
                title: "Success",
                text: "Welcome to RendezVu",
              });
              let attr = {email_verified: 'true'};
              awsAuth.adminUpdateAttributes(this.form.username, attr)
                .then(() => {
                  this.$router.push("/profile");
              })
            })
            .catch((error) => {
              this.snackbar({
                color: "error",
                icon: "mdi-alert-circle-outline",
                title: "Error",
                text: error.message,
              });
            });
          // let attr = {
          //   email_verified: "true",
          // };
          // awsAuth
          //   .adminUpdateAttributes(this.form.username, attr)
          //   .then(() => {
          //   })
          //   .catch((error) => {
          //     this.notify = true;
          //     this.notification = `ERROR, ${error.message}!`;
          //     this.notificationColor = "yellow darken-4";
          //     this.isUpdating = false;
          //   });
        })
        .catch((error) => {
          this.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: error.message,
          });
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
    newPasswordInput() {
      if (this.form.confirmPassword) {
        this.$refs.confirmPassword.validate();
      }
    },
    submitEnable() {
      return (
        this.form.oldPassword?.length > 0 &&
        this.form.newPassword?.length > 5 &&
        this.form.confirmPassword === this.form.newPassword
      );
    },

    closeNotify() {
      this.notify = false;
    },
  },
};
</script>

<style scoped>
.login-logo {
  margin: 0 auto;
  width: 200px;
}
.auth-form-setpassword {
  background: transparent;
  margin: 0 auto;
  width: 460px;
  text-align: center;
}
</style>
