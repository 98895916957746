<template>
  <div class="wrapper-field">
    <div class="login-form">
      <img class="icon-logo" src="../../assets/icon-logo-navbar.svg" alt="" />
      <div class="form-header">
        <span class="text-signin">Enter the world of Rendezvu</span>
      </div>
      <div class="form-error">{{ notification }}</div>
      <v-col class="log-input">
        <v-text-field
          color="#1eafc1 !important"
          label="Username"
          v-model="form.username"
          outlined
          :rules="rules"
          hide-details="auto"
          height="60px"
          prepend-inner-icon="$vuetify.icons.userIcon"
          @input="usernameInput"
        >
          <template v-slot:prepend-inner>
            <img
              class="profile-icon"
              height="20px"
              src="../../assets/icon-people-profile.svg"
              alt=""
            />
          </template>
        </v-text-field>
      </v-col>
      <v-col class="log-input">
        <v-text-field
          label="Password"
          :type="hidePassword ? 'password' : 'text'"
          v-model="form.password"
          :rules="rules"
          hide-details="auto"
          outlined
          height="60px"
          :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="hidePassword = !hidePassword"
          @keyup.enter="signIn"
          ><template v-slot:prepend-inner>
            <img
              class="profile-icon"
              height="20px"
              src="../../assets/icon-lock.svg"
              alt=""
            />
          </template>
        </v-text-field>
      </v-col>

      <div class="sign-option">
        <p class="text-link" @click="onToggle('restore')">
          <span>Forgot Password</span>
        </p>
      </div>
      <v-btn
        class="btn-login"
        block
        :loading="overlay"
        elevation="0"
        @click="signIn"
        :disabled="!form.username || form.password.length < 6 || overlay"
        height="60px"
        color="primary"
      >
        Login
      </v-btn>
      <div class="sign-option">
        <p @click="onToggle('signup')" class="text-link">
          <span class="bold-txt">Register now</span>
        </p>
      </div>

      <!--<v-overlay :absolute="absolute" :value="overlay"> </v-overlay>-->
      <br />
      <v-progress-linear v-if="overlay" indeterminate color="gray accent-4">
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
//import { Auth } from 'aws-amplify'
// import IconLogo from "@/components/icons/IconLogo";
import { mapActions } from "vuex";

export default {
  name: "signin",
  props: {
    onToggle: {
      type: Function,
    },
  },
  components: {
    // IconLogo,
  },
  data() {
    return {
      hidePassword: true,
      absolute: true,
      overlay: false,
      multiLine: true,
      top: false,
      left: false,
      notification: "Enter login and password",
      timeout: 3500,
      form: {
        username: "",
        password: "",
      },
      rules: [
        // (value) => !!value || "Required.",
        // (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
    };
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    async signIn() {
      const { username, password } = this.form;
      let self = this;
      self.overlay = true;
      self.$store
        .dispatch("auth/login", { username, password })
        .then(() => {
          self.notification = `Welcome to RendezVu!`;

          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: self.notification,
          });
          setTimeout(self.redirect, 2300);
        })
        .catch((error) => {
          self.overlay = false;
          self.notification = `ERROR! ${error.message}`;
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: error.message,
          });
        });
    },
    usernameInput(value) {
      this.form.username = value.replaceAll(" ", "_");
    },
    redirect() {
      self.overlay = false;
      this.$router.push(this.$route.query.redirect || "/profile");
    },
  },
};
</script>

<style scoped>
.wrapper-field {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  width: 40vw;
  border: 1px solid #e9eeef;
  border-radius: 4px;
  margin-top: 10px;
}

.login-form {
  /* background: white; */
  /* padding-top: 30px; */
}
.login-form .form-header {
  /* padding: 15px; */
}
.text-signin {
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  color: #252733;
  letter-spacing: 1.2px;
}
.form-error {
  font-size: 13px;
  line-height: 200%;
  margin-bottom: 10px;
  opacity: 0.6;
}
.profile-icon {
  margin-right: 0.5vw;
}
.log-input {
  color: #242424;
  border-width: 5px;
  border-color: #000;
}
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  /* margin: 10px 0 20px; */
}
h2 span {
  background: #fff;
  padding: 0 10px;
  color: #000;
  font-size: 0.9em;
  font-weight: 450;
}
.sign-option {
  margin-top: 5px;
}
.log-google {
  display: none;
}
.icon-logo {
  margin: 20px 0px;
}
.icon-logo-signin {
  /* margin-top: 5px; */
}
.btn-login {
  margin-bottom: 15px;
}
.v-text-field--outlined.v-input--dense .v-label {
  color: #242424;
  /* top: 20px; */
}
.text-link {
  color: #1eafc1;
}

>>> .v-label {
  /* top: 50% !important;
  transform: translateY(-50%); */
}

>>> .v-text-field .v-input__prepend-inner {
  color: #242424;
  align-self: center;
}

>>> .v-icon__component {
  /* height: 20px;
  width: 20px; */
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #1eafc1;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #1eafc1 !important;
  color: white !important;
}

>>> .v-text-field--outlined legend {
  color: #242424;
  display: none;
}

>>> .v-input--is-focused label,
>>> .v-input--is-dirty label {
  color: #242424;
  display: none;
}

@media screen and (max-width: 580px) {
  .icon-logo {
    display: none;
  }
  .wrapper-field {
    width: 100vw;
    /* height: 70vh; */
    border: none;
  }
  .icon-logo-signin {
    display: none;
  }
  .login-form {
    /* padding: 8px 0px; */
    margin-top: 8vh;
  }
  .login-form .form-header {
    padding: 0 15px 0 15px;
  }
  .v-btn--block {
    margin-right: auto;
    margin-left: auto;
    min-width: 75vw !important;
    max-width: 75vw;
  }
  .profile-icon {
    margin-top: 3px;
    height: 20px !important;
    margin-right: 1vw;
  }
  .log-input {
    width: 80vw !important;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) {
  .wrapper-field {
    width: 60vw;
    border: 1px solid #e9eeef;
    border-radius: 4px;
    margin-top: 10px;
  }
  .btn-login {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 18px;
    width: 10px !important;
    height: 60px !important;
  }
  .v-btn--block {
    margin-right: 2.5%;
    margin-left: 2.5%;
    min-width: 95% !important;
    max-width: auto;
  }
  .log-input {
    width: 40vw !important;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 834px) {
    .v-btn--block {
      margin-right: 7%;
      margin-left: 7%;
      min-width: 86% !important;
    }
  }
  @media screen and (max-width: 820px) {
    .v-btn--block {
      margin-right: 6.5%;
      margin-left: 6.5%;
      min-width: 87% !important;
    }
  }
  @media screen and (max-width: 768px) {
    .wrapper-field {
      width: 60vw;
      height: 50vh;
    }
    .v-btn--block {
      margin-right: 9%;
      margin-left: 9%;
      min-width: 82% !important;
    }
    .profile-icon {
      margin-top: 7px;
      height: 20px !important;
      margin-right: 1vw;
    }
  }
  @media screen and (max-width: 700px) {
    .v-btn--block {
      margin-right: 19%;
      margin-left: 19%;
      min-width: 62% !important;
    }
  }
  @media screen and (max-width: 600px) {
    .wrapper-field {
      width: 65vw;
      height: 60vh;
    }
    .text-signin {
      font-size: 16px;
    }
    .v-btn--block {
      margin-right: 9%;
      margin-left: 9%;
      min-width: 82% !important;
    }
    .profile-icon {
      margin-top: 1px;
      height: 25px !important;
      margin-right: 1vw;
    }
  }
}
@media screen and (min-width: 3800px) {
  .wrapper-field {
    width: 30vw;
    padding-bottom: 50px;
  }

  .text-signin {
    font-weight: 600;
    font-size: 40px;
    line-height: 200%;
    text-align: center;
    color: #252733;
    letter-spacing: 1.2px;
  }
  .form-error {
    font-size: 28px;
    line-height: 200%;
    margin-bottom: 20px;
  }
  .profile-icon {
    padding-bottom: 10px;

    height: 40px !important;
  }

  h2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    font-size: 64px;
    /* margin: 10px 0 20px; */
  }
  h2 span {
    background: #fff;
    padding: 0 10px;
    color: #000;
    font-size: 44px;
    font-weight: 450;
  }
  .sign-option {
    margin-top: 15px;
  }
  .icon-logo-signin {
    width: 125px !important;
    margin-top: 100px;
  }
  .btn-login {
    margin-bottom: 45px;
    margin-top: 45px;
    font-size: 24px;
    height: 100px !important;
  }
  .text-link {
    font-size: 24px;
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .v-btn--block {
    margin-right: 2.5%;
    margin-left: 2.5%;
    min-width: 95% !important;
  }
  @media screen and (max-device-width: 800px) {
    .v-btn--block {
      margin-right: 5%;
      margin-left: 5%;
      min-width: 90% !important;
    }
    .wrapper-field {
      height: 77vh;
    }
    .text-signin {
      font-size: 18px;
    }
    .form-error {
      font-size: 12px;
    }
    .icon-logo {
      width: 50px;
    }
    .btn-login {
      margin-bottom: 15px;
      margin-top: 15px;
      font-size: 16px;
    }
  }
  @media screen and (max-height: 580px) {
    .wrapper-field {
      /* height: 75vh; */
    }
    .log-input {
      width: 40vw !important;
      height: 70px;
    }
    .text-signin {
      font-size: 12px;
    }
    .form-error {
      font-size: 9px;
    }
    .icon-logo {
      width: 30px;
      margin: 0 0;
    }
  }
}
</style>
<style>
@media screen and (min-width: 3800px) {
  .v-application--is-ltr .v-text-field .v-label {
    padding-top: 10px;
    padding-left: 5px;
    height: 40px;
    font-size: 30px !important;
  }
}
@media screen and (max-height: 580px) and (orientation: landscape) {
}
</style>
