<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.66 0C18.06 0 20 1.92 20 5.33V14.67C20 18.06 18.07 20 14.67 20H5.33C1.92 20 0 18.06 0 14.67V5.33C0 1.92 1.92 0 5.33 0H14.66Z"
      fill="#EB5757"
    />
    <path
      d="M13.084 9.01371C13.1726 9.01371 13.253 9.05286 13.3166 9.11901C13.3758 9.18966 13.4056 9.27741 13.397 9.37011C13.397 9.40071 13.1601 12.4337 13.0248 13.7103C12.9401 14.4938 12.4413 14.9694 11.6931 14.9824C11.1177 14.9955 10.5554 15 10.0017 15C9.41383 15 8.83895 14.9955 8.28092 14.9824C7.55777 14.9649 7.05853 14.4807 6.97813 13.7103C6.83895 12.4292 6.6064 9.40071 6.60207 9.37011C6.59775 9.27741 6.62715 9.18966 6.68679 9.11901C6.74558 9.05286 6.8303 9.01371 6.91934 9.01371H13.084ZM10.9177 6C11.3106 6 11.6616 6.27765 11.7631 6.67364L11.8357 7.00214C11.8945 7.26989 12.1236 7.45934 12.3873 7.45934H13.6832C13.8561 7.45934 14 7.60468 14 7.78963V7.96063C14 8.14108 13.8561 8.29093 13.6832 8.29093H6.31727C6.14394 8.29093 6 8.14108 6 7.96063V7.78963C6 7.60468 6.14394 7.45934 6.31727 7.45934H7.61314C7.87638 7.45934 8.10547 7.26989 8.16469 7.00259L8.23255 6.69569C8.33802 6.27765 8.68511 6 9.08234 6H10.9177Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>
