<template>
  <svg
    width="25"
    height="20"
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0452 0C13.4514 0 13.7814 0.320611 13.7814 0.716383V3.72284C13.7814 4.20435 14.1925 4.60364 14.6881 4.60364C15.1958 4.60364 15.5948 4.20435 15.5948 3.72284V0.716383C15.5948 0.320611 15.9248 0 16.3322 0H20.2732C22.8844 0 25 2.0552 25 4.5919V7.55138C25 7.78626 24.9033 8.00939 24.734 8.17381C24.5648 8.33823 24.3351 8.43218 24.0933 8.43218C23.1987 8.43218 22.4734 9.13682 22.4734 9.99413C22.4734 10.8573 23.1903 11.5584 24.0764 11.5678C24.5757 11.5725 25 11.9107 25 12.3958V15.4081C25 17.9436 22.8844 20 20.2732 20H16.3322C15.9248 20 15.5948 19.6794 15.5948 19.2836V16.7469C15.5948 16.2537 15.1958 15.8661 14.6881 15.8661C14.1925 15.8661 13.7814 16.2537 13.7814 16.7469V19.2836C13.7814 19.6794 13.4514 20 13.0452 20H4.72679C2.12766 20 0 17.9448 0 15.4081V12.3958C0 11.9107 0.424323 11.5725 0.923598 11.5678C1.81093 11.5584 2.5266 10.8573 2.5266 9.99413C2.5266 9.16031 1.82544 8.52613 0.906673 8.52613C0.664894 8.52613 0.435203 8.43218 0.265957 8.26776C0.0967118 8.10335 0 7.88021 0 7.64533V4.60364C0 2.07164 2.13249 0 4.73888 0H13.0452ZM14.6881 7.1286C14.1925 7.1286 13.7814 7.52789 13.7814 8.00939V12.707C13.7814 13.1885 14.1925 13.5878 14.6881 13.5878C15.1958 13.5878 15.5948 13.1885 15.5948 12.707V8.00939C15.5948 7.52789 15.1958 7.1286 14.6881 7.1286Z"
      fill="#ADC7CC"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {};
</script>
