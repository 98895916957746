<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      errorMessage: null,
    };
  },
  mounted() {
    this.errorMessage = this.$route.meta.errorMessage;

    if (this.errorMessage) {
      this.showError(this.errorMessage);
    }

    if (this.$store.getters['auth/authenticated']) {
      this.$store.dispatch('auth/logout')
    }

    this.$router.push('/auth');
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),

    showError(error) {
      this.snackbar({
        color: "error",
        icon: "mdi-cancel",
        title: "Error",
        text: error,
        timeout: -1
      });
    }
  }
};
</script>
