export const Constants = {
  NavMenuItems: [
    {
      title: "HOME",
      path: "/",
      id: "home",
      index: 1,
    },
    {
      title: "WHAT IS RENDEZVU?",
      path: "/",
      id: "rendezvu",
      index: 2,
    },
    {
      title: "USE CASES",
      path: "/",
      id: "cases",
      index: 3,
    },
    {
      title: "COMING SOON",
      path: "/",
      id: "feature",
      index: 4,
    },
    {
      title: "TECHNOLOGY",
      path: "/",
      id: "technology",
      index: 5,
    },
    {
      title: "GET IN TOUCH",
      path: "/",
      id: "getInTouch",
      index: 6,
    }
  ],
  Servers: [
    "Whip Media",
    "Richey May",
    "Convergent",
    "Azure",
    "FNTECH",
    "MESAverse",
    "Rendezvu"
  ],
  UserTableHeaders: [
    {
      text: "Username",
      align: "left",
      sortable: true,
      value: "username",
    },
    {
      text: "Company",
      sortable: true,
      align: "left",
      value: "group",
    },
    {
      text: "Email",
      sortable: true,
      align: "left",
      value: "email",
    },
    {
      text: "Status",
      sortable: true,
      align: "left",
      value: "status",
    }
  ],
  CompanyUserTableHeaders: [
    {
      text: "Username",
      align: "left",
      sortable: true,
      value: "username",
    },
    {
      text: "Email",
      sortable: true,
      align: "left",
      value: "email",
    },
    {
      text: "Status",
      sortable: true,
      align: "left",
      value: "status",
    },
    {
      text: "Created",
      sortable: true,
      align: "left",
      value: "created",
    }
  ],
  CompanyTableHeaders: [
    {
      text: "",
      sortable: false,
      align: "left",
      value: "picture",
      width: "10%"
    },
    {
      text: "Company Name",
      sortable: false,
      align: "left",
      value: "username",
      width: "35%"
    },
    {
      text: "Participants",
      sortable: false,
      align: "left",
      value: "participants",
      width: "35%"
    },
    {
      text: "Access to servers",
      sortable: false,
      align: "left",
      width: "15%"
    }
  ],
  ServerTableHeaders: [
    {
      text: "Server Name",
      sortable: false,
      align: "left",
      value: "Caption",
      width: "25%"
    },
    {
      text: "URL",
      sortable: false,
      align: "left",
      value: "ServerAddress",
      width: "25%"
    },
    {
      text: "Status",
      sortable: false,
      align: "left",
      width: "10%",
      value: "status",
    },
    {
      text: "Online",
      sortable: false,
      align: "left",
      width: "10%",
      value: "users",
    },
    {
      text: "Access",
      sortable: false,
      align: "left",
      value: 'action',
      width: "10%"
    }
  ],
  ServerUserTableHeaders: [
    {
      text: "Username",
      sortable: true,
      align: "left",
      value: "username",
      width: "20%"
    },
    {
      text: "Company",
      sortable: true,
      align: "left",
      value: "group",
      width: "15%"
    },
    {
      text: "Access Level",
      sortable: false,
      align: "left",
      width: "20%"
    },
    {
      text: "Email",
      sortable: true,
      align: "left",
      value: "email",
      width: "15%"
    },
    {
      text: "Status",
      sortable: false,
      align: "left",
      width: "5%"
    },
    {
      text: "Servers",
      sortable: false,
      align: "left",
    }
  ],
  SortingOrder: [
    "Ascending",
    "Descending"
  ],
  AllRoles: [
    "Administrator",
    "Guest",
    "Presenter"
  ],
  Invitations: {
    joinmesa2022: "mesa",
    joinmesa2023: "mesa",
  },
  NodeEnv: process.env.NODE_ENV,
  ServerLogUsername: "user:Rendezvu9969@"
}
