<template>
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 18.8281V0.585938C14 0.262344 13.7482 0 13.4376 0H0.562365C0.25179 0 0 0.262344 0 0.585938V18.8281H0.0160461L0.0160511 19.4141L0.0160475 19.6538C0.0160453 19.7148 0.0160434 19.7687 0.0160461 20H3.95837V15.2476C3.95837 14.924 4.21016 14.6617 4.52074 14.6617H9.4793C9.78987 14.6617 10.0417 14.924 10.0417 15.2476V20H14V18.8281ZM11.5274 6.04754V3.17613C11.5274 2.85254 11.2756 2.5902 10.9651 2.5902H8.20918C7.8986 2.5902 7.64681 2.85254 7.64681 3.17613V6.04754C7.64681 6.37113 7.8986 6.63348 8.20918 6.63348H10.9651C11.2756 6.63348 11.5274 6.37113 11.5274 6.04754ZM5.79086 12.0884C6.10143 12.0884 6.35322 11.826 6.35322 11.5024V8.63102C6.35322 8.30742 6.10143 8.04508 5.79086 8.04508H3.03497C2.7244 8.04508 2.47261 8.30742 2.47261 8.63102V11.5024C2.47261 11.826 2.7244 12.0884 3.03497 12.0884H5.79086ZM5.79086 6.63348C6.10143 6.63348 6.35322 6.37113 6.35322 6.04754V3.17613C6.35322 2.85254 6.10143 2.5902 5.79086 2.5902H3.03497C2.7244 2.5902 2.47261 2.85254 2.47261 3.17613V6.04754C2.47261 6.37113 2.7244 6.63348 3.03497 6.63348H5.79086ZM7.64681 11.5024C7.64681 11.826 7.8986 12.0884 8.20918 12.0884H10.9651C11.2756 12.0884 11.5274 11.826 11.5274 11.5024V8.63102C11.5274 8.30742 11.2756 8.04508 10.9651 8.04508H8.20918C7.8986 8.04508 7.64681 8.30742 7.64681 8.63102V11.5024Z"
      fill="#ADC7CC"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {};
</script>
