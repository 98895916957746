import Vue from 'vue';
import VueRouter from 'vue-router';
import moment from 'moment';
import awsAuth from '@/cognito/aws-auth';
import store from './plugins/vuex';
import App from './App';
import routes from './router';
import vuetify from './plugins/vuetify';
import './plugins/zendesk';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import loader from "@/config.loader";
import VTooltip from 'v-tooltip';
import vueVimeoPlayer from 'vue-vimeo-player'


Sentry.init({
  Vue,
  dsn: loader.getConfigValue("SENTRY_API"),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});



Vue.use(VueRouter);
Vue.use(VTooltip);
Vue.config.productionTip = false;
Vue.use(vueVimeoPlayer)


/* Filters */
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format("MMMM DD YYYY")
    // return moment(String(value)).format("MMMM DD YYYY, h:mm:ss a")
  }
});
/* End */

const router = new VueRouter({
  mode: 'history',
  base: '/',
  fallback: true,
  routes,
  // scrollBehavior: async (to, from, savedPosition) => {
  //   if (savedPosition) {
  //     return savedPosition
  //   }

  //     if (to.hash === '#home') {
  //       return { x: 0, y: 0 }
  //     } else if (to.hash === '#rendezvu') {
  //       return { x: 0, y: 721 }
  //     } else if (to.hash === '#cases') {
  //       return { x: 0, y: 721 * 2 }
  //     } else if (to.hash === '#feature') {
  //       return { x: 0, y: 721 * 3 }
  //     } else if (to.hash === '#technology') {
  //       return { x: 0, y: 721 * 3 }
  //     } else {
  //       return { x: 0, y: 0 }
  //     }
  // }

});

router.beforeResolve(async (to, from, next) => {
  const sign = await awsAuth.signCheck();

  awsAuth.userData()
    .catch(err => {
      if (err.message === 'User does not exist.') {
        next({
          path: '/terminated',
        });
      }
    });

  if (to.name === 'Login' && sign) {
    next({ name: 'Main' });
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    awsAuth
      .authenticated()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: '/auth'
        });
      });
  }
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (awsAuth.isUserAdmin()) next();
    else next({
      path: '/forbidden'
    });
  }
  next();
});

new Vue({
  store,
  render: h => h(App),
  vuetify,
  router
}).$mount('#app');
