<template>
  <div class="feature-block" id="feature">
    <div class="second-backround">
      <div class="background-change">
        <div class="text-change">
          <div>COMING SOON</div>
          <h1>Debates room</h1>
          <h3>
            Our team is working to improve the product every day <br />
            and we are preparing a lot of interesting things for you.
          </h3>
        </div>
      </div>
      <div class="instruments">
        <div class="third-line">
          <div class="one-box">
            <div class="single-block">
              <div>
                Teleportation<br />
                feature <br />
                <span>Short description</span>
              </div>
              <img src="../../assets/icon-teleport.svg" alt="" />
            </div>
            <div class="single-block">
              <div>
                Business cards<br />
                <span>Short description</span>
              </div>
              <img src="../../assets/icon-business-card.svg" alt="" />
            </div>
          </div>
          <div class="big-block">
            <img src="../../assets/icon-big.svg" alt="" />
            <div>
              New customizable map<br />
              <span>Short description</span>
            </div>
          </div>
          <div class="one-box">
            <div class="single-block">
              <div>
                Games and fun<br />
                <span>Short description</span>
              </div>
              <img src="../../assets/icon-play.svg" alt="" />
            </div>
            <div class="single-block">
              <div>
                And much<br />
                <span>more interesting</span>
              </div>
              <img src="../../assets/icon-other.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="instruments-tablet">
        <div class="third-line">
          <div class="single-block">
            <div>
              Teleportation<br />
              feature <br />
              <span>Short description</span>
            </div>
            <img src="../../assets/icon-teleport.svg" alt="" />
          </div>
          <div class="single-block">
            <div>
              Games and fun<br />
              <span>Short description</span>
            </div>
            <img src="../../assets/icon-play.svg" alt="" />
          </div>
        </div>
        <div class="third-line">
          <div class="big-block">
            <div>
              New customizable map<br />
              <span>Short description</span>
            </div>
            <img src="../../assets/icon-big-tablet.svg" alt="" />
          </div>
        </div>
        <div class="third-line">
          <div class="single-block">
            <div>
              Business cards<br />
              <span>Short description</span>
            </div>
            <img src="../../assets/icon-business-card.svg" alt="" />
          </div>
          <div class="single-block">
            <div>
              And much<br />
              <span>more interesting</span>
            </div>
            <img src="../../assets/icon-other.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="instruments-mobile">
        <div class="second-block-mobile">
          <div class="long-block-mobile">
            <div>More animations <br />and emotions</div>
            <img src="../../assets/icon-smile.svg" alt="" />
          </div>

          <div class="first-line-mobile small-middle">
            <div class="identical-block-mobile">
              <img
                height="30px"
                src="../../assets/icon-app-mobile.svg"
                alt=""
              />
              <div>Mobile App</div>
            </div>
            <div class="identical-block-mobile">
              <img
                width="41px"
                src="../../assets/icon-play-mobile.svg"
                alt=""
              />
              <div>Games and fun</div>
            </div>
          </div>

          <div class="long-block-mobile">
            <div>New customizable map</div>
            <img width="130px" src="../../assets/icon-big-mobile.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animon } from "animon";

export default {
  data() {
    return {};
  },
  mounted() {
    animon(".animon-feature");
    gsap.registerPlugin(ScrollTrigger);
  },
};
</script>

<style lang="scss" scoped>
.feature-block {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  opacity: 1;
  background-color: white;
}
.animon-feature {
  opacity: 0;
  will-change: opacity;
  transition: opacity 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1);
}
.animon-feature.is-visible {
  opacity: 1;
}

.background-image {
  height: 100vh;
  width: 100vw;
  position: absolute;
  opacity: 1;
  background-image: linear-gradient(
      rgba(33, 28, 28, 0.8) 20%,
      rgba(33, 28, 28, 0) 80%
    ),
    url("../../assets/Coming-soon.jpg");
  background-size: cover;
  z-index: 1;
}
.second-backround {
  height: calc(100vh - 12vh);
  margin-top: 12vh;
  justify-content: space-around;
  opacity: 1;
  background: radial-gradient(
      circle at left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 0%,
      rgba(238, 174, 202, 0.5) 60%,
      rgba(238, 174, 202, 0.5) 60%,
      rgba(148, 203, 245, 0.2) 90%,
      rgba(148, 203, 245, 0.2) 90%
    ),
    url("../../assets/back-coming-soon.svg");
  z-index: 0;
}
.background-change {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  color: white;
  background-image: linear-gradient(
      rgba(33, 28, 28, 0) 20%,
      rgba(33, 28, 28, 0.9) 80%
    ),
    url("../../assets/Coming-soon-short.jpg");
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  height: 45vh;
  opacity: 1;
  width: 55vw;
  margin-right: 22.5vw;
  margin-left: 22.5vw;
  margin-top: 10px;
  border-radius: 5px 5px 5px 5px;
}

.text-change {
  margin-bottom: 20px;
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 100%;
    padding-top: 10px;
  }
  div {
    opacity: 0.8;
    font-size: 12px;
    letter-spacing: 0.1em;
    line-height: 1;
  }
  h3 {
    font-size: 13px;
    font-weight: 500;
    padding-top: 3vh;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
  }
  text-align: center;
  opacity: 0;
  animation: new 2s forwards;
  animation-delay: 4s;
  @keyframes new {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.instruments-tablet {
  display: none;
}
.instruments-mobile {
  display: none;
}
.instruments {
  width: 55vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.instruments-tablet {
  display: none;
}
.first-line {
  display: flex;
  justify-content: space-between;
  margin-top: 3vh;
  opacity: 1;
  position: relative;

  .identical-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    text-align: start;
    color: black;
    width: 17vw;
    height: 15vh;
    opacity: 0;
    border-radius: 5px;
    span {
      color: #86868b;
      font-size: 11px;
    }
    img {
      width: 70px;
    }
    div {
      font-size: 12px;
      padding-left: 10px;
    }
  }
}
.second-line {
  display: flex;
  justify-content: space-between;
  margin-top: 3vh;
  opacity: 1;
  position: relative;

  .around-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    text-align: start;
    color: black;
    width: 13vw;
    height: 15vh;
    opacity: 0;
    border-radius: 5px;

    span {
      color: #86868b;
      font-size: 11px;
    }
    img {
      width: 110px;
    }
    div {
      font-size: 12px;
      padding: 10px 0px 10px 10px;
    }
  }
}
.middle-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  text-align: start;
  color: black;
  width: 25vw;
  height: 15vh;
  opacity: 0;
  border-radius: 5px;
  img {
    width: 130px;
  }
  div {
    font-size: 12px;
    padding-left: 10px;
  }
  span {
    color: #86868b;
    font-size: 11px;
  }
}
.third-line {
  display: flex;
  justify-content: space-between;
  opacity: 1;
  margin-top: 1vh;

  .single-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    text-align: start;
    color: black;
    width: 17vw;
    height: 15vh;
    opacity: 1;
    border-radius: 5px;
    margin-top: 2vh;

    opacity: 1;
    span {
      color: #86868b;
      font-size: 11px;
    }
    img {
      width: 70px;
    }
    div {
      font-size: 12px;
      padding: 10px 0px 10px 10px;
    }
  }

  .big-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    text-align: center;
    color: black;
    width: 17vw;
    height: 32vh;
    opacity: 1;
    margin-top: 2vh;
    border-radius: 5px;

    span {
      color: #86868b;
      font-size: 11px;
    }
    img {
      width: 130px;
    }
    div {
      font-size: 12px;
      padding: 10px 10px 10px 10px;
    }
  }
}

@media screen and (min-width: 581px) and (max-width: 1025px) and (orientation: portrait) {
  .second-backround {
    background: radial-gradient(
        circle at left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 0%,
        rgba(238, 174, 202, 0.5) 60%,
        rgba(238, 174, 202, 0.5) 60%,
        rgba(148, 203, 245, 0.2) 90%,
        rgba(148, 203, 245, 0.2) 90%
      ),
      url("../../assets/back-coming-soon-tablet.svg");
    background-size: cover;
  }

  .background-change {
    background-image: linear-gradient(
        rgba(33, 28, 28, 0) 30%,
        rgba(33, 28, 28, 0.6) 70%
      ),
      url("../../assets/Coming-soon-short-tablet.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    height: 30vh;
    opacity: 1;
    width: 94vw;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-top: 30px;
  }
  .text-change {
    margin-bottom: 30px;
    h1 {
      font-size: 48px;
      line-height: 100%;
      padding-top: 10px;
      @media screen and (max-width: 700px) {
        font-size: 34px;
      }
    }
    div {
      font-size: 22px;
      @media screen and (max-width: 700px) {
        font-size: 12px;
      }
    }
    h3 {
      font-size: 20px;
      padding-top: 1vh;
      @media screen and (max-width: 700px) {
        font-size: 14px;
      }
    }
  }
  .instruments {
    display: none;
  }
  .instruments-mobile {
    display: none;
  }
  .instruments-tablet {
    width: 94vw;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    display: block;
    padding-top: 0px;
    .third-line {
      display: flex;
      margin-bottom: 0px;
    }
    .big-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      text-align: justify;
      color: black;
      width: 96vw;
      height: 15vh;
      border-radius: 10px;
      padding: 0 !important;
      @media screen and (max-width: 768px) {
        height: 14.5vh;
      }
      span {
        font-size: 22px;
        @media screen and (max-width: 700px) {
          font-size: 15px;
        }
      }
      img {
        width: 40vw;

        @media screen and (max-width: 768px) {
          height: 14.5vh;
        }
      }
      div {
        font-size: 24px;
        padding: 10px 0px 10px 30px;
        @media screen and (max-width: 700px) {
          font-size: 18px;
        }
        @media screen and (max-width: 650px) {
          font-size: 15px;
        }
      }
    }
    .single-block {
      width: 45.5vw;
      height: 15vh;
      border-radius: 10px;
      @media screen and (max-width: 768px) {
        height: 14.5vh;
      }
      span {
        color: #86868b;
        font-size: 22px;
        @media screen and (max-width: 834px) {
          font-size: 20px;
        }
        @media screen and (max-width: 700px) {
          font-size: 15px;
        }
        @media screen and (max-width: 650px) {
          font-size: 13px;
        }
      }
      img {
        width: 140px;
        @media screen and (max-width: 834px) {
          width: 110px;
        }
        @media screen and (max-width: 650px) {
          width: 90px;
        }
      }
      div {
        font-size: 24px;
        padding: 10px 0px 10px 30px;
        @media screen and (max-width: 834px) {
          font-size: 22px;
        }
        @media screen and (max-width: 700px) {
          font-size: 18px;
        }
        @media screen and (max-width: 650px) {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .background-change {
    height: 42vh;
    width: 70vw;
    margin-right: 15vw;
    margin-left: 15vw;
  }
  .instruments {
    width: 70vw;
  }

  .third-line {
    margin-top: 1vh;
    .single-block {
      width: 22vw;
      height: 17vh;
      margin-top: 3vh;
      span {
        font-size: 13px;
        @media screen and (max-device-width: 1110px) {
          font-size: 11px;
          @media screen and (max-device-width: 950px) {
            font-size: 11px;
          }
          @media screen and (max-height: 580px) {
          @media screen and (max-height: 400px) {
            font-size: 8px;
          }
        }
        }
      }
      img {
        width: 100px;
        @media screen and (max-device-width: 1110px) {
          width: 90px;
        }
        @media screen and (max-device-width: 950px) {
          width: 80px;
        }
        @media screen and (max-height: 580px) {
          @media screen and (max-height: 400px) {
             width: 50px;
          }
        }
      }
      div {
        font-size: 15px;
        padding: 10px 0px 10px 20px;
        @media screen and (max-device-width: 1110px) {
          font-size: 12px;
        }
        @media screen and (max-device-width: 950px) {
          font-size: 11px;
        }
        @media screen and (max-height: 580px) {
          @media screen and (max-height: 400px) {
            font-size: 9px;
          }
        }
      }
    }

    .big-block {
      width: 22vw;
      height: 37vh;
      margin-top: 3vh;
      span {
        font-size: 13px;
        @media screen and (max-device-width: 1110px) {
          font-size: 11px;
        }
        @media screen and (max-height: 580px) {
          @media screen and (max-height: 400px) {
            font-size: 8px;
          }
        }
      }
      img {
        width: 200px;
        @media screen and (max-device-width: 1110px) {
          width: 180px;
        }
        @media screen and (max-device-width: 950px) {
          width: 150px;
        }
        @media screen and (max-device-width: 800px) {
          width: 120px;
        }
        @media screen and (max-height: 580px) {
          width: 100px;
          @media screen and (max-height: 400px) {
            width: 80px;
          }
        }
      }
      div {
        font-size: 15px;
        padding: 10px 10px 10px 10px;
        @media screen and (max-device-width: 1110px) {
          font-size: 12px;
        }
        @media screen and (max-height: 580px) {
          @media screen and (max-height: 400px) {
            font-size: 9px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .text-change {
    h1 {
      font-size: 38px;
    }
    div {
      font-size: 14px;
    }
    h3 {
      font-size: 16px;
      padding-top: 2vh;
      padding-bottom: 20px;
    }
  }
  .third-line {
    margin-top: 0vh;

    .single-block {
      margin-top: 4vh;
      span {
        font-size: 15px;
      }
      img {
        width: 100px;
        margin-right: 15px;
      }
      div {
        font-size: 17px;
        padding: 0px 0px 0px 30px;
      }
    }

    .big-block {
      height: 34vh;
      margin-top: 4vh;

      span {
        color: #86868b;
        font-size: 15px;
      }
      img {
        width: 200px;
      }
      div {
        font-size: 17px;
        padding: 0px 10px 20px 10px;
      }
    }
  }
}
@media screen and (min-width: 2800px) {
  .background-change {
    margin-top: 20px;
    border-radius: 5px 5px 5px 5px;
  }
  .text-change {
    h1 {
      font-size: 76px;
      padding-top: 0px;
    }
    div {
      font-size: 28px;
    }
    h3 {
      font-size: 30px;
      padding-bottom: 35px;
    }
  }

  .third-line {
    .single-block {
      span {
        color: #86868b;
        font-size: 24px;
      }
      img {
        width: 160px;
      }
      div {
        font-size: 26px;
        padding: 20px 0px 20px 40px;
      }
    }

    .big-block {
      span {
        color: #86868b;
        font-size: 24px;
      }
      img {
        width: 300px;
      }
      div {
        font-size: 26px;
        padding: 0px 20px 40px 20px;
      }
    }
  }
}
@media screen and (min-width: 3800px) {
  .background-change {
    margin-top: 20px;
    border-radius: 5px 5px 5px 5px;
  }
  .text-feature {
    h1 {
      font-weight: 500;
      font-size: 130px;
      margin-top: 50px;
    }
    h3 {
      font-size: 36px;
    }
  }
  .text-change {
    h1 {
      font-size: 96px;
      padding-top: 0px;
    }
    div {
      font-size: 34px;
    }
    h3 {
      font-size: 36px;
      padding-bottom: 50px;
    }
  }

  .first-line {
    .identical-block {
      span {
        font-size: 32px;
      }
      img {
        width: 240px;
      }
      div {
        font-size: 40px;
        padding-left: 30px;
      }
    }
  }
  .second-line {
    .around-block {
      span {
        color: #86868b;
        font-size: 32px;
      }
      img {
        width: 400px;
      }
      div {
        font-size: 40px;
        padding: 20px 0px 20px 20px;
      }
    }
  }
  .middle-block {
    img {
      width: 560px;
    }
    div {
      font-size: 40px;
      padding-left: 20px;
    }
    span {
      color: #86868b;
      font-size: 32px;
    }
  }
  .third-line {
    .single-block {
      span {
        color: #86868b;
        font-size: 32px;
      }
      img {
        width: 220px;
      }
      div {
        font-size: 36px;
        padding: 20px 0px 20px 40px;
      }
    }

    .big-block {
      span {
        color: #86868b;
        font-size: 32px;
      }
      img {
        width: 450px;
      }
      div {
        font-size: 40px;
        padding: 20px 20px 20px 20px;
      }
    }
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .feature-block {
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;
    opacity: 1;
    background-color: white;
    // height: 45vh;
    opacity: 1;
    width: 100vw;
  }

  .second-backround {
    height: calc(100vh - 70px);
    margin-top: 70px;
    justify-content: space-around;
    opacity: 1;
    background: radial-gradient(
      circle at left,
      rgba(148, 203, 245, 0.3) 0%,
      rgba(148, 203, 245, 0.2) 0%,
      rgba(148, 203, 245, 0.3) 0%,
      rgba(238, 174, 202, 0.3) 60%,
      rgba(238, 174, 202, 0.3) 60%,
      rgba(148, 203, 245, 0.3) 90%,
      rgba(148, 203, 245, 0.3) 90%
    );
    z-index: 0;
  }
  .background-change {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    color: white;
    background-image: linear-gradient(
        rgba(33, 28, 28, 0) 20%,
        rgba(33, 28, 28, 0.9) 80%
      ),
      url("../../assets/Coming-soon-short-mobile.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    opacity: 1;
    width: 100vw;
    height: 40vh;
    margin-right: 0vw;
    margin-left: 0vw;
    margin-top: 0px;
    border-radius: 0px;
  }

  .text-feature {
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 18vh;
    margin-left: auto;
    margin-right: auto;
    h1 {
      font-weight: 500;
      font-family: Poppins;
      font-style: normal;

      font-size: 35px;
      line-height: 1.5;
      // font-size: 48px;
    }
    h3 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 1.5;
    }
  }
  .text-change {
    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 38px;
      line-height: 100%;
      padding-top: 10px;
    }
    div {
      opacity: 0.8;
      font-size: 12px;
      letter-spacing: 0.1em;
      line-height: 1;
    }
    h3 {
      font-size: 13px;
      font-weight: 500;
      padding-top: 1vh;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      margin-bottom: 20px;
      @media screen and (max-width: 390px) {
        font-size: 13px;
      }
      @media screen and (max-width: 360px) {
        font-size: 12px;
      }
    }
    text-align: center;
    opacity: 0;
    animation: new 2s forwards;
    animation-delay: 4s;
    @keyframes new {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .instruments {
    display: none;
  }
  .instruments-tablet {
    display: none;
  }
  .instruments-mobile {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 25px;
  }
  .first-block-mobile {
    display: block;
    opacity: 1;
  }

  .second-block-mobile {
    display: block;
    opacity: 1;
  }
  .first-line-mobile {
    display: flex;
    justify-content: space-between;
    text-align: start;
    width: 90vw;
    opacity: 1;
  }
  .identical-block-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    background-color: white;
    text-align: start;
    color: black;
    width: 42vw;
    height: 15vh;
    opacity: 1;
    border-radius: 10px;
    padding: 5px 0px 7px 20px;
    font-size: 15px;
  }
  .long-block-mobile {
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    height: 14vh;
    padding: 5px 20px 7px 20px;
    // margin: 15px 0 5px 0;
    font-size: 15px;
    opacity: 1;
    img {
      height: 13vh;
    }
  }
  .middle-long {
    margin: 15px 0 15px 0;
    opacity: 1;
  }
  .small-middle {
    margin: 17px 0 17px 0;
    opacity: 1;
  }
  @media screen and (max-width: 375px) {
    .background-change {
      height: 37vh;
    }
  }
  @media screen and (max-height: 670px) {
    .text-change {
      h1 {
        font-size: 30px;
        padding-top: 10px;
      }
      div {
        font-size: 11px;
      }
      h3 {
        font-size: 12px;
        padding-top: 1vh;
        margin-bottom: 20px;
      }
    }
    .instruments-mobile {
      margin-top: 20px;
    }
  }
  @media screen and (max-height: 600px) {
    .text-change {
      h1 {
        font-size: 29px;
        padding-top: 10px;
      }
      div {
        font-size: 11px;
      }
      h3 {
        font-size: 11px;
        padding-top: 1vh;
        margin-bottom: 20px;
      }
    }
    .instruments-mobile {
      margin-top: 15px;
    }
    .identical-block-mobile {
      font-size: 12px;
      height: 14vh;
    }
    .long-block-mobile {
      font-size: 12px;
    }
  }
  @media screen and (max-height: 570px) {
    .text-change {
      h1 {
        font-size: 25px;
        padding-top: 10px;
      }
      h3 {
        font-size: 10px;
        padding-top: 1vh;
        margin-bottom: 20px;
      }
    }

    .instruments-mobile {
      margin-top: 15px;
    }
    .long-block-mobile {
      height: 13.5vh;
    }
    .identical-block-mobile {
      height: 13.5vh;
    }
  }
}
</style>
