<template>
  <div>
    <v-menu v-if="!isMobile" offset-y open-on-hover close-delay="1000">
      <template v-slot:activator="{ on }">
        <div class="profile" v-on="on">
          <div class="profile-username">
            {{ user.username }}
          </div>
          <div class="profile-avatar-hightlight">
            <div class="profile-avatar">
              <v-img
                class="user-image"
                v-show="userImage"
                :src="`${userImage}`"
                aspect-ratio="2"
              ></v-img>

              <v-icon
                class="icon-profile-user"
                color="white"
                v-show="!userImage"
              >
                far fa-user-circle
              </v-icon>
            </div>
          </div>
        </div>
      </template>

      <div class="profile-menu">
        <profile-menu />
      </div>
    </v-menu>
    <v-menu v-if="isMobile" offset-y open-on-click close-delay="1000">
      <template v-slot:activator="{ on }">
        <div @click="openMenu" class="profile" v-on="on">
          <div class="profile-username">
            {{ user.username }}
          </div>
          <div class="profile-avatar-hightlight">
            <div class="profile-avatar">
              <v-img
                class="user-image"
                v-show="userImage"
                :src="`${userImage}`"
                aspect-ratio="2"
              ></v-img>

              <v-icon
                class="icon-profile-user"
                color="white"
                v-show="!userImage"
              >
                far fa-user-circle
              </v-icon>
            </div>
          </div>
        </div>
      </template>

      <profile-menu v-if="showMenu" class="profile-menu" />
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import awsAuth from "@/cognito/aws-auth";
import ProfileMenu from "../ProfileMenu";

export default {
  data() {
    return {
      userImage: "",
      isMobile: false,
      showMenu: false,
    };
  },
  components: {
    ProfileMenu,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.getUser();
    if (window.screen.width <= 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    async getUser() {
      const response = await awsAuth.userData();
      this.userImage = response.data.picture;
    },
    openMenu() {
      this.showMenu = true;
      setTimeout(() => {
        this.showMenu = false;
      }, 3500);
      // setTimeout(() => {
      //   this.showMenu = true;
      // }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
  z-index: 9;
  &:hover {
    background-color: nonу;
  }
}

.profile-username {
  margin-right: 1rem;
  font-weight: 600;
  color: #1eafc1 !important;
  font-size: 0.7rem;
}

.v-list {
  background-color: white !important;
}
.profile-avatar-hightlight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  background-image: url("../../assets/icon-ellipse-user.svg");
  background-size: contain;
}
.profile-avatar {
  border-radius: 50px;
  background-size: 100px;
}
.user-image {
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  // border: ;
}

.v-menu__content {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 0%), 0px 8px 10px 1px rgb(0 0 0 / 0%),
    0px 3px 14px 2px rgb(0 0 0 / 0%) !important;
  border-radius: 1px;
}
@media screen and (min-width: 3800px) {
  .profile-avatar-hightlight {
    width: 150px;
    height: 150px;
  }
  .profile-username {
    font-size: 35px;
    margin-right: 3rem;
    margin-left: 1vw;
  }
  .icon-profile-user {
    font-size: 75px;
  }
  .user-image {
    width: 130px;
    height: 130px;
    border-radius: 50% !important;
  }
}

@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .profile {
    padding: 0rem;
    // width: 150px;
    &:hover {
      background: none;
    }
  }
  .profile-avatar-hightlight {
    width: 70px;
    height: 70px;
    @media screen and (max-device-width: 1024px) {
    }
  }
  .user-image {
    width: 62px;
    height: 62px;
    border-radius: 50px !important;
  }
  .profile-username {
    display: none;
  }
  .profile-mobile-menu {
    position: absolute;
    margin-top: 10px;
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .profile-avatar-hightlight {
    width: 45px;
    height: 45px;
  }
  .profile-username {
    // font-size: 16px !important;
    margin-right: 0.5rem;
    // margin-left: 1vw;
    @media screen and (max-device-width: 800px) {
      // font-size: 14px !important;
    }
    @media screen and (max-height: 580px) {
      // font-size: 13px !important;
      @media screen and (max-width: 700px) {
        // font-size: 11px !important;
      }
    }
  }

  .icon-profile-user {
    font-size: 75px;
  }
  .user-image {
    width: 37px;
    height: 37px;
    border-radius: 50% !important;
  }
  .icon-profile-user {
    font-size: 30px;
  }
  @media screen and (max-height: 580px) {
    .profile {
      @media screen and (max-width: 700px) {
        padding: 0rem;
      }
    }
    .profile-username {
      font-size: 13px !important;
      @media screen and (max-width: 700px) {
        font-size: 11px !important;
      }
    }
    .profile-avatar-hightlight {
    width: 27px;
    height: 27px;
  }
  .user-image {
    width: 22px;
    height: 22px;
    border-radius: 50% !important;
  }
  }
}
@media screen and(max-width:580px) {
  .profile {
    padding: 0rem;
    // margin-left: 15px;
    // width: 70px;
    &:hover {
      background: none;
    }
  }
  .profile-mobile-menu {
    position: absolute;
    margin-top: 10px;
  }
  .v-list {
    background-color: white !important;
  }
  .profile-avatar-hightlight {
    width: 40px;
    height: 40px;
  }
  .profile-avatar {
    border-radius: 50px;
    background-size: 0px;
  }
  .user-image {
    width: 33px;
    height: 33px;
    border-radius: 50px !important;
    // border: ;
  }
  .profile-username {
    display: none;
  }
}
</style>
