<template>
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.777778 0H13.2222C13.4285 0 13.6263 0.086201 13.7722 0.23964C13.9181 0.393079 14 0.601187 14 0.818182V4.09091C14 4.3079 13.9181 4.51601 13.7722 4.66945C13.6263 4.82289 13.4285 4.90909 13.2222 4.90909H0.777778C0.571498 4.90909 0.373667 4.82289 0.227806 4.66945C0.0819442 4.51601 0 4.3079 0 4.09091V0.818182C0 0.601187 0.0819442 0.393079 0.227806 0.23964C0.373667 0.086201 0.571498 0 0.777778 0ZM0.777778 6.54545H13.2222C13.4285 6.54545 13.6263 6.63166 13.7722 6.78509C13.9181 6.93853 14 7.14664 14 7.36364V10.6364C14 10.8534 13.9181 11.0615 13.7722 11.2149C13.6263 11.3683 13.4285 11.4545 13.2222 11.4545H0.777778C0.571498 11.4545 0.373667 11.3683 0.227806 11.2149C0.0819442 11.0615 0 10.8534 0 10.6364V7.36364C0 7.14664 0.0819442 6.93853 0.227806 6.78509C0.373667 6.63166 0.571498 6.54545 0.777778 6.54545ZM0.777778 13.0909H13.2222C13.4285 13.0909 13.6263 13.1771 13.7722 13.3305C13.9181 13.484 14 13.6921 14 13.9091V17.1818C14 17.3988 13.9181 17.6069 13.7722 17.7604C13.6263 17.9138 13.4285 18 13.2222 18H0.777778C0.571498 18 0.373667 17.9138 0.227806 17.7604C0.0819442 17.6069 0 17.3988 0 17.1818V13.9091C0 13.6921 0.0819442 13.484 0.227806 13.3305C0.373667 13.1771 0.571498 13.0909 0.777778 13.0909ZM4.66667 3.27273H5.44444V1.63636H4.66667V3.27273ZM4.66667 9.81818H5.44444V8.18182H4.66667V9.81818ZM4.66667 16.3636H5.44444V14.7273H4.66667V16.3636ZM1.55556 1.63636V3.27273H3.11111V1.63636H1.55556ZM1.55556 8.18182V9.81818H3.11111V8.18182H1.55556ZM1.55556 14.7273V16.3636H3.11111V14.7273H1.55556Z"
      fill="#ADC7CC"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {};
</script>
