<template>
  <div class="feedback" id="getInTouch">
    <div class="common-feedback">
      <div v-show="isShow" class="feedback-form">
        <div class="block-form">
          <h1 class="title-h1">Get in touch</h1>
          <p class="title-p">Fill out the form or call us</p>
          <v-form class="form" id="support">
            <v-text-field
              label="Your email address"
              :rules="emailRules"
              v-model="feedbackForm.email"
              outlined
              dense
              style="font-size: 1.5vh; opacity: 90% !important"
              class="input input-focus"
            >
              <template v-slot:prepend-inner>
                <img
                  class="profile-icon"
                  src="../../assets/icon-email-feedback.svg"
                  alt=""
                /> </template
            ></v-text-field>
            <div class="input-row">
              <v-text-field
                label="Your phone"
                :rules="phoneRules"
                v-model="feedbackForm.phone"
                outlined
                class="input input-phone input-focus"
                dense
                style="font-size: 1.5vh; opacity: 90% !important"
              >
                <template v-slot:prepend-inner>
                  <img
                    class="profile-icon"
                    height="20px"
                    src="../../assets/icon-phone-feedback.svg"
                    alt=""
                  /> </template
              ></v-text-field>
              <v-text-field
                label="Your name"
                v-model="feedbackForm.username"
                outlined
                class="input input-focus"
                dense
                style="font-size: 1.5vh; opacity: 90% !important"
              >
                <template v-slot:prepend-inner>
                  <img
                    class="profile-icon"
                    src="../../assets/icon-briefcase-feedback.svg"
                    alt=""
                  /> </template
              ></v-text-field>
            </div>
            <v-textarea
              label="How can we help you?"
              v-model="feedbackForm.message"
              outlined
              height="12vh"
              no-resize
              class="input input-message input-focus"
            >
              <template v-slot:prepend-inner>
                <img
                  class="profile-icon-message"
                  src="../../assets/icon-plain-feedback.svg"
                  alt=""
                />
              </template>
            </v-textarea>

            <div class="btn-feedback">
              <v-btn
                elevation="0"
                class="send-feedback"
                color="primary"
                exact
                :disabled="!formValidated"
                @click="sendFeedbackForm"
              >
                Send request
              </v-btn>
            </div>
          </v-form>
        </div>

        <div class="contact-content">
          <h1>Contact</h1>
          <p>Fill out the form or call us</p>
          <div class="contact">
            <div class="first-contact">
              <img
                class="image-phone"
                src="../../assets/icon-phone.svg"
                alt=""
              />
              <div>
                Want to talk?<br />
                <span style="color: black">1 (844) 741-4210</span>
              </div>
            </div>
            <div class="first-contact">
              <img
                class="image-rupor"
                src="../../assets/icon-rupor.svg"
                alt=""
              />
              <div>
                Press<br />
                <span>media@rendezvu.com</span>
              </div>
            </div>
            <div class="first-contact">
              <img class="image-map" src="../../assets/icon-map.svg" alt="" />
              <div>
                Office<br />
                <span>7, Oxford Street, London</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!isShow" class="feedback-done">
        <img src="../../assets/icon-feedback-done.svg" alt="" />
        <h1>DONE</h1>
        <div>
          Request has been sent,<br />
          our manager will contact you
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/plugins/request";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { SET_FEEDBACK } from "@/store/ui";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      feedbackForm: {
        username: "",
        email: "",
        phone: "",
        message: "",
      },
      isMobile: false,
      isTablet: false,
      isShow: true,
      emailRules: [
        (value) => !!value || "Required",
        (value) =>
          (value &&
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              value
            )) ||
          "Please enter a valid email",
      ],
      phoneRules: [
        (value) => !!value || "Required",
        (value) =>
          (value && /^[\d]{11}$/.test(value)) || "Please enter only number",
      ],
    };
  },
  computed: {
    formValidated() {
      if (
        this.feedbackForm.email &&
        this.feedbackForm.username &&
        this.feedbackForm.phone &&
        this.feedbackForm.message
      ) {
        return (
          this.feedbackForm.username &&
          /^[\d]{11}$/.test(this.feedbackForm.phone) &&
          this.feedbackForm.message &&
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.feedbackForm.email
          )
        );
      }
      return false;
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const focusInput = document.querySelectorAll(".input-focus");
    const title1 = document.querySelector(".title-h1");
    const title2 = document.querySelector(".title-p");
    const profileIcon = document.querySelectorAll(".profile-icon");
    const profileIconMessage = document.querySelector(".profile-icon-message");
    const feedbackComponent = document.querySelector(".feedback-component");
    const navigationMain = document.querySelector(".navigation-main");

    focusInput.forEach((input) =>
      input.addEventListener("focusin", () => {
        this.getDevice();

        if (this.isMobile) {
          navigationMain.style.display = "none";
          title1.style.display = "none";
          title2.style.display = "none";
          profileIcon.forEach((icon) => {
            icon.style.padding = "0px 5px 5px 5px";
            icon.style.marginTop = "4px";
            icon.style.marginBottom = "5px";
          });

          profileIconMessage.style.padding = "5px";
          profileIconMessage.style.marginTop = "0px";
          profileIconMessage.style.marginBottom = "5px";
        }
        if (this.isTablet) {
          if (window.screen.height > window.screen.width) {
            navigationMain.style.display = "none";
          }
          title1.style.display = "none";
          title2.style.display = "none";
          feedbackComponent.style.height = "90vh";
        }
      })
    );
    focusInput.forEach((input) =>
      input.addEventListener("focusout", () => {
        this.getDevice();
        if (this.isMobile) {
          title1.style.display = "block";
          title2.style.display = "block";

          profileIcon.forEach((icon) => {
            icon.style.marginTop = "14px";
            icon.style.marginBottom = "25px";
            icon.style.padding = "0px 0px 0px 0px";
          });
          profileIconMessage.style.marginTop = "0px";
          profileIconMessage.style.marginBottom = "25px";
          profileIconMessage.style.padding = "0px";
        }
        if (this.isTablet) {
          title1.style.display = "block";
          title2.style.display = "block";
          feedbackComponent.style.height = "70vh";
        }
      })
    );
  },
  methods: {
    ...mapMutations("ui", {
      changeIsFeedback: SET_FEEDBACK,
    }),
    closeForm() {
      this.changeIsFeedback(false);
    },

    async sendFeedbackForm() {
      console.log(this.feedbackForm);
      const response = await request({
        method: "POST",
        url: "/users/sendFeedback",
        body: JSON.stringify(this.feedbackForm),
      });
      if (response.status === 200) {
        this.isShow = false;
        setTimeout(() => {
          this.feedbackForm = {
            username: "",
            email: "",
            phone: "",
            message: "",
          };
          window.location.reload();
        }, 1000);
      }
    },
    getDevice() {
      const devices = new RegExp(
        "Android|webOS|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini",
        "i"
      );

      if (devices.test(navigator.userAgent)) {
        if (window.screen.width <= 580) {
          this.isMobile = true;
          this.isTablet = false;
        } else {
          this.isTablet = true;
          this.isMobile = false;
        }
      } else {
        this.isMobile = false;
        this.isTablet = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  min-width: 70vw;
  background-color: rgb(255, 255, 255);
  opacity: 1;
  color: rgb(0, 0, 0);
  z-index: 9999;
  height: calc(100vh - 70px);
}

.feedback-form {
  display: flex;
  text-align: start;
  width: 50vw;
  border-radius: 5px;
  position: relative;
  padding-top: 4vh;
  padding-left: 7vw;
  padding-bottom: 0vh;
  // margin-top: 100px;
}
.feedback-done {
  width: 80vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 300%;
    text-align: center;
    color: #1d1d1f;
  }
  div {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 145%;
    text-align: center;
    color: #242424;
    opacity: 0.6;
  }
}
.block-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  opacity: 1;
  width: 40vw;
  h1 {
    line-height: 2;
    padding-left: 5px;
    font-size: 4vh;
    letter-spacing: 0.1rem;
  }
  p {
    font-size: 13px;
    line-height: 100%;
    color: #99b4b9;
    margin-bottom: 5vh;
  }
}

.contact-content {
  display: flex;
  flex-direction: column;
  padding-left: 4vw;
  width: 30vw;
  opacity: 1;
  h1 {
    line-height: 2;
    font-size: 4vh;
    letter-spacing: 0.1rem;
  }
  p {
    font-size: 13px;
    line-height: 100%;
    color: #99b4b9;
    margin-bottom: 5vh;
  }
}

.contact {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  div {
    font-size: 13px;
    color: #99b4b9;
  }
}
.input {
  padding-right: 1vw !important;
}
.input-row {
  display: flex;
  width: 38vw;
}

span {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #185fbf;
  opacity: 0.9;
}
.first-contact {
  width: 20vw;
  display: flex;
  align-items: center;
  margin-bottom: 3vh;
  img {
    margin-right: 2vw;
    width: 4vw;
  }
}
.profile-icon {
  margin-right: 0.5vw;
  width: 20px !important;
  max-width: 20px !important;
}
.profile-icon-message {
  margin-right: 0.5vw;
  margin-top: -10px;
  width: 20px !important;
  max-width: 20px !important;
}
.send-feedback {
  height: 7vh !important;
  width: 18.5vw;
}
.btn-feedback {
  margin-top: 0vh;
}

@media screen and (max-height: 580px) and (orientation: landscape) {
  .feedback {
    min-width: 70vw;
    background-color: rgb(255, 255, 255);
    opacity: 1;
    color: rgb(0, 0, 0);
    z-index: 9999;
    height: calc(100vh - 70px);
  }

  .feedback-form {
    display: flex;
    text-align: start;
    width: 50vw;
    border-radius: 5px;
    position: relative;
    padding-top: 2vh;
    padding-left: 7vw;
    padding-bottom: 0vh;
    // margin-top: 100px;
  }
  .feedback-done {
    width: 80vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 300%;
      text-align: center;
      color: #1d1d1f;
    }
    div {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 145%;
      text-align: center;
      color: #242424;
      opacity: 0.6;
    }
  }
  .block-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    opacity: 1;
    width: 40vw;
    h1 {
      line-height: 2;
      padding-left: 0px;
      font-size: 4vh;
      letter-spacing: 0.1rem;
    }
    p {
      font-size: 9px;
      line-height: 100%;
      color: #99b4b9;
      margin-bottom: 2vh;
    }
  }

  .contact-content {
    display: flex;
    flex-direction: column;
    padding-left: 4vw;
    width: 30vw;
    opacity: 1;
    h1 {
      line-height: 2;
      font-size: 4vh;
      letter-spacing: 0.1rem;
    }
    p {
      font-size: 9px;
      line-height: 100%;
      color: #99b4b9;
      margin-bottom: 5vh;
    }
  }

  .contact {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      font-size: 8px;
      color: #99b4b9;
    }
  }
  .input {
    padding-right: 1vw !important;
  }
  .input-row {
    display: flex;
    width: 38vw;
  }

  span {
    font-weight: 500;
    font-size: 9px;
    line-height: 100%;
    color: #185fbf;
    opacity: 0.9;
  }
  .first-contact {
    width: 20vw;
    display: flex;
    align-items: center;
    margin-bottom: 3vh;
    img {
      margin-right: 2vw;
      width: 3vw;
    }
  }
  .profile-icon {
    margin-right: 0.5vw;
    width: 15px !important;
    max-width: 15px !important;
    @media screen and (max-height: 400px) {
      width: 10px !important;
      max-width: 10px !important;
      padding-bottom: 10px;
    }
  }
  .profile-icon-message {
    margin-right: 0.5vw;
    margin-top: -10px;
    width: 15px !important;
    max-width: 15px !important;
    @media screen and (max-height: 400px) {
      width: 10px !important;
      max-width: 10px !important;
    }
  }
  .send-feedback {
    height: 7vh !important;
    width: 18.5vw;

    font-size: 8px;
  }
  .btn-feedback {
    margin-top: 2vh;
    @media screen and (max-height: 400px) {
      margin-top: 1vh;
    }
  }
  .input-message {
    height: 60px !important;
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .feedback {
    min-width: 70vw;
    background-color: rgb(255, 255, 255);
    opacity: 1;
    color: rgb(0, 0, 0);
    z-index: 9999;
    height: calc(100vh - 70px);
  }
  .feedback-form {
    display: flex;
    text-align: start;
    width: 50vw;
    border-radius: 5px;
    position: relative;
    padding-top: 4vh;
    padding-left: 7vw;
    padding-bottom: 0vh;
    // margin-top: 100px;
  }
  .contact-content {
    display: none;
  }
  .block-form {
    width: 100vw;
    h1 {
      line-height: 2;
      font-size: 3vh;
      letter-spacing: 0.1rem;
      font-weight: 600;
      line-height: 200%;
    }
    p {
      font-size: 22px;
      line-height: 100%;
      color: #99b4b9;
      margin-bottom: 5vh;
    }
  }
  .input-row {
    display: flex;
    width: 87vw;
  }
  .input-phone {
    margin-right: 7px !important;
  }
  .profile-icon {
    width: 25px !important;
    max-width: 25px !important;
    margin: 7px 15px 15px 7px;
    @media screen and (max-width: 768px) {
      margin-right: 2vw;
    }
    @media screen and (max-width: 700px) {
      width: 20px !important;
      max-width: 20px !important;
    }
  }
  .profile-icon-message {
    width: 25px !important;
    max-width: 25px !important;
    margin: 7px 17px 15px 7px;
    @media screen and (max-width: 768px) {
      margin-right: 2vw;
      margin: 0px 7px 0px 0px;
    }
    @media screen and (max-width: 700px) {
      width: 20px !important;
      max-width: 20px !important;
    }
  }
  .btn-feedback {
    margin-top: 0vh;
    @media screen and (max-width: 768px) {
      margin-top: 0vh;
    }
    @media screen and (max-width: 700px) {
      margin-top: 0vh;
    }
  }
  .send-feedback {
    height: 6.5vh !important;
    width: 40vw !important;
    font-size: 24px !important;
    text-transform: none;
    @media screen and (max-width: 700px) {
      height: 6vh !important;
      font-size: 18px !important;
    }
  }
  .feedback-done {
    width: 100vw;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url("../../assets/feedback-done-back.svg");
    background-size: cover;
    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 300%;
      text-align: center;
      color: #1d1d1f;
    }
    div {
      font-family: Poppins;
      font-style: normal;
      text-align: center;
      font-weight: normal;
      font-size: 22px;
      line-height: 145%;
      text-align: center;
      color: #242424;
      opacity: 0.6;
    }
  }
}
@media screen and (min-width: 1920px) {
  .block-form {
    h1 {
      font-size: 38px;
    }
    p {
      font-size: 15px;
    }
  }
  .contact-content {
    width: 30vw;
    h1 {
      font-size: 38px;
    }
    p {
      font-size: 15px;
      margin-bottom: 4vh;
    }
  }
  .profile-icon {
    width: 25px !important;
    max-width: 25px !important;
  }
  .profile-icon-message {
    width: 25px !important;
    max-width: 25px !important;
  }
  .btn-feedback {
    margin-top: 1vh;
  }
  .send-feedback {
    height: 7vh !important;
    width: 17.5vw !important;
    font-size: 16px !important;
    text-transform: none;
  }
}
@media screen and (min-width: 2800px) {
  .feedback {
    width: 60vw;
  }
  .feedback-form {
    display: flex;
    text-align: start;
    width: 40vw;
    border-radius: 5px;
    position: relative;
    // padding-top: 10vh;
    padding-left: 7vw;
    // padding-bottom: 20vh;
  }
  .feedback-done {
    display: flex;
    text-align: start;
    width: 50vw;
    border-radius: 5px;
    position: relative;
    padding-top: 4vh;
    padding-left: 7vw;
    h1 {
      font-size: 68px;
    }
    div {
      font-size: 44px;
    }
  }
  .block-form {
    justify-content: flex-start;
    h1 {
      font-size: 55px;
      margin-bottom: 25px;
    }
    p {
      font-size: 30px;
      margin-bottom: 1vh;
      margin-bottom: 75px;
    }
  }
  .contact-content {
    padding-left: 4vw;
    width: 20vw;
    h1 {
      font-size: 55px;
      margin-bottom: 25px;
    }
    p {
      font-size: 30px;
      margin-bottom: 5vh;
    }
  }

  .contact {
    div {
      font-size: 35px;
    }
  }

  span {
    font-size: 35px;
  }
  .first-contact {
    margin-bottom: 3vh;
    img {
      margin-right: 2vw;
      // width: 3vw;
    }
    .image-phone {
      width: 4vw;
    }
    .image-rupor {
      width: 4vw !important;
    }
    .image-map {
      width: 4vw;
    }
  }
  .btn-feedback {
    margin-top: 2vh;
  }
  .send-feedback {
    height: 6vh !important;
    width: 16vw !important;
    font-size: 28px !important;
  }
  .profile-icon {
    margin: 7px 7px 15px 7px;
    height: 35px !important;
  }
  .input-row {
    margin-bottom: 0px;
    display: flex;
    width: 34vw;
  }
}
@media screen and (min-width: 3800px) {
  .profile-icon {
    margin: 25px 0 0 0;
    width: 55px !important;
    max-width: 55px !important;
  }
  .profile-icon-message {
    width: 45px !important;
    max-width: 45px !important;
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .feedback {
    display: block;
    width: 100vw;
    background: linear-gradient(
      rgba(114, 112, 112, 0.1) 10%,
      rgba(97, 97, 97, 0) 80%
    );
    opacity: 1;
    color: rgb(0, 0, 0);
    z-index: 9999;
    height: calc(100vh - 70px);
    margin-top: 70px;
  }

  .feedback-form {
    display: block;
    text-align: start;
    width: 50vw;
    border-radius: 5px;
    position: relative;
    padding-top: 1vh;
    padding-left: 0vw;
    padding-bottom: 0vh;
    //      background: linear-gradient(
    // rgba(114, 112, 112, 0.1) 10%,
    // rgba(97, 97, 97, 0) 80% );
    //     color: rgb(0, 0, 0);
  }
  .feedback-text {
    display: none;
    flex-direction: column;
    text-align: start;
    color: #1d1d1f;
    opacity: 1;
    justify-content: space-around;
    margin-top: 7vh;
    margin-bottom: 5vh;
    margin-left: 0vw;
    font-style: normal;
    font-weight: 500;
    h1 {
      font-size: 28px;
      line-height: 100%;
    }
    p {
      letter-spacing: -0.5px;
      font-size: 14px;
      line-height: 100%;
      opacity: 0.6;
    }
  }
  .contact-content {
    display: none;
  }
  .input-row {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
  .feedback-done {
    width: 100vw;
    height: calc(100vh - 70px);
    // margin-top: 70px;
    background: radial-gradient(
      circle at left,
      rgba(238, 174, 202, 0.2) 0%,
      rgba(238, 174, 202, 0.2) 0%,
      rgba(148, 203, 245, 0.3) 30%,
      rgba(148, 203, 245, 0.2) 30%,
      rgba(148, 203, 245, 0.3) 30%,
      rgba(148, 203, 245, 0.3) 60%,
      rgba(148, 203, 245, 0.3) 60%,
      rgba(238, 174, 202, 0.3) 90%,
      rgba(238, 174, 202, 0.3) 90%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 300%;
      text-align: center;
      color: #1d1d1f;
    }
    div {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 145%;
      /* or 20px */
      text-align: center;
      color: #242424;
      opacity: 0.6;
    }
  }
  .block-form {
    padding-left: 4vw;
    padding-right: 2vw;
    padding-top: 1vh;
    opacity: 1;
    width: 100vw;
    // height: 47vh;
    h1 {
      line-height: 2;
      padding-left: 2vw;
      font-size: 26px;
    }
    p {
      line-height: 2;
      opacity: 0.7;
      padding-left: 2vw;
      font-size: 15px;
       margin-bottom: 1.5vh;
    }
  }
  .form {
    width: 90vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  .feedback-btn {
    height: 30vh;
  }
  .send-feedback {
    width: 89vw !important;
    height: 60px !important;
  }
  .btn-feedback {
    margin-top: 0vh;
  }
  .profile-icon {
    margin-right: 1.5vw;
    width: 25px !important;
    max-width: 25px !important;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .profile-icon-message {
    margin-right: 1.5vw;
    width: 25px !important;
    max-width: 25px !important;
    margin-top:-2px;
    // margin-bottom: 10px;
    padding: 0px;
  }
  .v-input {
    align-items: flex-start;
    display: flex;
    flex: 0 0 0 !important;
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
  }
  @media screen and (max-height: 600px) {
    .feedback-text {
      margin-top: 3vh;
      margin-bottom: 3vh;
      margin-left: 5vw;
      h1 {
        font-size: 26px;
      }
      p {
        font-size: 12px;
      }
    }
    .form-textfield {
      height: 60vh;
    }
    .input-message {
      height: 90px !important;
    }
  }

  @media screen and (max-height: 570px) {
    .feedback-text {
      margin-top: 3vh;
      margin-bottom: 3vh;
      margin-left: 5vw;
      h1 {
        font-size: 26px;
      }
      p {
        font-size: 12px;
      }
    }
    .form-textfield {
      height: 50vh;
    }
    .input-message {
      height: 90px !important;
    }
    .block-form {
    padding-left: 4vw;
    padding-right: 2vw;
    padding-top: 1vh;
    opacity: 1;
    width: 100vw;
    // height: 47vh;
    h1 {
      line-height: 1.5;
      padding-left: 2vw;
      font-size: 22px;
    }
    p {
      line-height: 2;
      opacity: 0.6;
      padding-left: 2vw;
      font-size: 12px;
    }
  }
  .profile-icon {
    margin-right: 1.5vw;
    width: 20px !important;
    max-width: 20px !important;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .profile-icon-message {
    margin-right: 1.5vw;
    width: 20px !important;
    max-width: 20px !important;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  }
}
</style>

<style>
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  flex: 1 3 auto;
}
.v-input input {
  max-height: 40px !important;
  font-size: 18px !important;
}

.v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense)
  .v-label,
.v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense)
  .v-label,
.v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense)
  .v-label,
.v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense)
  .v-label {
  top: 18px;
  height: 11vh;
}
@media screen and (max-height: 580px) and (orientation: landscape) {
  .v-input input {
    max-height: 20px !important;
    font-size: 16px !important;
  }
  .v-text-field--filled.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot {
    min-height: 30px !important;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 0px !important;
  }
  @media screen and (max-height: 400px) {
    .v-input input {
      max-height: 18px !important;
      font-size: 16px !important;
    }
    .v-text-field--filled.v-input--dense.v-text-field--single-line
      > .v-input__control
      > .v-input__slot,
    .v-text-field--filled.v-input--dense.v-text-field--outlined
      > .v-input__control
      > .v-input__slot,
    .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
      > .v-input__control
      > .v-input__slot,
    .v-text-field--full-width.v-input--dense.v-text-field--single-line
      > .v-input__control
      > .v-input__slot,
    .v-text-field--full-width.v-input--dense.v-text-field--outlined
      > .v-input__control
      > .v-input__slot,
    .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
      > .v-input__control
      > .v-input__slot,
    .v-text-field--outlined.v-input--dense.v-text-field--single-line
      > .v-input__control
      > .v-input__slot,
    .v-text-field--outlined.v-input--dense.v-text-field--outlined
      > .v-input__control
      > .v-input__slot,
    .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
      > .v-input__control
      > .v-input__slot {
      min-height: 20px !important;
    }
  }
  .v-label {
    font-size: 9px !important;
    line-height: 1;
    min-height: 8px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}
@media screen and (min-width: 3800px) {
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    padding-top: 5px;
    padding-left: 5px;
    height: 35px;
    font-size: 30px !important;
  }
  .v-text-field--outlined.v-input--dense .v-label {
    padding-top: 5px;
    padding-left: 5px;
    height: 35px;
    font-size: 30px !important;
  }

  .v-messages__message {
    line-height: 36px !important;
    font-size: 28px;
  }
  .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense)
    .v-label {
    /* top: 135px; */
    height: 8vh;
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense)
    .v-label {
    margin-top: 10px;
    height: 10vh;
    font-size: 16px;
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    padding-top: 5px;
    padding-left: 5px;
    /* height: 35px; */
    font-size: 20px !important;
  }
  .v-text-field--outlined.v-input--dense .v-label {
    padding-top: 5px;
    padding-left: 5px;
    height: 35px;
    font-size: 20px !important;
  }

  .v-messages__message {
    line-height: 20px !important;
    font-size: 18px;
  }
  .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--box.v-text-field--outlined:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense)
    .v-label,
  .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense)
    .v-label {
    margin-top: 5px;
    height: 9vh;
    font-size: 20px;
  }

  @media screen and (max-width: 834px) {
    .v-messages__message {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 820px) {
    .v-messages__message {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 768px) {
    .v-messages__message {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 700px) {
    .v-messages__message {
      font-size: 16px;
    }
    .v-text-field--outlined.v-input--dense .v-label {
      height: 30px;
      font-size: 18px !important;
    }
  }
}
</style>
