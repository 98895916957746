<template>
  <div class="about-case about-case-education">
    <div class="list-cases">
      <img src="../../../assets/icon-direction.svg" alt="" />
      <div>Create space for lectures and seminars</div>
    </div>
    <div class="list-cases">
      <img src="../../../assets/icon-direction.svg" alt="" />
      <div>Use different locations for competitive play or team missions</div>
    </div>
    <div class="list-cases">
      <img src="../../../assets/icon-direction.svg" alt="" />
      <div>Everyone can use the screen and present the prepared material</div>
    </div>
    <div class="list-cases last-list-cases">
      <img src="../../../assets/icon-direction.svg" alt="" />
      <div>Use chat to exchange messages and links</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.about-case {
  // height: 32vh;
}
.menu-cases {
  display: flex;
}

.list-cases {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  div {
    margin-left: 20px;
    font-size: 14px;
  }
  img {
    width: 37px;
  }
}
.case-info {
  margin-bottom: 20px;
}
@media screen and (min-width: 1600px)  {
 .list-cases {
      margin-bottom: 14px;
      div {
        font-size: 15px;
      }
      img {
        width: 46px;
      }
    }
}
@media screen and (min-width: 1800px)  {
 .list-cases {
      margin-bottom: 16px;
      div {
        font-size: 17px;
      }
      img {
        width: 48px;
      }
    }
}
@media screen and (min-width: 1920px) {
  .list-cases {
    margin-bottom: 20px;
    div {
      font-size: 16px;
      margin-left: 35px;
    }
    img {
      width: 54px;
    }
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .list-cases {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    div {
      margin-left: 20px;
      font-size: 22px;
    }
    img {
      width: 65px;
    }
  }
  .case-info {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 834px) {
    .list-cases {
      margin-bottom: 22px;
      div {
        font-size: 20px;
      }
      img {
         width: 57px;
      }
    }
  }
  @media screen and (max-width: 810px) {
    .list-cases {
      margin-bottom: 20px;
      div {
        font-size: 18px;
      }
      img {
        width: 52px;
      }
    }
  }
  @media screen and (max-width: 650px) {
    .list-cases {
      margin-bottom: 10px;
      div {
        font-size: 14px;
      }
      img {
        width: 40px;
      }
    }
  }
}
@media screen and (max-width: 1366px) and (orientation: landscape) {
  .list-cases {
     margin-bottom: 1.5vh;
    @media screen and (max-height: 580px) {
      @media screen and (max-height: 400px) {
        margin-bottom: 9px;
      }
    }
    div {
      font-size: 13px;
      @media screen and (max-width: 1200px) {
        font-size: 11px;
        @media screen and (max-width: 1100px) {
          font-size: 10px;
        }
       
        @media screen and (max-width: 950px) {
          font-size: 9.5px;
          margin-bottom: 0px;
        }
        @media screen and (max-width: 800px) {
          font-size: 8px;
          margin-left: 10px;
        }
      }
      @media screen and (max-height: 580px) {
        @media screen and (max-height: 400px) {
          font-size: 7px;
        }
      }
    }
    img {
      width: 39px;
      @media screen and (max-width: 1200px) {
        width: 36px;
      }
      // @media screen and (max-device-width: 1024px) {
      //   width: 30px;
      // }
      @media screen and (max-width: 950px) {
        width: 27px;
      }
      @media screen and (max-width: 800px) {
        width: 25px;
      }
       @media screen and (max-height: 580px) {
   
          width: 15px;
        
      }
    }
  
  }
}
@media screen and (min-width: 2800px) {
  .list-cases {
    display: flex;
    margin-bottom: 45px;
    div {
      margin-left: 40px;
      // text-align: end;
      font-size: 30px;
    }
    img {
      width: 70px;
    }
  }
  .case-info {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 3800px) {
  .list-cases {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    div {
      margin-left: 40px;
      text-align: end;
      font-size: 40px;
    }
    img {
      width: 80px;
    }
  }
  .case-info {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .list-cases {
    display: flex;
    align-items: center;
    margin-bottom: 7%;
    div {
      margin-left: 20px;
      font-size: 16px;
    }
    img {
      width: 45px;
    }
  }
  @media screen and (max-width: 420px) {
   .list-cases {
      margin-bottom: 7%;
      div {
        font-size: 15.5px;
      }
      img {
        width: 44px;
      }
    }
    .img-corporate {
      height: 28vh;
    }
  }
  @media screen and (max-width: 375px) {
    .corporate-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 25px;
      height: 63.5vh;
      h1 {
        margin: 30px 0 25px 0;
        font-size: 30px;
      }
    }

    .list-cases {
      margin-bottom: 7%;
      div {
        font-size: 15px;
      }
      img {
        width: 42px;
      }
    }
    .img-corporate {
      height: 28vh;
    }
  }
  @media screen and (max-width: 360px) {
    .list-cases {
      margin-bottom: 7%;
      div {
        font-size: 14px;
      }
      img {
        width: 42px;
      }
    }
  }
  @media screen and (max-height: 760px) {

    .list-cases {
      margin-bottom: 6%;
      div {
        font-size: 13px;
      }
      img {
        width: 40px;
      }
    }
  }
  @media screen and (max-height: 670px) {
    .corporate-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 25px;
      height: 56vh;
      h1 {
        margin: 20px 0 20px 0;
        font-size: 22px;
      }
    }

    .list-cases {
      margin-bottom: 4%;
      div {
        font-size: 12px;
      }
      img {
        width: 35px;
      }
    }
    .img-corporate {
      height: 34vh;
    }
  }

  @media screen and (max-height: 600px) {
    .corporate-text {
      height: 55vh;
      h1 {
        margin: 15px 0 15px 0;
        font-size: 22px;
      }
    }

    .list-cases {
      margin-bottom: 3.5%;
      div {
        font-size: 11px;
      }
      img {
        width: 33px;
      }
    }
    .img-corporate {
      height: 33vh;
    }
  }
  @media screen and (max-height: 570px) {
.list-cases {
      margin-bottom: 3%;
      div {
        font-size: 11px;
      }
      img {
        width: 30px;
      }
    }
  
  }
}
</style>
