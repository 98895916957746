const state = {
  transparentHeader: false,
  hideUI: false,
  hideFooter: false,
  index: 0,
  feedback: false,
  mobileContact: false,
  snackbar: {
    color: null,
    icon: null,
    mode: null,
    text: null,
    timeout: 2500,
    title: null,
    visible: false,
  },
  userSrc: '',
};

export const SET_HEADER_TRANSPARENT = 'SET_HEADER_TRANSPARENT';
export const SET_HIDE_UI = 'SET_HIDE_UI';
export const SET_HIDE_FOOTER = 'SET_HIDE_FOOTER';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_INDEX = 'SET_INDEX';
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const SET_MOBILE_CONTACT = 'SET_MOBILE_CONTACT';
export const SET_USER_SRC = 'SET_USER_SRC';

const mutations = {
  [SET_HEADER_TRANSPARENT](state, value) {
    state.transparentHeader = value;
  },
  [SET_HIDE_UI](state, value) {
    state.hideUI = value;
  },
  [SET_HIDE_FOOTER](state, value) {
    state.hideFooter = value;
  },
  [SET_SNACKBAR](state, value) {
    state.snackbar = Object.assign({}, state.snackbar, value)
  },
  [SET_INDEX](state, index) {
    state.index = index;
  },
  [SET_FEEDBACK](state, value) {
    state.feedback = value;
  },
  [SET_MOBILE_CONTACT](state, value) {
    state.mobileContact = value;
  }
};


const actions = {
  snackbar({ commit }, object) {
    commit("SET_SNACKBAR", Object.assign({}, {
      timeout: "2500",
      visible: true,
      mode: 'multi-line',
      title: '',
      text: ''
    }, object));
  },
}

export default { namespaced: true, state, mutations, actions };
