<template>
  <div class="technology-page" id="technology">
    <div class="technology-stack">
      <div class="text-block animonTech" data-effect="scaleDown">
        <div class="first-column">
          <h1>How we created</h1>
          <p>Technology stack</p>
        </div>
        <div class="second-column">
          <p class="second-column-p">
            RendezVu is 3D real time communication multiplayer <br />
            application based on progressive technologies and using <br />
            agile development technique.
          </p>
          <p class="second-column-p-mobile">
            RendezVu is 3D real time communication <br />multiplayer application
            based on progressive <br />technologies and using agile
            development<br />technique.
          </p>
          <img width="100px" src="../../assets/icon-scroll.svg" alt="" />
        </div>
      </div>
      <div class="image-block">
        <div class="img img1 animonItem" data-delay="200">
          <img src="../../assets/icon-unity.svg" alt="" />
          <h3>UNITY</h3>
        </div>
        <div class="img img2 animonItem" data-delay="400">
          <img src="../../assets/icon-aws.svg" alt="" />
          <h3 class="h-3">AWS SERVICES</h3>
        </div>
        <div class="img img3 animonItem" data-delay="600">
          <img src="../../assets/icon-agora.svg" alt="" />
          <h3 class="h-3">AGORA RTC</h3>
        </div>
        <div class="img img4 animonItem" data-delay="800">
          <img src="../../assets/icon-wolf.svg" alt="" />
          <h3 class="h-3">WOLF3D RPM</h3>
        </div>
        <div class="img img5 animonItem" data-delay="1000">
          <img src="../../assets/icon-zenject.svg" alt="" />
          <h3 class="h-3">ZENJECT</h3>
        </div>
      </div>
      <div class="image-block-tablet">
        <div class="line-stack-tablet">
          <div class="img-tablet img1-tablet animonTablet" data-delay="200">
            <img width="100px" src="../../assets/icon-unity.svg" alt="" />
            <h3>UNITY</h3>
          </div>
          <div class="img-tablet img2-tablet animonTablet" data-delay="400">
            <img width="150px" src="../../assets/icon-aws.svg" alt="" />
            <h3>AWS SERVICES</h3>
          </div>
          <div class="img-tablet img3-tablet animonTablet" data-delay="600">
            <img width="130px" src="../../assets/icon-zenject.svg" alt="" />
            <h3>ZENJECT</h3>
          </div>
        </div>
        <div class="line-stack-tablet">
          <div class="img-tablet img4-tablet animonTablet" data-delay="800">
            <img width="186px" src="../../assets/icon-agora.svg" alt="" />
            <h3>AGORA RTC</h3>
          </div>
          <div class="img-tablet img5-tablet animonTablet" data-delay="1200">
            <img width="92px" src="../../assets/icon-wolf.svg" alt="" />
            <h3>WOLF3D RPM</h3>
          </div>
          <div class="img-tablet img6-tablet animonTablet" data-delay="1400">
            <img src="../../assets/mouse-scroll-mobile.svg" alt="" />
            <h3>SCROLL DOWN</h3>
          </div>
        </div>
      </div>
      <div class="image-block-mobile">
        <div class="line-stack-mobile">
          <div class="img-mobile img1-mobile">
            <img width="68px" src="../../assets/icon-unity.svg" alt="" />
            <h3>UNITY</h3>
          </div>
          <div class="img-mobile img2-mobile">
            <img width="76px" src="../../assets/icon-aws.svg" alt="" />
            <h3>AWS SERVICES</h3>
          </div>
        </div>
        <div class="line-stack-mobile">
          <div class="img-mobile img3-mobile">
            <img width="126px" src="../../assets/icon-agora.svg" alt="" />
            <h3>AGORA RTC</h3>
          </div>
          <div class="img-mobile img4-mobile">
            <img width="62px" src="../../assets/icon-wolf.svg" alt="" />
            <h3>WOLF3D RPM</h3>
          </div>
        </div>
        <div class="line-stack-mobile">
          <div class="img-mobile img5-mobile">
            <img width="90px" src="../../assets/icon-zenject.svg" alt="" />
            <h3>ZENJECT</h3>
          </div>
          <div class="img-mobile img6-mobile">
            <img
              height="80px"
              src="../../assets/mouse-scroll-mobile.svg"
              alt=""
            />
            <h3>SCROLL DOWN</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { animon } from "animon";

export default {
  data() {
    return {};
  },
  mounted() {
    // animon(".animonTech");
    // animon(".animonItem");
    // animon(".animonTablet");
    gsap.registerPlugin(ScrollTrigger);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.technology-page {
  display: flex;
  min-width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}
.technology-stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin-top: 12vh;
  height: calc(100vh - 12vh);
  background: url("../../assets/background-tech.svg");
  background-size: cover;
  // linear-gradient(
  //   35deg,
  //   rgba(246, 58, 95, 0.2),
  //   rgba(88, 122, 136, 0.2),
  //   rgba(246, 58, 95, 0.2),
  //   rgba(255, 255, 255, 0.2),
  //   rgba(246, 58, 95, 0.2)
  // );
  opacity: 1;
}

.text-block {
  display: flex;
  text-align: justify;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  height: 23vh;
}

.first-column {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  text-align: start;

  h1 {
    font-size: 44px;
    line-height: 2;
    font-weight: 500;
    font-size: 32px;
    font-style: normal;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
  }
}

.second-column {
  display: flex;
  margin-right: 30px;
  text-align: start;
  align-items: center;

  .second-column-p {
    font-size: 13px;
    display: block;
  }
  .second-column-p-mobile {
    display: none;
  }
  img {
    margin-left: 20px;
  }
}
.img-block {
  height: 45vh;
  display: flex;
  justify-content: center;
  opacity: 1;
}
.image-block-tablet {
  display: none;
}
.image-block-mobile {
  display: none;
}
.image-block {
  display: grid;
  width: 100vw;
  height: 65vh;
  justify-content: flex-start;
  grid-template-columns: 20vw 20vw 20vw 20vw 20vw;
  grid-template-areas: "A B C D E";
  opacity: 1;
  color: black;

  .img {
    display: flex;
    align-items: center;
    border-top: rgba(193, 191, 191, 1) 1px solid;
    border-right: rgba(193, 191, 191, 1) 1px solid;
    border-bottom: rgba(193, 191, 191, 1) 1px solid;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 50%;
    padding-bottom: 50%;
  }
  .img1 {
    display: flex;
    flex-direction: column;
    grid-area: A;
    opacity: 1;

    img {
      width: 90px;
    }
  }
  .img2 {
    display: flex;
    flex-direction: column;
    grid-area: B;
    opacity: 1;
    img {
      width: 115px;
    }
  }
  .img3 {
    display: flex;
    flex-direction: column;
    grid-area: C;
    opacity: 1;
    img {
      width: 170px;
    }
  }
  .img4 {
    display: flex;
    flex-direction: column;
    grid-area: D;
    opacity: 1;
    img {
      width: 72px;
    }
  }
  .img5 {
    display: flex;
    flex-direction: column;
    grid-area: E;
    opacity: 1;
    img {
      width: 94px;
    }
  }
  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: -0.5px;
  }
}

.info-text {
  margin-left: 27px;
}

.slide-bottom {
  -webkit-animation: slide-bottom 2s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    alternate both;
  animation: slide-bottom 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) alternate
    both;
  opacity: 0;
  animation-delay: 1s;
}
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
    transition: 1s;
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
    transition: 1s;
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
    transition: 1s;
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 1;
    transition: 1s;
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .text-block {
    height: 33vh;
  }
  .first-column {
    h1 {
      font-size: 44px;
      @media screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }
    p {
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }

  .second-column {
    .second-column-p {
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
    .second-column-p-mobile {
      display: none;
    }
    img {
      margin-left: 30px;
    }
  }

  .image-block {
    height: 55vh;
    .img1 {
      img {
        width: 90px;
        @media screen and (max-device-width: 1024px) {
          width: 75px;
        }
        @media screen and (max-device-width: 900px) {
          width: 60px;
        }
      }
    }
    .img2 {
      img {
        width: 115px;
        @media screen and (max-device-width: 1024px) {
          width: 100px;
        }
        @media screen and (max-device-width: 900px) {
          width: 85px;
        }
      }
    }
    .img3 {
      display: flex;
      flex-direction: column;
      grid-area: C;
      opacity: 1;
      img {
        width: 170px;
        @media screen and (max-device-width: 1024px) {
          width: 155px;
        }
        @media screen and (max-device-width: 900px) {
          width: 140px;
        }
      }
    }
    .img4 {
      img {
        width: 72px;
        @media screen and (max-device-width: 1024px) {
          width: 57px;
        }
        @media screen and (max-device-width: 900px) {
          width: 42px;
        }
      }
    }
    .img5 {
      img {
        width: 94px;
        @media screen and (max-device-width: 1024px) {
          width: 79px;
        }
        @media screen and (max-device-width: 900px) {
          width: 64px;
        }
      }
    }
    h3 {
      font-size: 22px;
      @media screen and (max-device-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-device-width: 900px) {
        font-size: 14px;
      }
    }
    p {
      font-size: 12px;
      letter-spacing: -0.5px;
    }
  }

  .info-text {
    margin-left: 27px;
  }
  @media screen and (max-height: 580px) {
    @media screen and (max-width: 740px) {
      .first-column {
        h1 {
          font-size: 22px;
        }
        p {
          font-size: 11px;
        }
      }

      .second-column {
        .second-column-p {
          font-size: 9px;
        }

        img {
          margin-left: 10px;
          width: 60px;
        }
      }
    }
    .image-block {
      .img {
    padding-top: 40%;
    padding-bottom: 30%;
  }
      .img1 {
        img {
          width: 45px;
        }
      }
      .img2 {
        img {
          width: 60px;
        }
      }
      .img3 {
        img {
          width: 85px;
        }
      }
      .img4 {
        img {
          width: 38px;
        }
      }
      .img5 {
        img {
          width: 47px;
        }
      }
      h3 {
        font-size: 14px;
        @media screen and (max-height: 400px) {
 font-size: 7px;
        }

      }
    }
  }
}
@media screen and (min-width: 581px) and (max-width: 1025px) and (orientation: portrait) {
  .technology-stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    margin-top: 12vh;
    height: calc(100vh - 12vh);
    background: url("../../assets/background-tech.svg");
    background-size: cover;
    opacity: 1;
  }
  .text-block {
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 1;
    padding-top: 50px;
    height: 34vh;
  }
  .first-column {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    text-align: start;

    h1 {
      font-weight: 500;
      font-size: 52px;
      font-style: normal;
      letter-spacing: 1px;
      margin-top: 20px;
      @media screen and (max-width: 700px) {
        font-size: 42px;
        margin-top: 0px;
      }
    }
    p {
      display: none;
    }
  }

  .second-column {
    display: flex;
    margin-right: 30px;
    text-align: start;
    align-items: flex-end;
    margin-top: 10px;
    margin-left: 50px;
    margin-bottom: 10px;
    .second-column-p {
      display: block;
      font-size: 23px;
      @media screen and (max-width: 740px) {
        font-size: 22px;
      }
       @media screen and (max-width: 720px) {
        font-size: 20px;
      }
      @media screen and (max-width: 700px) {
        font-size: 18px;
      }
    }
    .second-column-p-mobile {
      display: none;
    }
    img {
      display: none;
    }
  }
  .image-block {
    display: none;
  }
  .image-block-mobile {
    display: none;
  }
  .image-block-tablet {
    display: block;
    width: 100vw;
    height: 55vh;
    justify-content: flex-start;
    color: black;
    opacity: 1;
    .line-stack-tablet {
      display: flex;
      width: 100vw;
    }
    .img-tablet {
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-sizing: border-box;
      height: 27vh;
      width: 50vw;
      padding-top: 20px;
      padding-bottom: 20px;

      h3 {
        font-size: 18px;
        letter-spacing: 0.1em;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.1em;
      }
    }
    .img1-tablet {
      display: flex !important;
      flex-direction: column;
      border-bottom: rgba(193, 191, 191, 1) 1px solid;
      border-top: rgba(193, 191, 191, 1) 1px solid;
      border-right: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
      @media screen and (max-width: 700px) {
        img {
          width: 90px !important;
        }
      }
    }
    .img2-tablet {
      display: flex !important;
      flex-direction: column;
      border-bottom: rgba(193, 191, 191, 1) 1px solid;
      border-top: rgba(193, 191, 191, 1) 1px solid;
      border-right: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
      h3 {
        padding-top: 20px;
      }
      @media screen and (max-width: 700px) {
        img {
          width: 120px !important;
        }
      }
    }
    .img3-tablet {
      display: flex !important;
      flex-direction: column;
      border-bottom: rgba(193, 191, 191, 1) 1px solid;
      border-top: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
      @media screen and (max-width: 700px) {
        img {
          width: 100px !important;
        }
      }
    }
    .img4-tablet {
      display: flex !important;
      flex-direction: column;
      border-right: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
      @media screen and (max-width: 700px) {
        img {
          width: 150px !important;
        }
      }
    }
    .img5-tablet {
      display: flex !important;
      flex-direction: column;
      border-right: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
      h3 {
        padding-bottom: 25px;
      }
      @media screen and (max-width: 700px) {
        img {
          width: 80px !important;
        }
      }
    }
    .img6-tablet {
      display: flex !important;
      flex-direction: column;
      opacity: 1;
      h3 {
        color: #3899a5;
        letter-spacing: 0.1em;
        font-weight: 400;
        padding-bottom: 20px;
      }
      img {
        height: 100px;
        @media screen and (max-width: 700px) {
          height: 80px !important;
        }
      }
    }
    h3 {
      font-style: normal;
      letter-spacing: -1px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: -0.5px;
    }
  }

  .info-text-tablet {
    margin-left: 27px;
  }
}

@media screen and (min-width: 1920px) {
  .first-column {
    margin-left: 75px;
    h1 {
      font-size: 44px;
    }
    p {
      font-size: 15px;
    }
  }

  .second-column {
    margin-right: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    .second-column-p {
      font-size: 18px;
    }
    .second-column-p-mobile {
      display: none;
    }
    img {
      width: 127px;
      margin-right: 85px;
      margin-left: 145px;
    }
  }
  .image-block {
    .img {
      padding-top: 70%;
      padding-bottom: 50%;
    }
  }
}
@media screen and (min-width: 2800px) {
  .first-column {
    margin-left: 130px;
    h1 {
      font-size: 68px;
    }
    p {
      font-size: 30px;
    }
  }

  .second-column {
    display: flex;
    margin-right: 130px;
    text-align: start;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 80px;
    .second-column-p {
      font-size: 32px;
      display: block;
    }
    img {
      width: 200px;
      margin-left: 70px;
    }
  }
  .image-block {
    .img {
      padding-top: 50%;
      padding-bottom: 40%;
    }
    h3 {
      font-size: 40px;
    }
  }
  .img1 {
    img {
      width: 200px !important;
    }
  }
  .img2 {
    img {
      width: 270px !important;
    }
  }
  .img3 {
    img {
      width: 400px !important;
    }
  }
  .img4 {
    img {
      width: 180px !important;
    }
  }
  .img5 {
    img {
      width: 200px !important;
    }
  }
}
@media screen and (min-width: 3800px) {
  .first-column {
    margin-left: 230px;
    h1 {
      font-size: 88px;
    }
    p {
      font-size: 44px;
    }
  }

  .second-column {
    display: flex;
    margin-right: 180px;
    text-align: start;
    align-items: flex-end;
    margin-top: 80px;
    margin-bottom: 80px;
    .second-column-p {
      font-size: 46px;
      display: block;
    }
    .second-column-p-mobile {
      display: none;
    }
    img {
      width: 300px;
      margin-left: 90px;
    }
  }
  .image-block {
    .img {
      padding-top: 70%;
      padding-bottom: 50%;
    }
    p {
      font-size: 44px;
    }
    h3 {
      font-size: 54px;
    }
  }
  .img1 {
    img {
      width: 250px !important;
    }
  }
  .img2 {
    img {
      width: 330px !important;
    }
  }
  .img3 {
    img {
      width: 450px !important;
    }
  }
  .img4 {
    img {
      width: 230px !important;
    }
  }
  .img5 {
    img {
      width: 250px !important;
    }
  }
  .info-text {
    margin-left: 54px;
  }
}
// .animonTech {
//   opacity: 1;
//   will-change: opacity, transform;
//   transition: opacity 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1),
//     transform 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1);
// }
// .animonTech.is-visible {
//   opacity: 1;
// }
// .animonTech[data-effect="myEffect"] {
//   transform: translateY(20);
// }
// .animonTech[data-effect="myEffect"].is-visible {
//   transform: translateY(0);
// }

// .animonItem {
//   opacity: 1;
//   will-change: opacity;
//   transition: opacity 640ms 400ms cubic-bezier(0.5, 1, 0.89, 1);
// }
// .animonItem.is-visible {
//   opacity: 1;
// }
@media screen and (max-width: 580px) and (orientation: portrait) {
  .image-block-tablet {
    display: none;
  }
  .image-block {
    display: none;
  }
  .technology-page {
    display: flex;
    min-width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255);
  }

  .technology-stack {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    width: 100vw;
    height: calc(100vh - 70px);
    opacity: 1;
    background: url("../../assets/back-tech-mobile.jpg");
  }

  .text-block {
    display: flex;
    flex-direction: column;
    text-align: start;
    color: #1d1d1f;
    opacity: 1;
    padding-top: 1vh;
    padding-bottom: 1vh;
    margin-left: 4vw;
    font-family: Poppins;
    font-style: normal;
    height: 26vh;
    align-items: flex-start;
    justify-content: center;
  }
  .first-column {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    text-align: start;
    h1 {
      font-weight: 500;
      font-size: 34px;
      font-style: normal;
      letter-spacing: 1px;
      margin-top: 0px;
    }
    p {
      display: none;
    }
  }

  .second-column {
    display: flex;
    margin-right: 30px;
    text-align: start;
    align-items: flex-end;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 10px;
    .second-column-p-mobile {
      font-size: 15px;
      display: block;
    }
    .second-column-p {
      display: none;
    }
    img {
      display: none;
    }
  }
  .line-stack-mobile {
    display: flex;
    align-items: center;
  }
  .image-block-mobile {
    display: flex;
    width: 100vw;
    height: 60vh;
    justify-content: flex-start;
    color: black;
    opacity: 1;
    flex-direction: column;
    .img-mobile {
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-sizing: border-box;
      height: 21vh;
      width: 50vw;
      padding-top: 20px;
      padding-bottom: 20px;

      h3 {
        font-size: 14px;
        letter-spacing: 0.1em;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .img1-mobile {
      display: flex !important;
      flex-direction: column;
      border-bottom: rgba(193, 191, 191, 1) 1px solid;
      border-top: rgba(193, 191, 191, 1) 1px solid;
      border-right: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
    }
    .img2-mobile {
      display: flex !important;
      flex-direction: column;
      border-top: rgba(193, 191, 191, 1) 1px solid;
      border-bottom: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
    }
    .img3-mobile {
      display: flex !important;
      flex-direction: column;
      border-right: rgba(193, 191, 191, 1) 1px solid;
      border-bottom: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
    }
    .img4-mobile {
      display: flex !important;
      flex-direction: column;
      border-bottom: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
    }
    .img5-mobile {
      display: flex !important;
      flex-direction: column;
      border-right: rgba(193, 191, 191, 1) 1px solid;
      opacity: 1;
    }
    .img6-mobile {
      display: flex !important;
      flex-direction: column;
      opacity: 1;
      h3 {
        color: #3899a5;
        letter-spacing: 0.1em;
        font-weight: 400;
      }
    }
    h3 {
      font-style: normal;
      letter-spacing: -1px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: -0.5px;
    }
  }

  .info-text-mobile {
    margin-left: 27px;
  }
  @media screen and (max-width: 390px) {
    .second-column {
      .second-column-p-mobile {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 375px) {
    .second-column {
      display: flex;
      margin-right: 30px;
      text-align: start;
      align-items: flex-end;
      margin-top: 10px;
      margin-left: 0px;
      margin-bottom: 10px;
      .second-column-p-mobile {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .second-column {
      display: flex;
      margin-right: 30px;
      text-align: start;
      align-items: flex-end;
      margin-top: 10px;
      margin-left: 0px;
      margin-bottom: 10px;
      .second-column-p-mobile {
        font-size: 13px;
      }
    }
  }
  @media screen and (max-height: 670px) {
    .image-block-mobile {
      justify-content: space-around;
      .img-mobile {
        width: 50vw;
        padding-top: 10px;
        padding-bottom: 10px;
        h3 {
          font-size: 13px;
        }
      }
    }
    .img1-mobile {
      img {
        height: 50px !important;
      }
    }
    .img2-mobile {
      img {
        height: 40px !important;
      }
    }
    .img3-mobile {
      img {
        height: 40px !important;
      }
    }
    .img4-mobile {
      img {
        height: 50px !important;
      }
    }
    .img5-mobile {
      img {
        height: 35px !important;
      }
    }
    .img6-mobile {
      img {
        height: 35px !important;
      }
    }

    .text-block {
      margin-top: 0vh;
      margin-bottom: 0vh;
      margin-left: 5vw;
      height: 29.5vh;

      h1 {
        font-size: 30px;
      }
      p {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }

  @media screen and (max-height: 600px) {
    .text-block {
      margin-top: 0vh;
      margin-bottom: 0vh;
      margin-left: 5vw;
      height: 29.5vh;

      h1 {
        font-size: 26px;
      }
      p {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  @media screen and (max-height: 570px) {
    .text-block {
      margin-top: 0vh;
      margin-bottom: 0vh;
      margin-left: 5vw;
      height: 25vh;

      h1 {
        font-size: 22px;
      }
      p {
        font-size: 11px;
        margin-top: 5px;
      }
    }
    .second-column {
      margin-top: 5px;
      margin-bottom: 10px;
      .second-column-p-mobile {
        font-size: 12px;
      }
    }
  }
}
</style>

