<template>
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b)">
      <circle cx="36" cy="36" r="36" fill="#E9F7F9" />
      <circle
        cx="36"
        cy="36"
        r="35.5"
        stroke="url(#paint0_linear)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="7 7"
      />
    </g>
    <path
      d="M39.2025 26C40.318 26.0111 41.2265 26.566 41.7555 27.5649C41.8921 27.8285 42.0825 28.2135 42.282 28.6196L42.5228 29.1107L42.641 29.3518L42.756 29.596C42.802 29.6737 42.8825 29.7292 42.986 29.7292C45.746 29.7292 48 31.9046 48 34.5683V41.1609C48 43.8246 45.746 46 42.986 46H30.014C27.2425 46 25 43.8246 25 41.1609V34.5683C25 31.9046 27.2425 29.7292 30.014 29.7292C30.106 29.7292 30.198 29.6848 30.2325 29.596L30.3015 29.4628C30.6235 28.808 31.0145 28.0089 31.2445 27.5649C31.7735 26.566 32.6705 26.0111 33.786 26H39.2025ZM36.5 33.1143C35.2925 33.1143 34.154 33.5694 33.2915 34.4018C32.4405 35.2342 31.969 36.3219 31.9805 37.4761C31.9805 38.6415 32.452 39.7292 33.303 40.5616C34.1655 41.3829 35.2925 41.838 36.5 41.838C37.742 41.838 38.869 41.3496 39.6855 40.5616C40.502 39.7736 41.008 38.6859 41.0195 37.4761C41.0195 36.3219 40.548 35.2231 39.697 34.3907C38.846 33.5694 37.7075 33.1143 36.5 33.1143ZM36.5 34.7791C37.2475 34.7791 37.949 35.0566 38.478 35.5671C39.007 36.0777 39.2945 36.7547 39.2945 37.4761C39.283 38.9634 38.041 40.1731 36.5 40.1731C35.7525 40.1731 35.051 39.8957 34.522 39.3851C33.993 38.8746 33.7055 38.1976 33.7055 37.4761V37.465C33.694 36.7658 33.9815 36.0888 34.5105 35.5782C35.051 35.0566 35.7525 34.7791 36.5 34.7791ZM42.9515 32.8479C42.3765 32.8479 41.9165 33.303 41.9165 33.8579C41.9165 34.4129 42.3765 34.8568 42.9515 34.8568C43.5265 34.8568 43.998 34.4129 43.998 33.8579C43.998 33.303 43.5265 32.8479 42.9515 32.8479Z"
      fill="#1EAFC1"
    />
    <defs>
      <filter
        id="filter0_b"
        x="-2"
        y="-2"
        width="76"
        height="76"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="1" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="36"
        y1="4.73684"
        x2="45.2589"
        y2="70.8229"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#66EEFF" />
        <stop offset="0.334561" stop-color="#FA4242" />
        <stop offset="0.652664" stop-color="#DF5881" />
        <stop offset="1" stop-color="#66EEFF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
