<template>
  <div>
    <v-list v-if="!isMobile" class="bg-opacity">
      <v-list-item-group>
        <template v-for="(item, i) in menuItems">
          <v-list-item
            v-if="checkMenuItem(item)"
            :key="`menu-item-${i}`"
            :class="`menu-item-${item.route.substring(1)}`"
            dense
            link
            :disabled="item.route == '/profile' && item.isOnlineGroup !== true"
            :to="item.route"
            exact
          >
            <img
              v-if="item.title === 'My Profile'"
              class="profile-icon profile"
              src="../assets/icon-people-profile.svg"
              alt=""
            />
            <img
              v-if="item.title === 'Edit Password'"
              class="profile-icon edit-password"
              src="../assets/icon-edit-pass.svg"
              alt=""
            />
            <img
              v-if="item.title === 'Admin'"
              class="profile-icon admin"
              src="../assets/icon-admin-menu.svg"
              alt=""
            />
            <img
              v-if="item.title === 'Log Out'"
              class="profile-icon logout"
              src="../assets/icon-logout.svg"
              alt=""
            />
            <!-- <v-icon v-text="item.icon"></v-icon> -->

            <v-list-item-title
              :style="
                item.title === 'My Profile'
                  ? 'color: #1eafc1'
                  : 'color:#242424;'
              "
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>

          <v-divider
            v-if="separated && i + 1 < menuItems.length"
            :key="`divider-${i}`"
          >
          </v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <v-list v-else class="bg-opacity">
      <v-list-item-group>
        <template v-for="(item, i) in menuItems">
          <v-list-item
            v-if="checkMenuItem(item)"
            :key="`menu-item-${i}`"
            :class="`menu-item-${item.route.substring(1)}`"
            dense
            link
            :disabled="item.route == '/profile' && item.isOnlineGroup !== true"
            :to="item.route"
            exact
          >
            <img
              v-if="item.title === 'My Profile'"
              class="profile-icon"
              width="12px"
              src="../assets/icon-profile-menu.svg"
              alt=""
            />
            <img
              v-if="item.title === 'Edit Password'"
              class="profile-icon"
              width="12px"
              src="../assets/icon-edit-pass.svg"
              alt=""
            />
            <img
              v-if="item.title === 'Play online'"
              class="profile-icon online"
              src="../assets/icon-play-menu.svg"
              alt=""
            />
            <img
              v-if="item.title === 'Admin'"
              class="profile-icon admin"
              src="../assets/icon-admin-menu.svg"
              alt=""
            />
            <img
              v-if="item.title === 'Log Out'"
              class="profile-icon"
              width="12px"
              style="margin-left: 0.2vw !important"
              src="../assets/icon-logout.svg"
              alt=""
            />
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>

          <v-divider
            v-if="separated && i + 1 < menuItems.length"
            :key="`divider-${i}`"
          >
          </v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import awsAuth from "../cognito/aws-auth";
export default {
  data() {
    return {
      menuItems: [
        {
          title: "My Profile",
          route: "/profile",
          isOnlineGroup: false,
        },
        {
          title: "Play online",
          route: "/online",
        },
        { title: "Edit Password", route: "/password" },
        {
          title: "Admin",
          route: "/admin",
          requiresAdmin: true,
        },
        { title: "Log Out", route: "/sign-out" },
      ],
      menuItemsMobile: [
        {
          title: "My Profile",
          route: "/profile",
          isOnlineGroup: false,
        },
        { title: "Edit Password", route: "/password" },
        { title: "Log Out", route: "/sign-out" },
      ],
      isAdmin: false,
      isMobile: false,
    };
  },
  mounted() {
    if (window.screen.width <= 767) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.isAdmin = awsAuth.isUserAdmin();
    this.$nextTick(() => {
      this.verifyIsOnlineGroup();
    });
  },
  props: {
    separated: { type: Boolean, default: false },
  },
  methods: {
    checkMenuItem(item) {
      if (item.requiresAdmin === true) {
        return this.isAdmin === true;
      }
      return true;
    },
    async verifyIsOnlineGroup() {
      let checking = false;
      /*try {
        let groups = await awsAuth.getUserGroups();
        if (groups.includes("online")) checking = true;
      } catch (error) {
        console.log(error.message);
      }*/
      // temp hack to allow online for everyone
      checking = true;
      this.menuItems[0].isOnlineGroup = checking;
      this.menuItemsMobile[0].isOnlineGroup = checking;
    },
  },
};
</script>
<style scoped lang="scss">
.bg-opacity {
  background-color: rgba(255, 255, 255, 1) !important;
}
.menu-item-profile {
  background-color: #ffffff;

  &.v-list-item--disabled {
    background-color: #fff;
    cursor: not-allowed;
  }
}

.profile-icon {
  margin-right: 1.5vw;
  margin-left: 0.5vw;
}

.v-list-item--dense {
  min-height: 30px;
}
.v-list-item__title {
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
}
.profile {
  width: 12px;
}
.online {
  width: 11px;
  margin-right: 1.55vw;
}
.edit-password {
  width: 12px;
}
.admin {
  width: 12px;
}
.logout {
  width: 12px;
  margin-left: 0.5vw !important;
}

@media screen and (max-width: 581px) {
  .bg-opacity {
    background-color: rgb(255, 255, 255) !important;
  }
  .profile-icon {
    margin-right: 2.5vw;
    margin-left: 0.5vw;
  }
}
@media screen and (min-width: 3800px) {
  .profile-icon {
    margin-right: 1.5vw;
    margin-left: 0.5vw;
  }

  .v-list-item--dense {
    min-height: 70px;
  }
  .v-list-item__title {
    letter-spacing: 1px;
    font-size: 24px;
    text-transform: uppercase;
  }
  .profile {
    width: 32px;
  }
  .online {
    width: 28px;
  }
  .edit-password {
    width: 32px;
  }
  .admin {
    width: 32px;
  }
  .logout {
    width: 32px;
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .profile-icon {
    margin-right: 1.5vw;
    margin-left: 0.5vw;
  }

  .v-list-item--dense {
    min-height: 60px;
    @media screen and (max-width: 600px) {
      min-height: 35px;
    }
  }
  .v-list-item__title {
    letter-spacing: 1px;
    font-size: 22px;
    text-transform: uppercase;
    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
  }
  .profile {
    width: 32px;
  }
  .online {
    width: 28px;
  }
  .edit-password {
    width: 32px;
  }
  .admin {
    width: 32px;
  }
  .logout {
    width: 32px;
  }
}
@media screen and (max-device-width: 1024px) and (orientation: landscape) {
  .profile-icon {
    margin-right: 1.5vw;
    margin-left: 0.5vw;
  }

  .v-list-item--dense {
    min-height: 30px;
    @media screen and (max-height: 580px) {
      min-height: 10px;
    }
  }
  .v-list-item__title {
    letter-spacing: 1px;
    font-size: 15px;
    text-transform: uppercase;
    @media screen and (max-height: 580px) {
      font-size: 10px;
    }
  }
  .profile {
    width: 15px;
    @media screen and (max-height: 580px) {
      width: 10px;
    }
  }
  .online {
    width: 12px;
    @media screen and (max-height: 580px) {
      width: 7px;
    }
  }
  .edit-password {
    width: 15px;
    @media screen and (max-height: 580px) {
      width: 10px;
    }
  }
  .admin {
    width: 15px;
    @media screen and (max-height: 580px) {
      width: 10px;
    }
  }
  .logout {
    width: 15px;
    @media screen and (max-height: 580px) {
      width: 10px;
    }
  }
}
</style>
